import {
  useBusinessDocumentForOwner,
  useGetPendingInvites,
} from "@/common/firebase/services/owner-hooks";
import { BusinessForOwner } from "@common/model/business";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import { useState } from "react";
import { useParams } from "react-router";
import { useUserStore } from "../../store/userStore";
import { BusinessInviteModal } from "../components/user-management/BusinessInviteModal";
import { BusinessUserInvitationTable } from "../components/user-management/BusinessUserInvitationTable";
import { BusinessUserRow } from "../components/user-management/BusinessUserRow";

export default function UserManagement() {
  const { id } = useParams();
  const { user } = useUserStore();

  if (!id || !user) return <></>;

  return <UserManagementView businessIds={[id]} userId={user.uid} />;
}

export function UserManagementView({
  businessIds,
  userId,
}: {
  businessIds: string[];
  userId: string;
}) {
  const [value, setValue] = useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const { business, mutate } = useBusinessDocumentForOwner(
    businessIds[0],
    userId
  );
  const { data, mutate: mutatePending } = useGetPendingInvites(
    business ? [business] : []
  );

  if (!business) return <></>;

  return (
    <Container component="main" maxWidth="lg" disableGutters>
      <Grid container>
        <Grid item xs>
          <Typography variant="h5">Utilisateurs</Typography>
          <Typography variant="body2">
            Gérez l'accès à votre établissements
          </Typography>
        </Grid>
        <Grid item xs={"auto"} display="flex" alignItems="end">
          <BusinessInviteModal
            businesses={[business as BusinessForOwner]}
            mutate={() => Promise.all([mutate(), mutatePending()])}>
            <Button variant="contained">Inviter</Button>
          </BusinessInviteModal>
        </Grid>
      </Grid>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            aria-label="lab API tabs example"
            variant="fullWidth"
            onChange={handleChange}>
            <Tab label="Utilisateurs" value="1" />
            <Tab
              label={`Invitations en attente (${data?.length || 0})`}
              value="2"
            />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ p: 0 }}>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table sx={{ minWidth: 350 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Nom</TableCell>
                  <TableCell align="right">Role</TableCell>
                  <TableCell align="right">Invité le</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!Object.keys(business?.users_permissions || {}).length && (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Alert severity="info">
                        Vous êtes le seul utilisateur à avoir accès à cet
                        établissement.
                      </Alert>
                    </TableCell>
                  </TableRow>
                )}
                {Object.values(business?.users_permissions || {})?.map(
                  (row) => (
                    <BusinessUserRow
                      role={row.role}
                      userId={row.id}
                      mutate={mutate}
                    />
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel value="2" sx={{ p: 0 }}>
          <BusinessUserInvitationTable
            businessCollection={[business]}
            multiple={false}
          />
        </TabPanel>
      </TabContext>
    </Container>
  );
}
