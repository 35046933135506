import {
  BusinessSubCollections,
  CustomerSubCollections,
  FBCollections,
} from "@common/firebase";
import {
  Business,
  BusinessEvent,
  BusinessEventCreate,
  BusinessFirestore,
  BusinessForOwner,
  BusinessInvitedUser,
  BusinessOffer,
  ProgramLottery,
  ProgramLoyalty,
  UserRole,
} from "@common/model/business";
import { BusinessUser } from "@common/model/business-user";
import {
  ScannedBusinessFirestore,
  SubscribedBusinessFirestore,
} from "@common/model/customer";
import dayjs from "dayjs";
import { User } from "firebase/auth";
import {
  DocumentData,
  DocumentSnapshot,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { AppUser } from "../../store/userStore";
import { Logger } from "../error-handling";
import { firebaseAppFirestoreDb } from "./config";
import { converter } from "./converter";
import { getCustomerById } from "./services/user-requests";

export const getAppUser = (user: User): Promise<AppUser> => {
  return Promise.all([
    getCustomerById(user.uid),
    getDocs(
      collection(
        firebaseAppFirestoreDb,
        FBCollections.Customers,
        user.uid,
        CustomerSubCollections.SubscribedBusinesses
      )
    ),
    getDocs(
      collection(
        firebaseAppFirestoreDb,
        FBCollections.Customers,
        user.uid,
        CustomerSubCollections.ScannedBusinesses
      ).withConverter(converter<ScannedBusinessFirestore>())
    ),
    getDoc(
      doc(
        firebaseAppFirestoreDb,
        FBCollections.BusinessUsers,
        user.uid
      ).withConverter(converter<BusinessUser>())
    ),
  ]).then(([customerData, ref, refScannedBusiness, businessUser]): AppUser => {
    return {
      ...user,
      firebaseUser: user,
      businessUser: businessUser.data(),
      customer: {
        ...customerData,
        id: user.uid,
        subscribed_businesses: ref.docs.map((d) => ({
          ...(d.data() as SubscribedBusinessFirestore),
          id: d.id,
        })),
        scanned_businesses: refScannedBusiness.docs
          .map((d) => ({
            ...d.data(),
            id: d.id,
          }))
          .sort((a, b) => (a.scans > b.scans ? -1 : 1)),
      },
    };
  });
};

export const getBusinessForOwner = async (
  document: DocumentSnapshot<DocumentData>,
  userId: string
): Promise<BusinessForOwner> => {
  const [
    subscribers,
    scansLast24,
    scansLast7Days,
    offers,
    currentUserPermission,
    eventQuery,
    loyaltyProgramsRef,
    lotteryProgramsRef,
  ] = await Promise.all([
    getDocs(
      collection(
        firebaseAppFirestoreDb,
        FBCollections.Businesses,
        document.id,
        BusinessSubCollections.SubscribedCustomers
      )
    ),
    getDocs(
      query(
        collection(firebaseAppFirestoreDb, FBCollections.Scans),
        where("created_at", ">=", dayjs().subtract(1, "days").toDate()),
        where("business_id", "==", document.id)
      )
    ),
    getDocs(
      query(
        collection(firebaseAppFirestoreDb, FBCollections.Scans),
        where("created_at", ">=", dayjs().subtract(7, "days").toDate()),
        where("business_id", "==", document.id)
      )
    ),
    getDocs(
      query(
        collection(
          firebaseAppFirestoreDb,
          FBCollections.Businesses,
          document.id,
          BusinessSubCollections.Offers
        )
      )
    ),
    getDoc(
      doc(
        firebaseAppFirestoreDb,
        FBCollections.Businesses,
        document.id,
        BusinessSubCollections.UserPermissions,
        userId
      ).withConverter(converter<BusinessInvitedUser>())
    ),
    getDocs(
      query(
        collection(
          firebaseAppFirestoreDb,
          FBCollections.Businesses,
          document.id,
          BusinessSubCollections.Events
        )
      )
    ),
    getDocs(
      query(
        collection(
          firebaseAppFirestoreDb,
          FBCollections.Businesses,
          document.id,
          BusinessSubCollections.Loyalty
        )
      )
    ),
    getDocs(
      query(
        collection(
          firebaseAppFirestoreDb,
          FBCollections.Businesses,
          document.id,
          BusinessSubCollections.Lottery
        )
      )
    ),
  ]);
  let usersPermissions;
  const userPermission = currentUserPermission.data();
  if (
    userPermission &&
    [UserRole.Admin, UserRole.Owner].includes(userPermission.role)
  ) {
    try {
      usersPermissions = await getDocs(
        query(
          collection(
            firebaseAppFirestoreDb,
            FBCollections.Businesses,
            document.id,
            BusinessSubCollections.UserPermissions
          )
        )
      );
    } catch (e) {
      Logger.errorSilent(
        e,
        document.id,
        BusinessSubCollections.UserPermissions
      );
    }
  }

  const events = eventQuery.docs.map<BusinessEvent>((ref) => ({
    ...(ref.data() as BusinessEventCreate),
    id: ref.id,
  }));
  const loyaltyPrograms = loyaltyProgramsRef.docs.map<ProgramLoyalty>(
    (ref) => ({
      ...(ref.data() as ProgramLoyalty),
      id: ref.id,
    })
  );
  const lotteryPrograms = lotteryProgramsRef.docs.map<ProgramLottery>(
    (ref) => ({
      ...(ref.data() as ProgramLottery),
      id: ref.id,
    })
  );
  const data = document.data() as BusinessFirestore;
  console.log(lotteryPrograms);
  return {
    ...data,
    id: document.id,
    subscribed_customers_count: subscribers.size || 0,
    current_user_permission:
      currentUserPermission.data() as BusinessInvitedUser,
    users_permissions:
      usersPermissions?.docs.map((d) => d.data() as BusinessInvitedUser) || [],
    programs: {
      filteredLoyalty: {
        active: loyaltyPrograms.find((l) => !l.archived_at),
        archived: loyaltyPrograms.filter((l) => !!l.archived_at) || [],
      },
      filteredLottery: {
        active: lotteryPrograms.find((l) => !l.archived_at),
        archived: lotteryPrograms.filter((l) => !!l.archived_at) || [],
      },
    },
    events: mapEvents(events),
    rawEvents: events,
    stats: {
      scansLast24: scansLast24.docs.length,
      scansLast7Days: scansLast7Days.docs.length,
    },
    offers: offers.docs.map(
      (ref) => ({ ...ref.data(), id: ref.id } as BusinessOffer)
    ),
  };
};

export const getBusinessForPublic = async (
  document: DocumentSnapshot<DocumentData>,
  userId?: string
): Promise<Business> => {
  const [
    subscribers,
    eventQuery,
    offers,
    loyaltyProgramsRef,
    lotteryProgramsRef,
  ] = await Promise.all([
    getDocs(
      collection(
        firebaseAppFirestoreDb,
        FBCollections.Businesses,
        document.id,
        BusinessSubCollections.SubscribedCustomers
      )
    ),
    getDocs(
      query(
        collection(
          firebaseAppFirestoreDb,
          FBCollections.Businesses,
          document.id,
          BusinessSubCollections.Events
        )
      )
    ),
    getDocs(
      query(
        collection(
          firebaseAppFirestoreDb,
          FBCollections.Businesses,
          document.id,
          BusinessSubCollections.Offers
        )
      )
    ),
    getDocs(
      query(
        collection(
          firebaseAppFirestoreDb,
          FBCollections.Businesses,
          document.id,
          BusinessSubCollections.Loyalty
        ),
        where("archived_at", "==", null)
      )
    ),
    getDocs(
      query(
        collection(
          firebaseAppFirestoreDb,
          FBCollections.Businesses,
          document.id,
          BusinessSubCollections.Lottery
        )
      )
    ),
  ]);

  const currentUserPermission = userId
    ? await getDoc(
        doc(
          firebaseAppFirestoreDb,
          FBCollections.Businesses,
          document.id,
          BusinessSubCollections.UserPermissions,
          userId
        )
      )
    : undefined;
  const data = document.data() as BusinessFirestore;
  const events = eventQuery.docs.map<BusinessEvent>((ref) => ({
    ...(ref.data() as BusinessEventCreate),
    id: ref.id,
  }));
  const loyaltyPrograms = loyaltyProgramsRef.docs.map<ProgramLoyalty>(
    (ref) => ({
      ...(ref.data() as ProgramLoyalty),
      id: ref.id,
    })
  );
  const lotteryPrograms = lotteryProgramsRef.docs.map<ProgramLottery>(
    (ref) => ({
      ...(ref.data() as ProgramLottery),
      id: ref.id,
    })
  );

  return {
    ...data,
    id: document.id,
    subscribed_customers_count: subscribers.size || 0,
    programs: {
      filteredLoyalty: {
        active: loyaltyPrograms.find((l) => !l.archived_at),
        archived: [],
      },
      filteredLottery: {
        active: lotteryPrograms.find((l) => !l.archived_at),
        archived: lotteryPrograms.filter((l) => !!l.archived_at) || [],
      },
    },
    events: mapEvents(events),
    rawEvents: events,
    offers: offers.docs.map(
      (ref) => ({ ...ref.data(), id: ref.id } as BusinessOffer)
    ),
    hasOwnership: currentUserPermission ? true : undefined,
  };
};

export function mapEvents(events?: BusinessEvent[]) {
  const now = Math.ceil(new Date().getTime() / 1000);

  const todayEvent = events?.find(
    (e) => e.date_start?.toDate().toDateString() === new Date().toDateString()
  );
  return {
    today: todayEvent,
    future:
      events
        ?.filter((e) => e.date_start?.seconds > now && todayEvent?.id !== e.id)
        .sort((a, b) => b.date_start?.seconds - a.date_start?.seconds) || [],
    past:
      events
        ?.filter((e) => e.date_start?.seconds < now && todayEvent?.id !== e.id)
        .sort((a, b) => b.date_start?.seconds - a.date_start?.seconds) || [],
  };
}
