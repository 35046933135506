import { useEffect } from "react";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";

import { usePathname } from "@/common/layout/routes/hooks";
import { NavLink } from "react-router-dom";

import { useResponsive } from "@/common/layout/hooks/use-responsive";

import { account } from "@/common/layout/_mock/account";

import Scrollbar from "@/common/layout/components/scrollbar/scrollbar";

import { Link, ListItemButton } from "@mui/material";
import { MenuNavItem } from "../../OwnerLayout";
import { NAV } from "./config-layout";

import CheersImg from "@/assets/cheers.png";
import Logo from "@/assets/logo.webp";
import { useOwnerMenu } from "@/common/hooks/useOwnerMenu";
import { Link as RouterLink } from "react-router-dom";

// ----------------------------------------------------------------------

export default function Nav({
  openNav,
  onCloseNav,
}: {
  openNav: boolean;
  onCloseNav: () => void;
}) {
  const pathname = usePathname();
  const ownerMenu = useOwnerMenu();
  const upLg = useResponsive("up", "lg");

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderAccount = (
    <Box
      sx={{
        my: 3,
        mx: 2.5,
        py: 2,
        px: 2.5,
        display: "flex",
        borderRadius: 1.5,
        alignItems: "center",
        bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
      }}>
      <Avatar src={account.photoURL} alt="photoURL" />

      <Box sx={{ ml: 2 }}>
        <Typography variant="subtitle2">{account.displayName}</Typography>

        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {/* {account.role} */}
        </Typography>
      </Box>
    </Box>
  );

  const renderMenu = (
    <Stack component="nav" spacing={0.5} sx={{ px: 2, py: 4 }}>
      {ownerMenu.flatMap((item) =>
        item.map((item) => <NavItem key={item.label} item={item} />)
      )}
    </Stack>
  );

  const renderUpgrade = (
    <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
      <Stack
        alignItems="center"
        spacing={3}
        sx={{ pt: 5, borderRadius: 2, position: "relative" }}>
        <Box
          component="img"
          src={CheersImg}
          sx={{ width: 100, position: "absolute", top: -50 }}
        />

        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h6">Des questions ?</Typography>

          {/* <Typography variant="body2" sx={{ color: "text.secondary", mt: 1 }}>
            From only $69
          </Typography> */}
        </Box>

        <Button
          component={NavLink}
          to={"/owner/contact"}
          variant="contained"
          color="inherit">
          Contactez nous !
        </Button>
      </Stack>
    </Box>
  );

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}>
      <Box sx={{ pt: 3, px: 4, display: "flex", justifyContent: "center" }}>
        <Link component={RouterLink} to="/" sx={{ display: "contents" }}>
          <Avatar src={Logo}></Avatar>
        </Link>
      </Box>

      {/* {renderAccount} */}

      {renderMenu}

      <Box sx={{ flexGrow: 1 }} />

      {renderUpgrade}
    </Scrollbar>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.WIDTH },
      }}>
      {upLg ? (
        <Box
          sx={{
            height: 1,
            position: "fixed",
            width: NAV.WIDTH,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}>
          {renderContent}
        </Box>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.WIDTH,
            },
          }}>
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

// ----------------------------------------------------------------------

function NavItem({ item }: { item: MenuNavItem }) {
  const pathname = usePathname();

  const active = item.to === pathname;

  return (
    <ListItemButton
      component={NavLink}
      to={item.to as string}
      sx={{
        minHeight: 44,
        borderRadius: 0.75,
        typography: "body2",
        color: "text.secondary",
        textTransform: "capitalize",
        fontWeight: "fontWeightMedium",
        ...(active && {
          color: "primary.main",
          fontWeight: "fontWeightSemiBold",
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
          "&:hover": {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
          },
        }),
      }}>
      <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
        {item.icon}
      </Box>

      <Box component="span">{item.label} </Box>
    </ListItemButton>
  );
}
