import { useBusinessCollection } from "@/common/firebase/services/owner-hooks";
import PlaceholderImage from "@assets/bar_placeholder.jpg";
import NoImage from "@assets/no-image.jpg";

import Address from "@/common/components/Address";
import { getImageUrl } from "@/common/firebase/common";
import { useBusinessSubscription } from "@/common/hooks/useBusinessSubscription";
import { BusinessForOwner } from "@common/index";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { Link } from "react-router-dom";
import { useSetCurrentPageName } from "../store/appSettingsStore";
import { useUserStore } from "../store/userStore";

export default function BusinessList() {
  useSetCurrentPageName("Vos établissements");
  const user = useUserStore();
  // const { businessList } = useDiscoverBusinessList(user.user?.uid);
  const {
    collection: businessList,
    isError,
    isLoading,
  } = useBusinessCollection(user.user?.uid as string);

  const { subscriptionMode } = useBusinessSubscription(undefined);
  // const [businessList, setBusinessList] = useState<any[]>([]);

  // useEffect(() => {
  //   if (!user.user) return;
  //   // Create query for messages
  //   const asyncfetch = async () => {
  //     console.log("asyc fetch");
  //     const list = await getDocs(
  //       query(
  //         collection(db, "businesses"),
  //         // orderBy("timestamp", "desc")
  //         where("owner_id", "==", user.user?.uid)
  //       )
  //     );
  //     console.log(list.docs);
  //     setBusinessList(list.docs.map((doc) => doc.data()));
  //   };

  //   asyncfetch();
  // }, [user.user]);

  return (
    <Container component="main" sx={{ px: 0 }}>
      <CssBaseline />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={3}>
          <Card sx={{ height: "100%" }} variant="rounded">
            <CardActionArea
              component={Link}
              sx={{ height: 1 }}
              to={`/owner/business/create`}>
              <CardMedia
                sx={{ height: 140 }}
                image={PlaceholderImage}
                title="green iguana"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="body2"
                  component="div"
                  color="text.secondary">
                  {!businessList?.length
                    ? "Cliquez ici pour ajouter votre premier établissement dans notre répertoire."
                    : "Cliquez ici pour ajoutez un autre établissement que vous gérez."}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        {isLoading &&
          [0, 1].map((v) => (
            <Grid item xs={12} md={4} lg={3} key={v}>
              <Card sx={{ height: "100%", position: "relative" }}>
                <Skeleton variant="rectangular" sx={{ height: 140 }} />
                <Chip
                  label="Owner"
                  color="info"
                  sx={{ position: "absolute", top: 5, right: 5 }}
                />
                <CardContent>
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </CardContent>
              </Card>
            </Grid>
          ))}
        {businessList?.map((business) => (
          <Grid item key={business.id} xs={12} md={4} lg={3}>
            <Card sx={{ height: "100%", position: "relative" }}>
              <CardActionArea
                component={Link}
                sx={{ height: 1 }}
                to={`/owner/business/${business.id}`}>
                <CardMedia
                  sx={{ height: 140 }}
                  image={
                    (business.cover_picture &&
                      getImageUrl(business.cover_picture.key)) ||
                    NoImage
                  }
                  title="green iguana"
                />
                <Stack
                  spacing={1}
                  sx={{ position: "absolute", top: 5, right: 5 }}>
                  <Chip
                    label={
                      (business as BusinessForOwner).current_user_permission
                        .role
                    }
                    color="info"
                  />
                  {business.status === "draft" && (
                    <Chip label="Brouillon" color="warning" />
                  )}
                  {subscriptionMode &&
                    (business.subscription_active ? (
                      <Chip label="Pro" color="success" />
                    ) : (
                      <Chip label="Gratuit" color="default" />
                    ))}
                </Stack>
                <CardContent sx={{ pb: 3 }}>
                  <Stack direction="column">
                    <Typography gutterBottom variant="subtitle2" noWrap>
                      {business.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <Address address={business.address}></Address>
                    </Typography>
                  </Stack>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
