import { MapFilters } from "@/customer/HomeFilters";
import { create } from "zustand";

interface MapState {
  results: any;
  location: { lat: number; lng: number } | null;
  filters: MapFilters | null;
  setResults: (results: any | null) => void;
  setLocation: (location: { lat: number; lng: number } | null) => void;
  setFilters: (filters: MapFilters) => void;
}

export const useMapStore = create<MapState>((set) => ({
  results: null,
  location: null,
  filters: null,
  setResults: (results: any | null) => set({ results }),
  setLocation: (location: { lat: number; lng: number } | null) =>
    set({ location }),
  setFilters: (filters: MapFilters) => set({ filters }),
}));
