import { useBusinessCollection } from "@/common/firebase/services/owner-hooks";
import {
  useGetBusinessSubscription,
  useListStripePaymentMethods,
} from "@/common/firebase/services/stripe-requests";
import { CreditCard, Download, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  OutlinedInput,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";

import { CurrencyMap } from "@/common/common";
import { Logger } from "@/common/error-handling";
import { firebaseAppFunctions } from "@/common/firebase/config";
import { Capacitor } from "@capacitor/core";
import { InAppBrowser } from "@capgo/inappbrowser";
import { BusinessForOwner, UserRole } from "@common/index";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";
import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Stripe from "stripe";
import { useSetCurrentPageName } from "../store/appSettingsStore";
import { useUserStore } from "../store/userStore";
import ProductCarrousel from "./forms/ProductCarrousel";
const stripe = new Stripe(
  "sk_test_51OZhZkF8ndPimQzmtIXpNR8DcFwKBMmgeAs6G5l7N02QZCOjObG46OT3QHhdMXZ3ogH0TvEQrZoStglCryDDQwQh00rPiCZ4YH"
);

// const stripe = require('stripe')('');
// const configuration = await stripe.billingPortal.configurations.create({
//   business_profile: {
//     headline: "Annulation",
//   },
//   features: {
//     subscription_cancel: {
//       enabled: true,
//       mode: "at_period_end",
//     },
//   },
// });
// console.log(configuration);

export enum StripeConfigurations {
  CancelImmediately = "bpc_1OmOorF8ndPimQzmMc8VlNKz",
  CancelAtPeriodEnd = "bpc_1OmOqRF8ndPimQzmHltycCeP",
}

export default function BillingContainer() {
  useSetCurrentPageName("Facturation");
  const userState = useUserStore();
  const [isCreatinPortalLink, setIsCreatinPortalLink] = useState(false);
  const {
    collection: paymentMethods,
    isLoading: isLoadingPm,
    mutate,
  } = useListStripePaymentMethods();
  const [searchParams] = useSearchParams();

  const {
    collection: businessCollection,
    isLoading: isLoadingBusinessCollection,
  } = useBusinessCollection(userState.user?.uid as string, [
    UserRole.Admin,
    UserRole.Owner,
  ]);

  async function goToCustomerPortalEditPaymentMethod() {
    setIsCreatinPortalLink(true);
    const functionRef = httpsCallable(
      firebaseAppFunctions,
      "ext-firestore-stripe-payments-ehqk-createPortalLink"
    );

    try {
      const { data } = await functionRef({
        returnUrl: window.location.href,
        locale: "auto",
        configuration: "bpc_1Obt0uF8ndPimQzm0PGeJd6Q",
      });

      if (Capacitor.isNativePlatform()) {
        try {
          await InAppBrowser.open({ url: (data as any).url });
          const browserHandlerCloseEvent = await InAppBrowser.addListener(
            "closeEvent",
            () => {
              browserHandlerCloseEvent.remove();
              mutate();
            }
          );

          const browserHandlerUrlEvent = await InAppBrowser.addListener(
            "urlChangeEvent",
            (changedRoute) => {
              if (changedRoute.url.search(window.location.href)) {
                browserHandlerUrlEvent.remove();
                mutate();
              }
            }
          );
        } catch (e) {
          Logger.error(e);
        }
      } else {
        window.location.assign((data as any).url);
      }
    } catch (e) {
      setIsCreatinPortalLink(false);
      Logger.error(e);
    }
  }

  return (
    <Container component="main" maxWidth="lg" disableGutters>
      <Typography
        sx={{ mt: 1 }}
        component="h2"
        variant="h6"
        color="text.primary"
        gutterBottom>
        Abonnements
      </Typography>
      {isLoadingBusinessCollection ? (
        <Skeleton variant="rectangular" width={"100%"} height={50} />
      ) : (
        businessCollection?.map((business) => {
          return (
            <BusinessAccordion
              business={business}
              defaultExpanded={
                businessCollection.length === 1 ||
                business.id === searchParams.get("id")
              }
              key={business.id}
            />
          );
        })
      )}
      <Typography
        sx={{ mt: 2 }}
        component="h2"
        variant="h6"
        color="text.primary"
        gutterBottom>
        Méthodes de paiement
      </Typography>
      <Grid container spacing={2}>
        {isLoadingPm ? (
          <Grid item>
            <Card>
              <CardContent>
                <Skeleton
                  variant="rectangular"
                  width={150}
                  height={100}
                  sx={{ mb: 1 }}
                />
                <Skeleton variant="rectangular" width={150} height={100} />
              </CardContent>
            </Card>
          </Grid>
        ) : (
          paymentMethods?.map((pm) => (
            <Grid key={pm.id} item xs sm={6} md={4} lg={3}>
              <Card variant="outlined">
                <CardContent>
                  <Stack direction={"column"} sx={{ pb: 2 }}>
                    <OutlinedInput
                      value={"**** **** **** " + pm.card?.last4}
                      disabled
                      endAdornment={
                        <InputAdornment position="end">
                          <CreditCard />
                        </InputAdornment>
                      }
                    />
                    <Typography variant="caption">
                      {" "}
                      Exp: {pm.card?.exp_month.toString().padStart(2, "0")}/
                      {pm.card?.exp_year.toString()}
                    </Typography>
                  </Stack>
                  <Typography variant="h6">
                    {pm.billing_details.name}
                  </Typography>
                  <Typography variant="body1">
                    {pm.billing_details.address?.line1}{" "}
                    {pm.billing_details.address?.line2},{" "}
                    {pm.billing_details.address?.city}{" "}
                    {pm.billing_details.address?.country}{" "}
                    {pm.billing_details.address?.state}
                  </Typography>
                  <Typography variant="body1">
                    {pm.billing_details.email}
                  </Typography>
                  <Typography variant="body1">
                    {pm.billing_details.phone}
                  </Typography>
                </CardContent>
                <CardActions>
                  <LoadingButton
                    loading={isCreatinPortalLink}
                    variant={"contained"}
                    onClick={goToCustomerPortalEditPaymentMethod}>
                    Modifier
                  </LoadingButton>
                </CardActions>
              </Card>
            </Grid>
          ))
        )}
      </Grid>
    </Container>
  );
}

export function BusinessAccordion({
  business,
  defaultExpanded = false,
}: {
  business: BusinessForOwner;
  defaultExpanded: boolean;
}) {
  const userState = useUserStore();
  // const { collection: productsCollections } = useGetProductsPrice();
  const { collection: subscription, mutate } = useGetBusinessSubscription(
    business.id
  );
  // const [checkoutCreatingId, setCheckoutCreatingId] = useState("");
  const [creatingCustomerPortalLink, setCreatingCustomerPortalLink] =
    useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  async function goToCustomerPortal(
    subId: string,
    configId: StripeConfigurations
  ) {
    setCreatingCustomerPortalLink(true);
    const functionRef = httpsCallable(
      firebaseAppFunctions,
      "ext-firestore-stripe-payments-ehqk-createPortalLink"
    );

    try {
      const { data } = await functionRef({
        returnUrl: window.location.href,
        locale: "auto",
        configuration: configId,
        flow_data: {
          type: "subscription_cancel",
          subscription_cancel: {
            subscription: subId,
          },
        },
      });
      if (Capacitor.isNativePlatform()) {
        try {
          await InAppBrowser.open({ url: (data as any).url });
          const browserHandlerCloseEvent = await InAppBrowser.addListener(
            "closeEvent",
            () => {
              browserHandlerCloseEvent.remove();
              setTimeout(() => {
                mutate();
              }, 1500);
            }
          );

          const browserHandlerUrlEvent = await InAppBrowser.addListener(
            "urlChangeEvent",
            (changedRoute) => {
              if (
                changedRoute.url.search("/flow-confirmation") > 0 ||
                changedRoute.url.search(window.location.href)
              ) {
                browserHandlerUrlEvent.remove();
                setTimeout(() => {
                  mutate();
                }, 1500);
              }
            }
          );
        } catch (e) {
          Logger.error(e);
        }
      } else {
        window.location.assign((data as any).url);
      }
    } catch (e) {
      setCreatingCustomerPortalLink(false);
      Logger.error(e);
    }
  }

  // async function createCheckout(business: Business, priceId: string) {
  //   setCheckoutCreatingId(priceId);
  //   await createSubscriptionCheckout(
  //     userState.user?.uid as string,
  //     business,
  //     priceId,
  //     (hasError) => {
  //       setTimeout(() => {
  //         mutate();
  //       }, 3000);

  //       setCheckoutCreatingId("");

  //       if (!hasError) {
  //         Logger.success("Merci pour votre paiement !");
  //       }
  //     }
  //   );
  // }
  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1-content"
        id="panel1-header">
        <Typography sx={{ width: "33%", flexShrink: 0 }}>
          {business.name}
        </Typography>
        <Chip
          label={subscription ? "Pro" : "Gratuit"}
          color={subscription ? "success" : "default"}
        />
        {subscription?.canceled_at && (
          <Chip sx={{ ml: 1 }} label={"Annulé"} color={"error"} />
        )}
      </AccordionSummary>
      <AccordionDetails sx={{ display: "flex" }}>
        {subscription && (
          <Grid container spacing={2} sx={{ width: 1 }}>
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}>
                    <Typography>Souscribtion "Pro"</Typography>

                    <Typography>
                      {Number(
                        subscription.items[0].price.unit_amount / 100
                      ).toFixed(2)}
                      {
                        CurrencyMap[
                          subscription.items[0].price.currency as "eur"
                        ]
                      }
                      /mo
                    </Typography>
                  </Box>
                  <Typography variant="caption">
                    {subscription.canceled_at
                      ? "Prendra fin le :"
                      : "Renouvellement :"}{" "}
                    {dayjs(subscription.current_period_end.toDate()).format(
                      "LL"
                    )}
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: "end", pt: 0 }}>
                  <LoadingButton
                    loading={creatingCustomerPortalLink}
                    onClick={() => setOpenCancelDialog(true)}>
                    Annuler
                  </LoadingButton>
                  <Dialog
                    open={openCancelDialog}
                    onClose={() => setOpenCancelDialog(false)}
                    PaperProps={{
                      component: "form",
                      onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const configId = formData.get(
                          "cancel_mode"
                        ) as StripeConfigurations;

                        if (!configId) {
                          return;
                        }
                        setOpenCancelDialog(false);
                        goToCustomerPortal(subscription.id, configId);
                        () => setOpenCancelDialog(false);
                      },
                    }}>
                    <DialogTitle>Annulation</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Quand voulez vous annuler ?
                      </DialogContentText>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue={StripeConfigurations.CancelImmediately}
                          name="cancel_mode">
                          <FormControlLabel
                            value={StripeConfigurations.CancelImmediately}
                            control={<Radio />}
                            label="Immédiatement (vous serez remboursé au prorata)"
                          />
                          <FormControlLabel
                            value={StripeConfigurations.CancelAtPeriodEnd}
                            control={<Radio />}
                            label="Ne pas renouveller (annulation à la fin de la période en cours)"
                          />
                        </RadioGroup>
                      </FormControl>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setOpenCancelDialog(false)}>
                        Retour
                      </Button>
                      <Button type="submit">Annuler l'abonnement</Button>
                    </DialogActions>
                  </Dialog>
                </CardActions>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card variant="outlined">
                <CardHeader
                  sx={{ pb: 0 }}
                  title="Factures"
                  titleTypographyProps={{ variant: "h6" }}
                />
                <CardContent>
                  <List disablePadding>
                    {subscription.invoices.map((invoice: any) => (
                      <ListItem
                        key={invoice.invoice_pdf}
                        disableGutters
                        secondaryAction={
                          <IconButton
                            component={"a"}
                            edge="end"
                            aria-label="delete"
                            href={invoice.invoice_pdf}
                            target="_blank">
                            <Download />
                          </IconButton>
                        }>
                        <ListItemText
                          primaryTypographyProps={{
                            sx: {
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            },
                          }}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm>
                              {invoice.number}
                            </Grid>
                            <Grid item xs={12} sm>
                              <Chip
                                label={
                                  Number(invoice.total / 100).toFixed(2) +
                                  CurrencyMap[invoice.currency as "eur"]
                                }
                                color="info"
                              />
                            </Grid>
                            <Grid item xs={12} sm>
                              <Chip
                                color={
                                  invoice.status === "paid" ? "success" : "info"
                                }
                                label={`${invoice.status} - ${dayjs(
                                  invoice.status_transitions.paid_at * 1000
                                ).format("LL")}`}
                              />
                            </Grid>
                          </Grid>
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
        {!subscription && (
          <ProductCarrousel
            business={business}
            disableFreePlan={true}
            subscription={subscription}
            from={"billing"}
          />
          // <Grid
          //   container
          //   spacing={5}
          //   alignItems="flex-end"
          //   justifyContent={"center"}>
          //   {productsCollections
          //     ?.sort(
          //       (a: any, b: any) => a.price.unit_amount - b.price.unit_amount
          //     )
          //     .map((product: any) => (
          //       // Enterprise card is full width at sm breakpoint
          //       <Grid
          //         item
          //         key={product.id}
          //         xs={12}
          //         sm={6}
          //         md={4}
          //         sx={{ height: { xs: "auto", sm: 1 } }}>
          //         <Card
          //           sx={{
          //             height: 1,
          //             display: "flex",
          //             flexFlow: "column",
          //           }}
          //           elevation={
          //             (!subscription && product.price.unit_amount === 0) ||
          //             product.price.id === subscription?.price.id
          //               ? 4
          //               : 2
          //           }>
          //           <CardHeader
          //             title={product.name}
          //             subheader={product.description}
          //             titleTypographyProps={{ align: "center" }}
          //             action={
          //               (!subscription && product.price.unit_amount === 0) ||
          //               product.price.id === subscription?.price.id ? (
          //                 <Check />
          //               ) : (
          //                 ""
          //               )
          //             }
          //             subheaderTypographyProps={{
          //               align: "center",
          //             }}
          //             sx={{
          //               backgroundColor: (theme) =>
          //                 (!subscription && product.price.unit_amount === 0) ||
          //                 product.price.id === subscription?.price.id
          //                   ? theme.palette.primary.light
          //                   : theme.palette.grey[200],
          //             }}
          //           />
          //           <CardContent sx={{ flex: 1 }}>
          //             <Box
          //               sx={{
          //                 display: "flex",
          //                 justifyContent: "center",
          //                 alignItems: "baseline",
          //                 mb: 2,
          //               }}>
          //               {product.price.unit_amount ? (
          //                 <>
          //                   <Typography
          //                     component="h2"
          //                     variant="h4"
          //                     color="text.primary">
          //                     {CurrencyMap[product.price.currency as "eur"]}
          //                     {Number(product.price.unit_amount / 100).toFixed(
          //                       2
          //                     )}
          //                   </Typography>
          //                   <Typography variant="h6" color="text.secondary">
          //                     /mo
          //                   </Typography>
          //                 </>
          //               ) : (
          //                 <Typography
          //                   component="h2"
          //                   variant="h4"
          //                   color="text.primary">
          //                   Gratuit
          //                 </Typography>
          //               )}
          //             </Box>
          //             <ul>
          //               {Object.keys(product.metadata)
          //                 .sort((a: any, b: any) => a - b)
          //                 .map((key: any) => (
          //                   <Typography
          //                     component="li"
          //                     variant="subtitle1"
          //                     key={product.metadata[key]}>
          //                     {product.metadata[key]}
          //                   </Typography>
          //                 ))}
          //             </ul>
          //           </CardContent>
          //           <CardActions>
          //             <LoadingButton
          //               fullWidth
          //               loading={checkoutCreatingId === product.price.id}
          //               disabled={
          //                 !!checkoutCreatingId ||
          //                 (!subscription && product.price.unit_amount === 0) ||
          //                 product.price.id === subscription?.price.id
          //               }
          //               onClick={() =>
          //                 createCheckout(business, product.price.id)
          //               }
          //               variant={"contained"}>
          //               Sélectionner ce plan
          //             </LoadingButton>
          //           </CardActions>
          //         </Card>
          //       </Grid>
          //     ))}
          // </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
