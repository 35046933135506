import { Search as SearchIcon } from "@mui/icons-material";
import {
  Button,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListSubheader,
  OutlinedInput,
} from "@mui/material";
import Container from "@mui/material/Container";
import { useSetCurrentPageName } from "../store/appSettingsStore";
import { useUserStore } from "../store/userStore";
import { BusinessListItem } from "./Home";
export default function Search() {
  useSetCurrentPageName("Recherche");
  const userState = useUserStore();

  return (
    <Container component="main" maxWidth="lg" sx={{ overflow: "unset", pt: 6 }}>
      <Grid container rowSpacing={4.5}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={true}>
              <OutlinedInput
                id="input-with-icon-textfield"
                fullWidth
                placeholder="Recherche un établissement"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={"auto"}>
              <Button variant="contained" fullWidth sx={{ height: 1 }}>
                <SearchIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <List>
            <ListSubheader sx={{ backgroundColor: "transparent" }}>
              3 resultats
            </ListSubheader>
            <ListItem>
              <BusinessListItem
                data={{ name: "patate" } as any}></BusinessListItem>
            </ListItem>
            <ListItem>
              <BusinessListItem
                data={{ name: "patate" } as any}></BusinessListItem>
            </ListItem>
            <ListItem>
              <BusinessListItem
                data={{ name: "patate" } as any}></BusinessListItem>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Container>
  );
}
