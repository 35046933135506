import { Logger } from "@/common/error-handling";
import { firebaseAppFunctions } from "@/common/firebase/config";
import { getCustomerById } from "@/common/firebase/services/user-requests";
import { FBCloudFunctions } from "@common/firebase";
import {
  BusinessScanRequest,
  BusinessScanResponse,
  ClaimValue,
  UserCardValue,
} from "@common/index";
import { Business, BusinessProgram } from "@common/model/business";
import { CustomerFirestore2 } from "@common/model/customer";
import { ProgramType } from "@common/model/scan";
import { CloseRounded } from "@mui/icons-material";
import {
  Alert,
  AppBar,
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { httpsCallable } from "firebase/functions";
import debounce from "lodash.debounce";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { OnResultFunction, QrReader } from "react-qr-reader";
import { useClaimRewardDialog } from "./ClaimRewardDialog";

let isScanInProgress = false;
export const QRScannerWeb = ({
  submitScanCallback,
  handleClose,
  business,
  program,
}: {
  submitScanCallback?: (
    customer: CustomerFirestore2,
    scannedValue: string,
    businessId: string
  ) => void;
  handleClose: () => void;
  business: Business;
  program?: BusinessProgram;
}) => {
  const [isScanned, setIsScanned] = useState<{
    customer: CustomerFirestore2;
    programTypes: ProgramType[] | null;
  } | null>(null);
  const [isScanning, setIsScanning] = useState(false);
  const [isScanError, setIsScanError] = useState<string[]>([]);
  const { t } = useTranslation("translation");
  const { setClaimResult, claimDialog } = useClaimRewardDialog(() => {
    setIsScanning(false);
    setIsScanError([]);
    setIsScanned(null);
    isScanInProgress = false;
  });

  const submitScan = async (
    customer: CustomerFirestore2,
    scannedValue: string,
    businessId: string
  ) => {
    const loyaltyProgram = business.programs?.filteredLoyalty?.active;
    const lotteryProgram = business.programs?.filteredLottery.active;
    if (!loyaltyProgram && !lotteryProgram && !program) return;
    try {
      const businessScanRequest = httpsCallable<
        BusinessScanRequest,
        BusinessScanResponse
      >(firebaseAppFunctions, FBCloudFunctions.BusinessScan);
      const errors: string[] = [];

      const result = await businessScanRequest({
        scannedValue,
        businessId,
        action:
          program?.type === ProgramType.lottery ? "lotteryDraw" : undefined,
        programId: program ? program.id : undefined,
      }).then((result) => {
        return result.data;
      });

      if (result.type === "program" && result.data) {
        const data = result.data;
        const successTypes = Object.values(ProgramType)
          .map((type) => (data[type]?.success ? type : null))
          .filter((v) => !!v) as ProgramType[];
        successTypes.forEach((type) => {
          Logger.success(
            `[${t("programs." + type)}] [${customer.username}] : Validé`
          );
        });

        setIsScanned({
          customer,
          programTypes: successTypes,
        });
        setIsScanError(
          [result.data.lottery?.error, result.data.loyalty?.error].filter(
            (v) => !!v
          ) as string[]
        );
        setIsScanning(false);
      } else if (result.type === "claim") {
        setIsScanning(false);
        setIsScanned({
          customer,
          programTypes: null,
        });
        setClaimResult(result.data, result.data.reward[0], customer);
      } else if ("error" in result) {
        errors.push(result.error);
        setIsScanError(errors);
        setIsScanning(false);
      }

      if (result.type !== "claim") {
        setTimeout(() => {
          setIsScanError([]);
          setIsScanned(null);
          isScanInProgress = false;
        }, 5000);
      }

      if (submitScanCallback) {
        submitScanCallback(customer, scannedValue, businessId);
        return;
      }
    } catch (e) {
      isScanInProgress = false;
      setIsScanning(false);
    }
    return;
  };

  const handleScan: OnResultFunction = async (result, error) => {
    try {
      if (
        !!result &&
        !isScanning &&
        !isScanned &&
        !isScanError.length &&
        !isScanInProgress
      ) {
        setIsScanning(true);
        isScanInProgress = true;
        try {
          const userid = (
            JSON.parse(result.getText()) as UserCardValue | ClaimValue
          ).u;
          const user = await getCustomerById(userid);

          // const activePrograms = [
          //   business.programs?.filteredLoyalty.active,
          //   business.programs?.filteredLottery.active,
          // ].filter((v) => !!v) as BusinessProgram[];

          await submitScan(user, result.getText(), business.id);
        } catch (e) {
          Logger.error(e);
          setIsScanning(false);
        }
      }
    } catch (e) {
      isScanInProgress = false;
      setIsScanning(false);
      Logger.error(e);
    }
    isScanInProgress = false;
  };
  const debounced = useCallback(debounce(handleScan, 1500), []);

  return (
    <Box>
      {claimDialog}
      {/* <div
        style={{
          width: "250px",
          height: "250px",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          position: "absolute",
          bottom: "25px",
          left: "50%",
          transform: "translate(-50%, 0)",
        }}>
        <Stack spacing={1}>
          {isScanned &&
            isScanned.programTypes.map((type) => (
              <Alert severity="success">
                [{type}] [{isScanned.customer.username}] : Validé
              </Alert>
            ))}
          {isScanning && <CircularProgress />}
          {isScanError.map((error) => (
            <Alert severity="error">Erreur : {error}</Alert>
          ))}
        </Stack>
      </div> */}
      <Box id="box">
        <AppBar position="static">
          <Toolbar>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                flexGrow: 1,
              }}>
              Scan
            </Typography>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleClose}>
              <CloseRounded />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Container
          sx={{
            p: 0,
            pb: 4,
            justifyContent: "center",
            textAlign: "center",
          }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  maxWidth: "400px",
                  maxHeight: "400px",
                  position: "relative",
                }}>
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 99,
                  }}>
                  <div
                    style={{
                      width: "250px",
                      height: "250px",
                      border: "3px dashed white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    <Stack spacing={1}>
                      {isScanned &&
                        isScanned.programTypes &&
                        isScanned.programTypes.map((type) => (
                          <Alert severity="success">
                            [{t("programs." + type)}] [
                            {isScanned.customer.username}] : Validé
                          </Alert>
                        ))}
                      {isScanning && <CircularProgress />}
                      {isScanError.map((error) => (
                        <Alert severity="error">Erreur : {error}</Alert>
                      ))}
                    </Stack>
                  </div>
                </div>
                <QrReader
                  constraints={{ facingMode: "user" }}
                  onResult={(result, error) => {
                    if (result) {
                      setIsScanning(true);
                      debounced(result, error);
                    }
                  }}
                  videoStyle={{
                    width: "100%",
                    opacity: isScanned || isScanning ? 0.1 : 1,
                  }}
                  scanDelay={1000}
                />
              </Box>
            </Grid>
          </Grid>

          {/* <Typography id="transition-modal-description" sx={{ mt: 2 }}>
          Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
        </Typography> */}
        </Container>
      </Box>
    </Box>
  );
};
