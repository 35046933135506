import { Logger } from "@/common/error-handling";
import { deleteBusinessUserPermission } from "@/common/firebase/services/owner-requests";
import { useCustomer } from "@/common/firebase/services/user-hooks";
import { useUserStore } from "@/store/userStore";
import { Business } from "@common/index";
import { Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Chip, TableCell, TableRow, Tooltip } from "@mui/material";
import { useState } from "react";

export const BusinessUserRow = ({
  userId,
  role,
  businesses,
  businessIds,
  mutate,
}: {
  businesses?: Business[];
  businessIds?: string[];
  mutate: () => void;
  [key: string]: any;
}) => {
  const { customer } = useCustomer(userId);
  const { user } = useUserStore();
  const [loading, setLoading] = useState(false);

  const removeUserPermission = async () => {
    try {
      setLoading(true);
      await Promise.all(
        businessIds?.map((id) => {
          return deleteBusinessUserPermission(id, userId);
        }) || []
      );
      mutate();
      setLoading(false);
      Logger.success("Utilisateur supprimé");
    } catch (e) {
      setLoading(false);
      Logger.error(e);
    }
  };

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell>
        {customer?.firstname} {customer?.lastname}{" "}
        {customer?.id === user?.uid ? "(Vous)" : ""}
      </TableCell>
      <TableCell align="right">
        <Chip color="warning" label={role}></Chip>
      </TableCell>
      <TableCell align="right">
        <Chip label="---------" color="info"></Chip>
      </TableCell>
      {businessIds && businesses && (
        <TableCell align="right">
          <Tooltip
            title={businessIds
              .map(
                (id) => businesses.find((business) => business.id === id)?.name
              )
              .join(", ")}>
            <Chip label={businessIds.length} color="info"></Chip>
          </Tooltip>
        </TableCell>
      )}
      <TableCell align="right">
        {customer?.id !== user?.uid && (
          <>
            {/* <IconButton>
                <Edit />
              </IconButton> */}
            <LoadingButton loading={loading} onClick={removeUserPermission}>
              <Delete />
            </LoadingButton>
          </>
        )}
      </TableCell>
    </TableRow>
  );
};
