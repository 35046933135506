import { ComponentErrorBoundary } from "@/common/error-boundary";
import { Logger } from "@/common/error-handling";
import {
  createBusinessLottery,
  updateBusinessLottery,
} from "@/common/firebase/services/owner-requests";
import { ProgramLottery, ProgramLotteryForm } from "@common/model/business";
import { ProgramType } from "@common/model/scan";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { Timestamp } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { bool, number, object, string } from "yup";
import { BusinessFormComponentProps } from "../tabs/BusinessDashboard";
import { YupRewardValidators } from "./utils";

const schema = object<ProgramLottery>({
  title: string().required(),
  description: string().required(),
  conditions: object({
    uniqueParticipant: bool().required(),
    numberOfWinnersToDraw: number().required().min(1),
    // fulfillCondition: string().required(),
    // maximumEntries: number()
    //   .optional()
    //   .when("fulfillCondition", {
    //     is: "maximum_participant",
    //     then: (schema) => schema.required(),
    //   }),
    // limit_date: object()
    //   .optional()
    //   .nullable()
    //   .when("fulfillCondition", {
    //     is: "limit_date",
    //     then: (schema) => schema.required(),
    //   }),
  }),
  reward: YupRewardValidators,
});

interface LotteryFormProps extends BusinessFormComponentProps {
  lottery?: ProgramLottery;
}

export const LotteryForm = ({
  business,
  lottery,
  handleClose,
  mutate,
}: LotteryFormProps) => {
  const [loading, setLoading] = useState(false);

  const {
    unregister,
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
    control,
    resetField,
  } = useForm<ProgramLotteryForm>({
    resolver: yupResolver(schema as any),
    defaultValues: {
      ...lottery,
      type: ProgramType.lottery,
      conditions: {
        numberOfWinnersToDraw: lottery?.conditions.numberOfWinnersToDraw || 2,
        uniqueParticipant: lottery?.conditions.uniqueParticipant || false,
        // limit_date: lottery?.conditions.limit_date
        //   ? dayjs(lottery?.conditions.limit_date)
        //   : undefined,
        // fulfillCondition: lottery?.conditions.fulfillCondition || "limit_date",
      },
      created_at: lottery?.created_at || Timestamp.now(),
      updated_at: Timestamp.now(),
      archived_at: lottery?.archived_at || null,
    },
  });
  const fulfillCondition = watch("conditions.fulfillCondition");
  const numberOfWinnersToDraw = watch(
    "conditions.numberOfWinnersToDraw"
  ) as unknown as string;

  useEffect(() => {
    const totalWinner = Math.max(0, parseInt(numberOfWinnersToDraw || "0"));
    for (
      let i = 0;
      i <= (control._fields.reward as unknown as any[])?.length;
      i++
    ) {
      if (i < totalWinner) {
        continue;
      }
      unregister(`reward.${i}.label`);
    }
  }, [numberOfWinnersToDraw, control._fields.reward, unregister]);

  const createLottery = async (formData: ProgramLotteryForm) => {
    const lotteryValues: ProgramLottery = {
      ...formData,
      conditions: {
        ...formData.conditions,
        // limit_date: formData.conditions.limit_date?.toISOString(),
      } as any,
    };
    if (fulfillCondition === "limit_date") {
      delete lotteryValues.conditions?.maximumEntries;
    } else {
      delete lotteryValues.conditions?.limit_date;
    }
    console.log(lotteryValues);
    try {
      setLoading(true);
      if (lottery) {
        await updateBusinessLottery(business, lottery.id, lotteryValues);
        Logger.success("Lotterie mise à jour");
      } else {
        await createBusinessLottery(business.id, lotteryValues);
        Logger.success("Lotterie créé");
      }
      mutate();
      setLoading(false);
      handleClose();
    } catch (e) {
      setLoading(false);
      Logger.error(e);
    }
  };

  return (
    <ComponentErrorBoundary>
      <Box
        component="form"
        onSubmit={handleSubmit(createLottery)}
        noValidate
        sx={{ mt: 1 }}>
        <Typography variant="h5" sx={{ mb: 2, mt: 2 }}>
          Description
        </Typography>
        <Stack spacing={2}>
          <TextField
            {...register("title")}
            error={!!errors.title}
            id="lottery-label"
            fullWidth
            required
            label={"Titre"}></TextField>

          <TextField
            {...register("description")}
            multiline
            rows={3}
            error={!!errors.description}
            fullWidth
            placeholder="Profite de notre lotterie de juin pour fêter le printemps et tenter de gagner une bouteille de vin !"
            sx={{ minHeight: 45 }}
            required
            InputProps={{
              inputComponent: TextareaAutosize,
              inputProps: {
                maxRows: 6,
                minRows: 4,
              },
            }}
          />
        </Stack>
        <Typography variant="h5" sx={{ mb: 2, mt: 2 }}>
          Conditions
        </Typography>
        <Grid container rowSpacing={2}>
          {lottery && (
            <Grid item xs={12}>
              <Alert severity="info" sx={{ mb: 2 }}>
                Les conditions du programme ne sont pas éditable.
              </Alert>
            </Grid>
          )}
          {/* <Grid item xs={12}>
          <Controller
            control={control}
            name="conditions.fulfillCondition"
            render={(field) => (
              <ToggleButtonGroup
                {...field}
                color="primary"
                exclusive
                aria-label="Platform"
                onChange={(event, value) => field.field.onChange(value)}
                value={field.field.value}
                disabled={!!lottery}
                fullWidth>
                <ToggleButton value="maximum_participant">
                  Nombre de participants
                </ToggleButton>
                <ToggleButton value="limit_date">Date limite</ToggleButton>
              </ToggleButtonGroup>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          {lottery && (
            <Alert
              sx={{
                mt: 1,
              }}>
              {lottery.conditions.numberOfWinnersToDraw} Gagnants |{" "}
              {lottery.conditions.limit_date &&
                `Date de fin ${dayjs(lottery.conditions.limit_date).format(
                  "MM/DD/YYYY - HH:mm"
                )}`}
            </Alert>
          )}
          {!lottery && fulfillCondition === "maximum_participant" && (
            <Stack spacing={2} sx={{ width: 1 }}>
              <Alert severity="info">
                La loterie prend fin dés le nombre de participants atteint.
              </Alert>
              <TextField
                {...register("conditions.maximumEntries")}
                error={!!errors.conditions?.maximumEntries}
                type="number"
                id="demo-simple-select"
                fullWidth
                required
                label={"Nombre maximum de participants"}></TextField>
            </Stack>
          )}

          {!lottery && fulfillCondition === "limit_date" && (
            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid item xs={12}>
                <Alert severity="info">
                  La loterie terminera à la date donnée.
                </Alert>
              </Grid>
              <Grid item xs>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="fr">
                  <Controller
                    name="conditions.limit_date"
                    control={control}
                    render={({ field }) => (
                      <DateTimePicker
                        {...field}
                        sx={{ width: 1 }}
                        label="Date et heure de fin *"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!errors.conditions?.limit_date,
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          )}
        </Grid> */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel required id="demo-simple-select-label">
                Limite de participation
              </InputLabel>
              <Select
                {...register("conditions.uniqueParticipant")}
                error={!!errors.conditions?.uniqueParticipant}
                defaultValue={0}
                required
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Limite de participation">
                <MenuItem value={0}>Qualification à chaque scan</MenuItem>
                <MenuItem value={1}>
                  Qualification unique (le client ne peut participer qu'une
                  seule fois)
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register("conditions.numberOfWinnersToDraw")}
              error={!!errors.conditions?.numberOfWinnersToDraw}
              type="number"
              id="demo-simple-select"
              fullWidth
              required
              inputProps={{ min: 1 }}
              label={"Nombre de gagnant(s)"}></TextField>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ mb: 2, mt: 2 }}>
                  Récompense
                </Typography>
                {/* <Alert severity="info">
                Ici vous pouvez ajouter un code pour identifier un produit de
                votre inventaire pour simplifier la livraison des récompenses à
                la caisse.
              </Alert> */}
              </Grid>
              <Grid item xs={12}>
                {[
                  ...Array(
                    numberOfWinnersToDraw
                      ? Math.max(0, parseInt(numberOfWinnersToDraw || "0"))
                      : 0
                  ),
                ]
                  .fill(0)
                  .map((_, i) => (
                    <TextField
                      key={`reward.${i}`}
                      {...register(`reward.${i}.label`)}
                      margin="normal"
                      fullWidth
                      id="rewardLabel"
                      label={`Récompense pour le gagnant ${i + 1}`}
                      required
                      placeholder={"Récompense"}
                      error={!!errors.reward?.[i]?.label}
                    />
                  ))}
                {/* <TextField
                {...register("reward.customCode")}
                margin="normal"
                fullWidth
                id="customCode"
                label="Code personnalisé"
                placeholder="YYYYY"
                error={errors?.reward?.type === "atleastoneofCode"}
              />
              <TextField
                {...register("reward.barcode")}
                margin="normal"
                type="number"
                fullWidth
                id="barcoe"
                label="Code barre"
                placeholder="00000000000000000"
                error={errors?.reward?.type === "atleastoneofCode"}
              />
              <TextField
                {...register("reward.qrcode")}
                margin="normal"
                fullWidth
                id="qrcode"
                label="QRCode"
                placeholder="X1X1X1X1X1X1"
                error={errors?.reward?.type === "atleastoneofCode"}
              /> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <LoadingButton
          type="submit"
          loading={loading}
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}>
          Sauvegarder
        </LoadingButton>
      </Box>
    </ComponentErrorBoundary>
  );
};
