export enum FBCollections {
  Businesses = "businesses",
  BusinessUsers = "business_users",
  BusinessProducts = "business_products",
  Customers = "customers",
  CustomerSettings = "customers_settings",
  Scans = "scans",
  BusinessInvites = "business_invites",
  DistributedRewards = "distributed_rewards",
  AppConfig = "app_config",
}

export enum BusinessSubCollections {
  Loyalty = "programs_loyalty",
  Lottery = "programs_lottery",
  Events = "events",
  SubscribedCustomers = "subscribed_customers",
  UserPermissions = "users_permissions",
  Offers = "offers",
}

export enum BusinessUserSubScollections {
  InternalNotes = "internal_notes",
}

export enum CustomerSubCollections {
  SubscribedBusinesses = "subscribed_businesses",
  ScannedBusinesses = "scanned_businesses",
}

export enum FBCloudFunctions {
  NearestBusinesses = "getNearestBusinesses",
  InviteUserToBusiness = "inviteUserToBusiness",
  RegisterNewUser = "registerNewUser",
  RemoveBusinessInvite = "removeBusinessInvite",
  LotteryDraw = "lotteryDraw",
  GetPaymentMethods = "getPaymentMethods",
  BusinessScan = "businessScan",
  AdminApi = "adminApi",
  ContactForm = "contactForm",
  RemoveUser = "removeUser",
}
