import { Business, OpeningHour } from "@common/model/business";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
import { days } from "../../owner/forms/BusinessForm";

export const BusinessOpeningHours = ({
  business,
  isOpen,
}: {
  business: Business;
  isOpen?: OpeningHour;
}) => {
  const currentLocalHour = dayjs();
  const correctedDayIndex =
    currentLocalHour.day() === 0 ? 6 : currentLocalHour.day() - 1;

  return (
    <>
      {business?.opening_hours.map((value, index) => (
        <Typography
          component={"p"}
          fontWeight={
            isOpen?.day === index || correctedDayIndex === index ? "bold" : ""
          }
          key={index}>
          {days[index]} :{" "}
          {value.active
            ? `${value.open?.value} - ${value.close?.value}`
            : "Fermé"}
        </Typography>
      ))}
    </>
  );
};
