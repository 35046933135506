import { ImageSelector } from "@/common/components/ImageSelector";
import PicturePreview from "@/common/components/PicturePreview";
import { Logger } from "@/common/error-handling";
import { fileUpload, getImageUrl } from "@/common/firebase/common";
import { firebaseAppFirestoreDb } from "@/common/firebase/config";
import { converter } from "@/common/firebase/converter";
import { useBusinessDocumentForOwner } from "@/common/firebase/services/owner-hooks";
import { deleteImage } from "@/common/firebase/services/owner-requests";
import { useBusinessDocumentForPublic } from "@/common/firebase/services/user-hooks";
import { useUserStore } from "@/store/userStore";
import { FBCollections } from "@common/firebase";
import { Image } from "@common/index";
import { Business, BusinessFirestore } from "@common/model/business";
import { Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Fab,
  Grid,
} from "@mui/material";
import Box from "@mui/material/Box";
import { arrayUnion, doc, Timestamp, updateDoc } from "firebase/firestore";
import { useState } from "react";
import { useSWRConfig } from "swr";

//& google.maps.places.PlaceOpeningHoursPeriod
export default function BusinessMenuForm({ business }: { business: Business }) {
  const user = useUserStore();
  const [loading, setLoading] = useState(false);
  const [openPicturePreview, setOpenPicturePreview] = useState<string>();
  const [menuPictures, setMenuPictures] = useState<File[]>([]);
  const { mutate } = useSWRConfig();
  const { mutate: mutatePublic } = useBusinessDocumentForPublic(business.id);
  const { mutate: mutateOwner } = useBusinessDocumentForOwner(business.id, "");

  const updateMenu = async () => {
    if (!business) return;

    try {
      setLoading(true);

      if (menuPictures.length) {
        for (const picture of menuPictures) {
          await new Promise<void>((resolve) => {
            fileUpload(
              picture,
              FBCollections.Businesses,
              business.id,
              user.user!.uid,
              (progress) => {},
              (loading) => {},
              async (url) => {
                await updateDoc(
                  doc(
                    firebaseAppFirestoreDb,
                    FBCollections.Businesses,
                    business.id
                  ).withConverter(converter<BusinessFirestore>()),
                  {
                    menu_pictures: arrayUnion({
                      key: url.key,
                      created_at: Timestamp.now(),
                      created_by: user.user?.uid,
                    } as Image),
                  }
                );
                resolve();
              },
              (error) => {
                Logger.error(error);
                setLoading(false);
                resolve();
              },
              "menu"
            );
          });
        }
      }

      await mutateOwner();
      mutatePublic();
      setMenuPictures([]);
      setLoading(false);
      Logger.success("Établissement mis à jour");
    } catch (e) {
      Logger.error(e);
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardHeader title="Menu" sx={{ pt: 1, pb: 0 }}></CardHeader>
      <CardContent>
        <Grid container spacing={1}>
          {business?.menu_pictures?.map((pic) => (
            <Grid
              item
              key={pic.key}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              sx={{ position: "relative" }}>
              <Box
                onClick={() => setOpenPicturePreview(getImageUrl(pic.key))}
                sx={{
                  maxHeight: 250,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "aliceblue",
                  cursor: "pointer",
                  overflow: "hidden",
                }}>
                <img
                  srcSet={`${getImageUrl(pic.key)}`}
                  src={`${getImageUrl(pic.key)}`}
                  loading="lazy"
                />
              </Box>
              <Fab
                size="small"
                sx={{ position: "absolute", top: 15, right: 10 }}
                onClick={() => {
                  void deleteImage(business.id, pic, mutate);
                }}>
                <Delete />
              </Fab>
            </Grid>
          ))}
          {menuPictures.map((pic, i) => (
            <Grid
              item
              key={pic.name + i}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              sx={{ position: "relative" }}>
              <Box
                onClick={() => setOpenPicturePreview(URL.createObjectURL(pic))}
                sx={{
                  maxHeight: 250,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "aliceblue",
                  cursor: "pointer",
                  overflow: "hidden",
                }}>
                <img
                  srcSet={`${URL.createObjectURL(pic)}`}
                  src={`${URL.createObjectURL(pic)}`}
                  loading="lazy"
                />
              </Box>
              <Fab
                size="small"
                sx={{ position: "absolute", top: 15, right: 10 }}
                onClick={() =>
                  setMenuPictures(
                    menuPictures.filter((_, index) => i !== index)
                  )
                }>
                <Delete />
              </Fab>
            </Grid>
          ))}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <ImageSelector
              sx={{
                width: 1,
                height: 1,
                minHeight: 150,
                maxHeight: 250,
              }}
              callback={async (file: File) => {
                setMenuPictures([...menuPictures, file]);
              }}>
              <Button
                variant="text"
                sx={{
                  width: 1,
                  height: 1,
                  maxHeight: 250,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "aliceblue",
                  cursor: "pointer",
                }}>
                Ajouter une photo
              </Button>
            </ImageSelector>
          </Grid>
        </Grid>
        {openPicturePreview && !loading && (
          <PicturePreview
            pictureList={openPicturePreview ? [openPicturePreview] : []}
            activeIndex={0}
            setActiveIndex={() => setOpenPicturePreview(undefined)}
          />
        )}
      </CardContent>
      <CardActions sx={{ d: "flex", justifyContent: "end" }}>
        <LoadingButton
          variant="contained"
          onClick={updateMenu}
          loading={loading}
          disabled={!menuPictures.length}>
          Enregistrer
        </LoadingButton>
      </CardActions>
    </Card>
  );
}
