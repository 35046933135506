import { Address } from "@common/index";
import algoliasearch from "algoliasearch";

const client = algoliasearch(
  import.meta.env.VITE_ALGOLIA_APP_ID,
  import.meta.env.VITE_ALGOLIA_SEARCH_API_KEY
);
const algoliaBusinessIndex = client.initIndex("business");
const indexToDate = [6, 0, 1, 2, 3, 4, 5];

type Mutable<T> = {
  -readonly [key in keyof T]: T[key];
};

export type AlgoliaSearchResponseHit = Awaited<
  ReturnType<typeof algoliaSearch>
>["hits"][0];
export const algoliaSearch = async (
  name: string,
  params?: {
    latLon?: number[];
    latLonIp?: boolean;
    aroundRadius?: number;
    offer?: boolean;
    event?: boolean;
    reward?: boolean;
    date?: Date;
    time?: Date;
    hasLotteryOrLoyalty?: boolean;
  }
) => {
  const filters = [];
  if (params?.offer) {
    filters.push("(offer.start_hour > 0)");
  }

  if (params?.reward) {
    filters.push("(has_lottery:true OR has_loyalty:true)");
  }

  if (params?.hasLotteryOrLoyalty) {
    filters.push("(has_lottery:true OR has_loyalty:true)");
  }

  if (params?.date && !params?.time) {
    filters.push(
      `(opening_hours.${indexToDate[params.date.getDay()]}.active:true)`
    );
  }

  if (!params?.date && params?.time) {
    const today = new Date();
    filters.push(
      // ...indexToDate.map((dayIndex) => {
      `(opening_hours.${today.getDay()}.o.minutes <= ${
        params.time!.getHours() * 60 + params.time!.getMinutes()
      } OR opening_hours.${today.getDay()}.c.minutes >= ${
        params.time!.getHours() * 60 + params.time!.getMinutes()
      })`
      // })
    );
  }

  if (params?.date && params.time) {
    filters.push(
      `(opening_hours.${indexToDate[params.date.getDay()]}.o.minutes <= ${
        params.time.getHours() * 60 + params.time.getMinutes()
      } OR opening_hours.${indexToDate[params.date.getDay()]}.c.minutes >= ${
        params.time.getHours() * 60 + params.time.getMinutes()
      })`
    );
  }

  if (params?.event) {
    if (params?.date) {
      params.date.setHours(0, 0, 0, 0);
      filters.push(`(event_dates.timestamp > ${params.date.getTime()})`);
    } else {
      filters.push(`(event_dates.timestamp > 0)`);
    }
  }

  const options: Mutable<Parameters<typeof algoliaBusinessIndex.search>[1]> =
    {};

  if (filters.length) {
    options.filters = filters.join(" AND ");
  }

  if (params?.latLonIp) {
    options.aroundLatLngViaIP = true;
    options.aroundRadius = params.aroundRadius ?? 10000;
  } else if (params?.latLon) {
    options.aroundLatLng = `${params.latLon[0]}, ${params.latLon[1]}`;
    options.aroundRadius = params.aroundRadius ?? 10000;
  }

  return algoliaBusinessIndex.search<{
    name: string;
    address: Address;
    cover_picture: string;
    google_place_id: string;
    location: {
      lon: number;
      lat: number;
    };
  }>(name.trim(), options);
};
