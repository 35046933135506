import { CloseRounded } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";

export const AppModal = ({
  children,
  title,
  handleClose,
}: {
  children: JSX.Element;
  title: string;
  handleClose: () => void;
}) => {
  return (
    <Box sx={{ height: 1 }}>
      <AppBar position="static">
        <Toolbar className="top-notch">
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              flexGrow: 1,
            }}>
            {title}
          </Typography>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleClose}>
            <CloseRounded />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Container
        maxWidth={"md"}
        sx={{
          pb: 6,
          justifyContent: "center",
          height: 1,
          overflow: "auto",
        }}>
        {children}
      </Container>
    </Box>
  );
};
