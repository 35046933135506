import { useBusinessDocumentForOwner } from "@/common/firebase/services/owner-hooks";
import { deleteBusinessEvent } from "@/common/firebase/services/owner-requests";
import {
  BusinessPermissions,
  useBusinessPermission,
} from "@/common/hooks/useBusinessPermission";
import { Business, BusinessEvent } from "@common/model/business";
import { Delete, Edit, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Backdrop,
  Box,
  Button,
  Chip,
  Fade,
  Grid,
  ImageList,
  ImageListItem,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { AppModal } from "../../common/components/AppModal";
import { useUserStore } from "../../store/userStore";
import UpgradePremiumAd from "../PremiumAd";
import { EventForm } from "../forms/EventForm";

const style = {
  position: "absolute",
  top: "0",
  left: "0",
  transform: "translate(0, 0)",
  width: 1,
  height: 1,
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function BusinessEventPrograms() {
  const { id } = useParams();
  const { user } = useUserStore();

  if (!id || !user) return <></>;

  return <View id={id} userId={user.uid} />;
}

function View({ id, userId }: { id: string; userId: string }) {
  const { business, isLoading, mutate } = useBusinessDocumentForOwner(
    id,
    userId
  );
  const [permissions] = useBusinessPermission(business);

  const [openEvent, setOpenEvent] = useState(false);
  const [editingEvent, setEditingEvent] = useState<BusinessEvent | undefined>();
  const handleOpenEvent = (event?: BusinessEvent) => {
    handleClose();
    setEditingEvent(event);
    setOpenEvent(true);
  };
  const handleCloseEvent = () => {
    setOpenEvent(false);
    setEditingEvent(undefined);
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteEvent = async (event: BusinessEvent) => {
    if (!business) {
      return;
    }
    await deleteBusinessEvent(business.id, event.id);
    mutate();
  };

  if (!business) return <></>;

  return (
    <Grid container>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openEvent}
        onClose={handleCloseEvent}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}>
        <Fade in={openEvent}>
          <Box sx={style}>
            <AppModal
              title="Créer un programme de fidélité"
              handleClose={handleCloseEvent}>
              <EventForm
                business={business}
                handleClose={handleCloseEvent}
                currentEvent={editingEvent}
                mutate={mutate}
              />
            </AppModal>
          </Box>
        </Fade>
      </Modal>
      <Grid item xs={12} sm sx={{ mt: 2, mb: 1 }}>
        <Typography variant="h5">À venir</Typography>
      </Grid>
      <Grid item xs={12} sm={"auto"} sx={{ mt: 2, mb: 1 }}>
        <Button
          variant="contained"
          onClick={() => handleOpenEvent()}
          disabled={!permissions.canEditPrograms}>
          Créer un évènement
        </Button>
      </Grid>
      <Grid item xs={12}>
        {!permissions.canEditPrograms && (
          <Box display="flex" justifyContent="center" sx={{ p: 4 }}>
            <UpgradePremiumAd business={business} />
          </Box>
        )}
      </Grid>
      <Grid item xs={12} sx={{ mt: 1 }}>
        <Stack spacing={2}>
          {business?.events?.today && (
            <EventAccordion
              business={business}
              event={business.events.today}
              permissions={permissions}
              handleOpenEvent={handleOpenEvent}
              deleteEvent={deleteEvent}>
              {permissions.canEditPrograms ? (
                <>
                  <Button
                    onClick={() => handleOpenEvent(business.events!.today)}
                    variant="outlined"
                    endIcon={<Edit />}
                    disabled={!permissions.canEditPrograms}>
                    Éditer
                  </Button>
                  <Button
                    onClick={() => deleteEvent(business.events!.today!)}
                    variant="contained"
                    color="error"
                    endIcon={<Delete />}>
                    Delete
                  </Button>
                </>
              ) : undefined}
            </EventAccordion>
          )}
          {!!business?.events?.future.length &&
            business.events.future.map((event, i) => (
              <EventAccordion
                key={i}
                business={business}
                event={event}
                permissions={permissions}
                handleOpenEvent={handleOpenEvent}
                deleteEvent={deleteEvent}>
                {permissions.canEditPrograms ? (
                  <>
                    <Button
                      onClick={() => handleOpenEvent(event)}
                      variant="outlined"
                      endIcon={<Edit />}
                      disabled={!permissions.canEditPrograms}>
                      Éditer
                    </Button>
                    <Button
                      onClick={() => deleteEvent(event)}
                      variant="contained"
                      color="error"
                      endIcon={<Delete />}>
                      Delete
                    </Button>
                  </>
                ) : undefined}
              </EventAccordion>
            ))}
          {!business?.events?.today && !business?.events?.future.length && (
            <>
              <Alert severity="warning">Aucun évènement à venir.</Alert>
            </>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Typography variant="h5">Historique</Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: 1 }}>
        <Stack spacing={2}>
          {business?.events?.past.length ? (
            business.events.past.map((event, i) => (
              <EventAccordion
                key={i}
                event={event}
                business={business}
                permissions={permissions}
                handleOpenEvent={handleOpenEvent}
                deleteEvent={deleteEvent}
              />
            ))
          ) : (
            <>
              <Alert severity="warning">Aucun évènement archivé.</Alert>
            </>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}

export function EventAccordion({
  business,
  event,
  permissions,
  handleOpenEvent,
  deleteEvent,
  children,
}: {
  business: Business;
  event: BusinessEvent;
  permissions: BusinessPermissions;
  handleOpenEvent: (event?: BusinessEvent) => void;
  deleteEvent: (event: BusinessEvent) => void;
  children?: JSX.Element;
}) {
  const dayjsDate = dayjs(event.date_start.toDate()).utcOffset(
    business.utc_offset_minutes
  );

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Stack spacing={1} direction={"row"}>
          <Typography variant="h6">{event.title}</Typography>
          <Chip
            label={dayjsDate.format("MM/DD/YYYY - HH:mm")}
            color="success"
          />
          <Chip
            label={dayjsDate.fromNow()}
            color={dayjsDate.isAfter() ? "info" : "warning"}
          />
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{event.description}</Typography>
        {event.images && (
          <ImageList
            variant="masonry"
            sx={{ width: 1 }}
            cols={3}
            rowHeight={164}>
            {event.images.map((item, i) => (
              <ImageListItem key={item.key}>
                <img srcSet={`${item}`} src={`${item}`} loading="lazy" />
              </ImageListItem>
            ))}
          </ImageList>
        )}
      </AccordionDetails>
      {children && <AccordionActions>{children}</AccordionActions>}
    </Accordion>
  );
}
