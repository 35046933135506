import { Copyright } from "@mui/icons-material";
import { Container } from "@mui/material";
import { FC } from "react";
import { Outlet } from "react-router-dom";

export const OwnerBasicLayout: FC<any> = () => {
  return (
    <Container maxWidth="lg" sx={{ pt: 1, pb: 2 }}>
      <Outlet />
      <Copyright sx={{ pt: 4 }} />
    </Container>
  );
};
