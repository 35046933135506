import { create } from "zustand";

export interface AutocompleteSearchResult {
  lat?: number;
  lng?: number;
  google_place_id: string;
  name?: string;
  business_status?: string;
}
interface SearchBarState {
  inputText: string;
  result: AutocompleteSearchResult | null;
  setResult: (user: AutocompleteSearchResult | null) => void;
}

export const useSearchBarStore = create<SearchBarState>((set) => ({
  inputText: "",
  result: null,
  init: false,
  setResult: (result: AutocompleteSearchResult | null) => set({ result }),
}));
