import { AppModal } from "@/common/components/AppModal";
import { InviteForm } from "@/owner/forms/InviteForm";
import { BusinessForOwner } from "@common/index";
import { Backdrop, Box, Fade, Modal } from "@mui/material";
import { cloneElement, useState } from "react";

const style = {
  position: "absolute",
  top: "0",
  left: "0",
  transform: "translate(0, 0)",
  width: 1,
  height: 1,
  bgcolor: "background.paper",
  boxShadow: 24,
};

export const BusinessInviteModal = ({
  businesses,
  mutate,
  children,
}: {
  businesses: BusinessForOwner[];
  mutate: () => Promise<any>;
  children: JSX.Element;
}) => {
  const [openInviteModal, setOpenInviteModal] = useState(false);

  const handleInviteModal = () => {
    setOpenInviteModal(true);
  };
  const handleCloseInviteModal = () => {
    setOpenInviteModal(false);
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openInviteModal}
        onClose={handleCloseInviteModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}>
        <Fade in={openInviteModal}>
          <Box sx={style}>
            <AppModal
              title="Inviter un utilisateur"
              handleClose={handleCloseInviteModal}>
              <InviteForm
                businesses={businesses}
                mutate={mutate}
                handleClose={handleCloseInviteModal}
              />
            </AppModal>
          </Box>
        </Fade>
      </Modal>
      {cloneElement(children, { onClick: handleInviteModal })}
    </>
  );
};
