import { algoliaSearch } from "@/common/algolia";
import { Logger } from "@/common/error-handling";
import { getImageUrl } from "@/common/firebase/common";
import { useGoogleMapApi } from "@/common/google-map";
import { useMapStore } from "@/store/mapStore";
import NoImage from "@assets/no-image.jpg";
import { Capacitor } from "@capacitor/core";
import { Geolocation } from "@capacitor/geolocation";
import { Business } from "@common/model/business";
import {
  FilterList,
  FilterListOff,
  GpsFixed,
  GpsNotFixed,
  KeyboardArrowDown,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  CircularProgress,
  Drawer,
  Fab,
  Fade,
  Grid,
  IconButton,
  List,
  ListItem,
  Skeleton,
  Stack,
  SwipeableDrawer,
  Theme,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import dayjs from "dayjs";
import GoogleMap, { onGoogleApiLoadedProps } from "google-maps-react-markers";
import { MouseEventHandler, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSearchBarStore } from "../store/searchBarStore";
import HomeFilters, { MapFilters } from "./HomeFilters";
import { MapMarker } from "./common/MapMarker";

function getZoomFromMeters(meters: number) {
  return Math.log2(40000 / (meters / 2 / 1000));
}

function getMetersFromZoom(zoomLevel: number) {
  return Math.round((40000 / Math.pow(2, zoomLevel)) * 2 * 1000);
}

export function BusinessListItem({
  data,
  focused,
  onMouseEnter,
  onMouseLeave,
}: {
  data: Business;
  focused?: boolean;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
}) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (focused) {
      ref.current?.focus();
    }
  }, [focused]);

  return (
    <ListItem
      sx={{ p: 1 }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <Card
        ref={ref}
        sx={{
          display: "flex",
          width: 1,
          // backgroundColor: focused ? "#d6d6d6" : "white",
        }}
        elevation={focused ? 6 : 2}>
        <CardActionArea
          component={Link}
          to={`/business/${(data as any).firebase_key}`}
          sx={{ display: "flex", justifyContent: "start" }}>
          <Stack
            direction="row"
            alignItems="center"
            sx={{ overflow: "hidden", maxHeight: 95 }}>
            <Avatar
              src={
                (data.cover_picture && getImageUrl(data.cover_picture.key)) ||
                NoImage
              }
              variant="square"
              sx={{ width: 95, height: 95 }}>
              W
            </Avatar>
            <Stack direction="column" sx={{ p: 2, overflow: "hidden" }}>
              <Typography
                variant="subtitle2"
                noWrap
                textOverflow="ellipsis"
                overflow="hidden">
                {data.name}
              </Typography>
              {/* <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
              overflow={"hidden"}
              textOverflow={"ellipsis"}>
              {data.description}
            </Typography> */}
              <Typography variant="caption">
                {data.address?.street_address}{" "}
                {data.address?.street_address_addition
                  ? `, ${data.address?.street_address_addition}`
                  : ""}
                {data.address?.city}&nbsp;({data.address?.postal_code}),{" "}
                {data.address?.country}
              </Typography>
            </Stack>
          </Stack>
          {/* <CardMedia
            component={"img"}
            sx={{ width: 95, height: 120 }}
            image={data.cover_picture || NoImage}
          /> */}
        </CardActionArea>
      </Card>
    </ListItem>
  );
}

const drawerBleeding = 56;

const StyledStaticDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  ".MuiPaper-root": {
    height: `calc(50% - ${drawerBleeding}px - var(--safe-area-inset-bottom))`,
    overflow: "visible",
    zIndex: 999,
  },
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  ".MuiPaper-root": {
    overflow: "visible",
    zIndex: 1090,
  },
}));

function MapLoadingComponent() {
  const [fadeIn, setFadeIn] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setFadeIn(true);
    }, 1000);
  }, []);

  return (
    <Fade in={fadeIn} timeout={1000} appear={false}>
      <Box
        sx={{
          display: "flex",
          height: 1,
          alignItems: "center",
          justifyContent: "center",
          flexFlow: "column",
        }}>
        <Typography component="p" variant="caption" sx={{ pb: 2 }}>
          Carte en cours de chargement
        </Typography>
        <CircularProgress />
      </Box>
    </Fade>
  );
}

function Home() {
  const mapStore = useMapStore();
  const searchbarStore = useSearchBarStore();
  const { t } = useTranslation("translation");

  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [useGps, setUseGps] = useState(false);
  const [gpsLoading, setGpsLoading] = useState(false);
  const [results, setResults] = useState<any[]>([]);
  const [hoveredBusiness, setHoveredBusiness] = useState("");
  const [selectedBusiness, setSelectedBusiness] = useState("");
  const [loading, setLoading] = useState(true);
  const [coordinates, setGpsCoordinates] = useState<{
    lat: number;
    lng: number;
  }>();
  const [gpsCenter, setGpsCenter] = useState<{
    lat: number;
    lng: number;
  } | null>();
  const [positionError, setPositionError] = useState<any>();
  const mapRef = useRef<google.maps.Map>();
  const [mapReady, setMapReady] = useState(false);
  const [showSearchHere, setShowSearchHere] = useState(false);
  const zoom = useRef<number | undefined>();
  const [filters, setFilters] = useState<Partial<MapFilters>>();
  const { status: GoogleMapStatus } = useGoogleMapApi();

  const isLoading = useMemo(() => {
    return (!positionError && loading && !coordinates) || gpsLoading;
  }, [positionError, loading, coordinates, gpsLoading]);

  const dateTimeFilterChip = useMemo(() => {
    const hasDateFilter = filters?.when?.includes("date") && filters?.date;
    const hasTimeFilter = filters?.when?.includes("time") && filters?.time;

    if (hasTimeFilter && hasDateFilter) {
      return `${dayjs(filters.date).format("ddd DD MMM")} ${dayjs(
        filters.time
      ).format("HH:mm")}`;
    } else if (hasTimeFilter && !hasDateFilter) {
      return `${dayjs(filters.time).format("HH:mm")}`;
    } else if (!hasTimeFilter && hasDateFilter) {
      return `${dayjs(filters.date).format("ddd DD MMM")}`;
    } else {
      return null;
    }
  }, [filters]);

  const refreshFilters = (formValues: Partial<MapFilters>) => {
    const storeLocation = useMapStore.getState().location;
    mapStore.setFilters(formValues as MapFilters);
    const when = formValues.when?.includes("date")
      ? formValues?.date
      : formValues.when?.includes("now")
      ? new Date()
      : null;

    setFilters(
      formValues.when?.length || formValues.programs?.length
        ? formValues
        : undefined
    );

    if (!storeLocation) {
      return;
    }

    algoliaSearch(searchbarStore.inputText, {
      latLon: [storeLocation.lat, storeLocation.lng],
      offer: formValues.programs?.includes("offer"),
      event: formValues.programs?.includes("event"),
      reward: formValues.programs?.includes("reward"),
      date: when ?? undefined,
      time: formValues.when?.includes("time")
        ? formValues?.time || undefined
        : undefined,
    })
      .then((r) => {
        mapStore.setResults(r.hits);
        setResults(r.hits);
      })
      .catch((e) => {
        Logger.error(e);
        setLoading(false);
      });
  };

  const getCurrentPosition = async (showError = true) => {
    setGpsLoading(true);
    setUseGps(true);
    try {
      setPositionError(null);
      if (Capacitor.isNativePlatform()) {
        await Geolocation.requestPermissions();
      }
      const position = await Geolocation.getCurrentPosition();

      setCoordinates({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
      setGpsCenter({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
      refreshGps({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    } catch (e) {
      setPositionError(e);
      if (showError) {
        Logger.error("Erreur de géolocalisation", e);
      }
    }
    setTimeout(() => {
      setGpsLoading(false);
    }, 500);
  };

  const setCoordinates = (coords: { lat: number; lng: number }): void => {
    setGpsCoordinates(coords);
    mapRef.current?.setCenter(coords);
    mapStore.setLocation({
      lat: coords.lat,
      lng: coords.lng,
    });
    setPositionError(null);
  };
  /**
   * @description This function is called when the map is ready
   * @param {Object} map - reference to the map instance
   * @param {Object} maps - reference to the maps library
   */
  const onGoogleApiLoaded = ({ map, maps }: onGoogleApiLoadedProps) => {
    mapRef.current = map;
    setMapReady(true);
  };

  const searchHere = () => {
    setShowSearchHere(false);
    const center = mapRef.current?.getCenter();

    if (center) {
      refreshGps({ lat: center.lat(), lng: center.lng() });
    }
  };

  useEffect(() => {
    if (mapStore.filters) {
      setFilters(mapStore.filters);
    }
    if (mapStore.results && mapStore.location) {
      setCoordinates({
        lat: mapStore.location.lat,
        lng: mapStore.location.lng,
      });
      setResults(mapStore.results);
      setLoading(false);
      setUseGps(!searchbarStore.result);
    } else {
      algoliaSearch("", { latLonIp: true }).then((r) => {
        setCoordinates({
          lat: parseFloat(r.aroundLatLng?.split(",")[0] ?? "0"),
          lng: parseFloat(r.aroundLatLng?.split(",")[1] ?? "0"),
        });
        mapStore.setResults(r.hits);
        setResults(r.hits);
      });
      getCurrentPosition(false);
    }
  }, []);

  useEffect(() => {
    if (!coordinates || !mapRef.current) {
      return;
    }

    mapRef.current.setZoom(getZoomFromMeters(10000));

    const dragend = mapRef.current.addListener("dragend", () => {
      const center = mapRef.current?.getCenter();
      const factor =
        (45 / Math.pow(2, mapRef.current?.getZoom() as number)) * 10;

      if (
        center &&
        (Math.abs(coordinates.lat - center.lat()) > factor ||
          Math.abs(coordinates.lng - center.lng()) > factor)
      ) {
        setShowSearchHere(true);
      }
    });
    const zoomChanged = mapRef.current.addListener("zoom_changed", () => {
      if (!mapRef.current) {
        return;
      }
      if (zoom.current && zoom.current > (mapRef.current.getZoom() as number)) {
        setShowSearchHere(true);
      }
      zoom.current = mapRef.current.getZoom();
    });

    return () => {
      dragend.remove();
      zoomChanged.remove();
    };
  }, [mapReady]);

  useEffect(() => {
    if (coordinates && coordinates.lat !== mapStore.location?.lat) {
      if (!coordinates || searchbarStore.result) return;
      refreshGps();
    }
  }, [coordinates]);

  useEffect(() => {
    if (
      !searchbarStore.result ||
      !searchbarStore.result.lat ||
      !searchbarStore.result.lng
    )
      return;
    if (searchbarStore.result.lat === mapStore.location?.lat) {
      return;
    }

    setUseGps(false);
    setLoading(true);

    mapStore.setLocation({
      lat: searchbarStore.result.lat,
      lng: searchbarStore.result.lng,
    });
    setCoordinates({
      lat: searchbarStore.result.lat,
      lng: searchbarStore.result.lng,
    });

    algoliaSearch(searchbarStore.inputText, {
      latLon: [searchbarStore.result.lat, searchbarStore.result.lng],
    })
      .then((r) => {
        mapStore.setResults(r.hits);
        setResults(r.hits);
        setLoading(false);
        setTimeout(() => {
          if (
            !results.find(
              (r) =>
                r.google_place_id === searchbarStore.result?.google_place_id
            )
          ) {
            setSelectedBusiness(
              searchbarStore.result?.google_place_id as string
            );
          } else {
            setSelectedBusiness(
              results.find(
                (r) =>
                  r.google_place_id === searchbarStore.result?.google_place_id
              ).firebase_key
            );
          }
        }, 250);
      })
      .catch((e) => {
        Logger.error(e);
        setLoading(false);
      });
  }, [searchbarStore.result]);

  useEffect(() => {
    if (!useGps) {
      setGpsCenter(null);
    }
  }, [useGps]);

  const getGps = () => {
    searchbarStore.setResult(null);
    getCurrentPosition();
  };

  const refreshGps = (coords = coordinates) => {
    if (!coords) return;
    mapStore.setLocation({ lat: coords.lat, lng: coords.lng });
    setLoading(true);
    setCoordinates({ lat: coords.lat, lng: coords.lng });

    algoliaSearch(searchbarStore.inputText, {
      latLon: [coords.lat, coords.lng],
      aroundRadius: mapRef.current?.getZoom()
        ? getMetersFromZoom(mapRef.current.getZoom() as number)
        : undefined,
    })
      .then((r) => {
        mapStore.setResults(r.hits);
        setResults(r.hits);
        setLoading(false);
      })
      .catch((e) => {
        Logger.error(e);
        setLoading(false);
      });
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const toggleFilterDrawer = (newOpen: boolean) => () => {
    setOpenFilter(newOpen);
  };

  const isSmMedia = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const params = {
    center: { lat: 59.95, lng: 30.33 },
    zoom: 9,
  };
  const container =
    window !== undefined
      ? () => document.getElementsByTagName("main")[0]
      : undefined;

  return (
    <>
      <Grid container sx={{ height: "100%" }}>
        <Grid
          item
          xs={12}
          md={8}
          sx={{ position: "relative", zIndex: 0 }}
          onClick={() => setSelectedBusiness("")}>
          {isSmMedia && (
            <Fab
              size="medium"
              color={filters ? "warning" : "default"}
              sx={{ position: "absolute", bottom: "75px", left: "15px" }}
              onClick={toggleFilterDrawer(true)}>
              {filters ? <FilterList /> : <FilterListOff />}
            </Fab>
          )}
          <StyledDrawer
            anchor={"bottom"}
            open={openFilter}
            sx={{ height: "auto", zIndex: 1200 }}
            onClose={toggleFilterDrawer(false)}>
            <Box
              sx={{
                position: "absolute",
                top: -34,
                right: 54,
                background: "white",
                borderRadius: [1, 1, 0, 0],
              }}>
              <IconButton
                color="default"
                aria-label="open drawer"
                onClick={toggleFilterDrawer(false)}>
                <KeyboardArrowDown />
              </IconButton>
            </Box>
            <HomeFilters
              currentFilters={filters}
              onFilterChanged={refreshFilters}
            />
          </StyledDrawer>
          <Fab
            onClick={getGps}
            disabled={gpsLoading}
            size="medium"
            color={coordinates && useGps ? "success" : "default"}
            sx={{ position: "absolute", bottom: "75px", right: "15px" }}>
            {gpsLoading ? (
              <CircularProgress />
            ) : coordinates && useGps ? (
              <GpsFixed />
            ) : (
              <GpsNotFixed />
            )}
          </Fab>
          {positionError && !coordinates && (
            <Box
              sx={{
                height: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <Typography variant="caption" color="text.secondary">
                {useGps /*&& positionError && positionError.PERMISSION_DENIED*/
                  ? "La localisation a été refusé."
                  : /*!isGeolocationAvailable &&*/
                    "La localisation n'est pas disponible."}
              </Typography>
            </Box>
          )}

          {showSearchHere && (
            <Box
              sx={{
                position: "absolute",
                width: "100%",
                top: "75px",
                textAlign: "center",
                zIndex: 9,
              }}>
              <Button onClick={searchHere} variant="contained">
                Rechercher ici
              </Button>
            </Box>
          )}

          {coordinates && (
            <GoogleMap
              apiKey={""}
              loadScriptExternally
              status={GoogleMapStatus}
              onGoogleApiLoaded={onGoogleApiLoaded}
              defaultZoom={params.zoom}
              defaultCenter={coordinates}
              idleContent={<MapLoadingComponent />}
              loadingContent={<MapLoadingComponent />}
              options={{
                zoomControl: false,
                fullscreenControl: false,
                disableDefaultUI: true,
                clickableIcons: false,
              }}
              events={[
                {
                  handler(e) {
                    console.log(e);
                  },
                  name: "onClick",
                },
              ]}>
              {gpsCenter && (
                <MapMarker
                  key={gpsCenter.lat + gpsCenter.lng}
                  lat={gpsCenter.lat}
                  lng={gpsCenter.lng}
                  mode="dot"></MapMarker>
              )}
              {!loading &&
                searchbarStore.result &&
                searchbarStore.result.business_status &&
                !results.find(
                  (r) =>
                    r.google_place_id === searchbarStore.result?.google_place_id
                ) && (
                  <MapMarker
                    key={searchbarStore.result.google_place_id}
                    lat={coordinates.lat}
                    lng={coordinates.lng}
                    name={searchbarStore.result.name}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedBusiness(
                        searchbarStore.result?.google_place_id as string
                      );
                    }}
                    hover={
                      hoveredBusiness ===
                        searchbarStore.result.google_place_id ||
                      selectedBusiness === searchbarStore.result.google_place_id
                    }
                  />
                )}
              {results.length
                ? results.map((data, index) => (
                    <MapMarker
                      onMouseEnter={() => setHoveredBusiness(data.firebase_key)}
                      onMouseLeave={() => setHoveredBusiness("")}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedBusiness(data.firebase_key);
                      }}
                      key={data.firebase_key}
                      lat={data.location.lat}
                      lng={data.location.lon}
                      hover={
                        hoveredBusiness === data.firebase_key ||
                        selectedBusiness === data.firebase_key ||
                        selectedBusiness === data.google_place_id
                      }
                      business={data}
                    />
                  ))
                : ""}
            </GoogleMap>
          )}
        </Grid>
        <Grid item md={4} sx={{ maxHeight: 1, overflow: "auto" }}>
          {isSmMedia ? (
            <StyledStaticDrawer
              anchor="bottom"
              open={open}
              container={container}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
              swipeAreaWidth={drawerBleeding * 2}
              disableSwipeToOpen={false}
              onClick={toggleDrawer(!open)}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}>
              <Box
                className={open ? "drawerBleedingOpen" : "drawerBleedingClose"}
                sx={{
                  position: "absolute",
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8,
                  visibility: "visible",
                  right: 0,
                  left: 0,
                  backgroundColor: "#fff",
                  pointerEvents: "all",
                }}>
                <Puller />
                <Box display="flex" alignItems="center">
                  <Typography
                    sx={{
                      p: 2,
                      color: "text.secondary",
                      width: 1,
                      fontWeight: "bold",
                    }}>
                    {isLoading ? (
                      <Skeleton variant="text" sx={{ width: "25%" }} />
                    ) : (
                      `${results?.length} résultat(s)`
                    )}
                  </Typography>
                  {dateTimeFilterChip ? (
                    <Chip
                      sx={{ mx: 0.5 }}
                      label={dateTimeFilterChip}
                      onClick={(e) => {
                        setOpenFilter(true);
                        e.stopPropagation();
                      }}
                    />
                  ) : (
                    ""
                  )}

                  {filters?.programs?.length ? (
                    <Chip
                      sx={{ mx: 0.5 }}
                      label={filters?.programs
                        ?.map((p: string) => t("filters." + p))
                        .join(", ")}
                      color="info"
                      variant="outlined"
                      onClick={(e) => {
                        setOpenFilter(true);
                        e.stopPropagation();
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  px: 2,
                  pb: 2,
                  height: "100%",
                  overflow: "auto",
                }}>
                <List>
                  {results?.map((data, index) => (
                    <BusinessListItem key={index} data={data} />
                  ))}
                  {loading &&
                    [0, 1, 3, 4].map((i) => (
                      <ListItem key={i} sx={{ p: 1 }}>
                        <Card
                          sx={{
                            display: "flex",
                            width: 1,
                            // backgroundColor: focused ? "#d6d6d6" : "white",
                          }}>
                          <Skeleton
                            sx={{ width: 95, height: 120 }}
                            component="img"
                            variant="rectangular"
                          />
                          <CardContent
                            sx={{
                              flex: "1 0",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}>
                            <Skeleton sx={{ width: "65%" }} variant="text" />
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                          </CardContent>
                        </Card>
                      </ListItem>
                    ))}
                </List>
              </Box>
            </StyledStaticDrawer>
          ) : (
            <>
              <HomeFilters
                currentFilters={filters}
                onFilterChanged={refreshFilters}
              />
              <List>
                <Typography
                  variant="subtitle2"
                  sx={{
                    backgroundColor: "transparent",
                    px: 1,
                    textAlign: "center",
                  }}>
                  {isLoading ? (
                    <Skeleton variant="text" sx={{ width: "25%" }} />
                  ) : (
                    `${results?.length} résultat(s)`
                  )}
                </Typography>
                {results?.map((data, index) => (
                  <BusinessListItem
                    onMouseEnter={() => setHoveredBusiness(data.firebase_key)}
                    onMouseLeave={() => setHoveredBusiness("")}
                    key={index}
                    data={data}
                    focused={data.firebase_key === hoveredBusiness}
                  />
                ))}
                {isLoading &&
                  [0, 1, 3, 4].map((i) => (
                    <ListItem key={i} sx={{ p: 1 }}>
                      <Card
                        sx={{
                          display: "flex",
                          width: 1,
                          // backgroundColor: focused ? "#d6d6d6" : "white",
                        }}>
                        <Skeleton
                          sx={{ width: 95, height: 120 }}
                          component="img"
                          variant="rectangular"
                        />
                        <CardContent
                          sx={{
                            flex: "1 0",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}>
                          <Skeleton sx={{ width: "65%" }} variant="text" />
                          <Skeleton variant="text" />
                          <Skeleton variant="text" />
                        </CardContent>
                      </Card>
                    </ListItem>
                  ))}
              </List>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default Home;

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));
