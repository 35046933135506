import { BusinessProgram, CustomerFirestore2, Reward } from "@common/index";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export function useClaimRewardDialog(closeCallback?: () => void) {
  const [dialog, setDialog] = useState<ReturnType<typeof ClaimRewardDialog>>();

  function onClose() {
    setDialog(undefined);
    if (closeCallback) {
      closeCallback();
    }
  }

  function setClaimResult(
    program: BusinessProgram,
    reward: Reward,
    customer: CustomerFirestore2
  ) {
    setDialog(
      <ClaimRewardDialog
        program={program}
        reward={reward}
        customer={customer}
        onCloseRewardDialog={onClose}
      />
    );
  }

  return { setClaimResult, claimDialog: dialog };
}

export default function ClaimRewardDialog({
  program,
  reward,
  customer,
  onCloseRewardDialog,
}: {
  program: BusinessProgram;
  reward: Reward;
  customer: CustomerFirestore2;
  onCloseRewardDialog: () => void;
}) {
  const [open, setOpen] = useState(true);
  const { t } = useTranslation("translation");

  return (
    <Dialog maxWidth="xs" open={open}>
      <DialogTitle>Récompense obtenue !</DialogTitle>
      <DialogContent dividers>
        <Typography variant="subtitle1">
          L'utilisateur <b>{customer.username}</b> a remporté la récompense du
          programme <b>{t("programs." + program.type)}</b> !
        </Typography>
        <Typography variant="h6" sx={{ py: 3, textAlign: "center" }}>
          {reward.label}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            onCloseRewardDialog();
          }}>
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
