import { Logger } from "@/common/error-handling";
import { inviteUserToBusiness } from "@/common/firebase/services/owner-requests";
import {
  BusinessForOwner,
  BusinessInviteForm,
  UserRole,
} from "@common/model/business";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Timestamp } from "firebase/firestore";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { KeyedMutator } from "swr";
import { array, mixed, object, string } from "yup";

const schema = object<BusinessInviteForm>().shape({
  emails: array(string().email()).min(1).required(),
  business_ids: array().min(1).required(),
  role: mixed().oneOf(Object.values(UserRole)).required(),
  created_at: object().required(),
});

export interface InviteFormComponentProps {
  businesses: BusinessForOwner[];
  handleClose: () => void;
  mutate: KeyedMutator<any>;
}

export const InviteForm = ({
  businesses,
  handleClose,
  mutate,
}: InviteFormComponentProps) => {
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    control,
    getValues,
    setValue,
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      emails: [],
      business_ids: businesses.length > 0 ? [] : businesses,
      created_at: Timestamp.now(),
    },
  });

  const createInvite = async (formData: BusinessInviteForm) => {
    try {
      setLoading(true);
      await inviteUserToBusiness(
        formData.emails,
        formData.role,
        formData.business_ids.map((bu) => bu.id)
      );
      mutate();
      setLoading(false);
      handleClose();
      Logger.success("Utilisateur invité");
    } catch (e) {
      setLoading(false);
      Logger.error(e);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(createInvite)}
      noValidate
      sx={{ mt: 1 }}>
      <Typography variant="h5" sx={{ mb: 2, mt: 2 }}>
        Informations
      </Typography>
      <Stack spacing={1}>
        <Controller
          render={({ field: { onChange, ...props } }) => (
            <Autocomplete
              freeSolo
              multiple
              autoSelect
              filterOptions={(options: string[], params) => {
                const filtered = [];

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push(inputValue);
                }

                return filtered;
              }}
              id="free-solo-2-demo"
              disableClearable
              options={[]}
              inputValue={inputValue}
              onInputChange={(event, value) => {
                setInputValue(value);
                if ([" ", ",", ";"].includes(value.charAt(value.length - 1))) {
                  onChange([
                    ...control._formValues.emails,
                    value.slice(0, value.length - 1),
                  ]);
                  setInputValue("");
                }
              }}
              onChange={(event, newValue) => {
                onChange(newValue);
              }}
              PaperComponent={(props) => {
                return <Paper elevation={8} {...props} />;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Adresses email"
                  error={!!errors.emails}
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
              {...props}
            />
          )}
          name={"emails"}
          control={control}
        />
        {/* <Autocomplete
          {...register("emails")}
          freeSolo
          multiple
          autoSelect
          filterOptions={(options: string[], params) => {
            const filtered = [];

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option);
            if (inputValue !== "" && !isExisting) {
              filtered.push(inputValue);
            }

            return filtered;
          }}
          id="free-solo-2-demo"
          disableClearable
          options={[]}
          onChange={(event, newValue) => {
            setValue("emails", newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Adresses email"
              error={!!errors.emails}
              InputProps={{
                ...params.InputProps,
                type: "search",
              }}
            />
          )}
        /> */}
        {businesses.length > 0 && (
          <Autocomplete
            {...register("business_ids")}
            multiple
            autoSelect
            options={businesses}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, newValue) => {
              setValue("business_ids", newValue);
            }}
            PaperComponent={(props) => {
              return <Paper elevation={8} {...props} />;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Établissements"
                error={!!errors.emails}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
        )}
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Role</InputLabel>
          <Select
            {...register("role")}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            error={!!errors.role}
            label="Role">
            <MenuItem value={UserRole.Admin}>{UserRole.Admin}</MenuItem>
            <MenuItem value={UserRole.Viewer}>{UserRole.Viewer}</MenuItem>
            <MenuItem value={UserRole.Scanner}>{UserRole.Scanner}</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <LoadingButton
        type="submit"
        loading={loading}
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}>
        Inviter
      </LoadingButton>
    </Box>
  );
};
