import { GoogleSigninIcon } from "@/assets/GoogleSignin";
import { Logger } from "@/common/error-handling";
import { createCustomerProfile } from "@/common/firebase/services/user-requests";
import { useRedirectUrl } from "@/common/hooks/useRedirectUrl";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { Capacitor } from "@capacitor/core";
import {
  Apple,
  ArrowBack,
  ArrowForward,
  CheckCircle,
} from "@mui/icons-material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Card,
  CardContent,
  Fade,
  IconButton,
  Stack,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {
  AuthError,
  AuthErrorCodes,
  getAdditionalUserInfo,
  GoogleAuthProvider,
  linkWithCredential,
  OAuthProvider,
  sendPasswordResetEmail,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  UserCredential,
} from "firebase/auth";
import { FormEvent, useMemo, useState } from "react";
import {
  createSearchParams,
  Link as RouterLink,
  To,
  useNavigate,
} from "react-router-dom";
import { firebaseAppAuth } from "../../common/firebase/config";
import { useUserStore } from "../../store/userStore";

export default function SignIn() {
  const user = useUserStore();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [isReset, setReset] = useState(false);
  const [successReset, setSuccessReset] = useState(false);
  const [busy, setBusy] = useState(false);
  const { getCurrentRedirectUrl, redirectFromCache } = useRedirectUrl();

  const canShowAppleSignin = useMemo(
    () => !Capacitor.isNativePlatform() || Capacitor.getPlatform() === "ios",
    []
  );

  const registerUrl = useMemo((): To => {
    const redirectUrl = getCurrentRedirectUrl();
    if (redirectUrl) {
      return {
        pathname: "/auth/register/customer",
        search: `?${createSearchParams({ redirectUrl })}`,
      };
    } else {
      return {
        pathname: "/auth/register/customer",
      };
    }
  }, []);

  const registerUrlBusinessUser = useMemo((): To => {
    const redirectUrl = getCurrentRedirectUrl();
    if (redirectUrl) {
      return {
        pathname: "/auth/register/business",
        search: `?${createSearchParams({ redirectUrl })}`,
      };
    } else {
      return {
        pathname: "/auth/register/business",
      };
    }
  }, []);

  const signInWithGoogle = async () => {
    const googleProvider = new GoogleAuthProvider();

    setBusy(true);
    try {
      if (Capacitor.isNativePlatform()) {
        const user = await FirebaseAuthentication.signInWithGoogle();

        const credential = GoogleAuthProvider.credential(
          user.credential?.idToken
        );
        // Sign in with credential from the Google user.
        await signInWithCredential(firebaseAppAuth, credential)
          .then(async (result) => {
            if (
              !result.user.providerData.some(
                (provider) =>
                  provider.providerId === GoogleAuthProvider.PROVIDER_ID
              )
            ) {
              await linkWithCredential(result.user, credential);
            }
            return result;
          })
          .then(handleSuccessSignin)
          .catch((error) => {
            console.log(error);
            Logger.error(error);
          });
      } else {
        try {
          await signInWithPopup(firebaseAppAuth, googleProvider).then(
            handleSuccessSignin
          );
        } catch (error) {
          Logger.error("Error signing in with Google:", error);
        }
      }
    } catch (e) {
      Logger.error(e);
    } finally {
      setBusy(false);
    }
  };

  const signInWithApple = async () => {
    const appleProvider = new OAuthProvider("apple.com");

    setBusy(true);
    try {
      if (Capacitor.isNativePlatform()) {
        const result = await FirebaseAuthentication.signInWithApple({
          skipNativeAuth: true,
        });

        const credential = appleProvider.credential({
          idToken: result.credential?.idToken,
          rawNonce: result.credential?.nonce,
        });

        // Sign in with credential from the Google user.
        await signInWithCredential(firebaseAppAuth, credential)
          .then(async (result) => {
            if (
              !result.user.providerData.some(
                (provider) => provider.providerId === appleProvider.providerId
              )
            ) {
              await linkWithCredential(result.user, credential);
            }
            return result;
          })
          .then(handleSuccessSignin)
          .catch((error) => {
            Logger.error(error);
          });
      } else {
        try {
          await signInWithPopup(firebaseAppAuth, appleProvider).then(
            handleSuccessSignin
          );
        } catch (error) {
          Logger.error("Error signing in with Google:", error);
        }
      }
    } catch (e) {
      Logger.error(e);
    } finally {
      setBusy(false);
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(null);

    if (isReset) {
      resetPassword(event);
      return;
    }

    const data = new FormData(event.currentTarget);
    const userData = {
      email: data.get("email"),
      password: data.get("password"),
    };
    if (userData.email && userData.password) {
      setBusy(true);
      signInWithEmailAndPassword(
        firebaseAppAuth,
        userData.email.toString(),
        userData.password.toString()
      )
        .then(handleSuccessSignin)
        .catch((e: AuthError) => {
          setBusy(false);
          if (Object.values(AuthErrorCodes).includes(e.code as any)) {
            if (
              [
                AuthErrorCodes.INVALID_AUTH,
                AuthErrorCodes.INVALID_PASSWORD,
                AuthErrorCodes.INVALID_EMAIL,
              ].includes(e.code as any)
            ) {
              setError("Identifiants invalides");
              return;
            }
            setError("Identifiants invalide ou le compte n'existe pas");
            return;
          }
          setError("Une erreur s'est produite.");
        });
    }
  };

  const resetPassword = (event: FormEvent<HTMLFormElement>) => {
    const data = new FormData(event.currentTarget);
    setBusy(true);
    sendPasswordResetEmail(firebaseAppAuth, data.get("email") as string)
      .then(() => {
        setSuccessReset(true);
        setReset(false);
        setBusy(false);
        setTimeout(() => {
          setSuccessReset(false);
        }, 5000);
      })
      .catch((error) => {
        setBusy(false);
        setError("Une erreur s'est produite.");
      });
  };

  async function handleSuccessSignin(result: UserCredential) {
    setBusy(false);
    const additionalUserInfo = await getAdditionalUserInfo(result);

    if (additionalUserInfo?.isNewUser) {
      const [firstname, lastname] = (result.user.displayName || " ").split(" ");
      await createCustomerProfile(result.user, firstname, lastname);
    }

    if (!redirectFromCache()) {
      const idToken = await result.user.getIdTokenResult();
      if (idToken.claims.isBusiness) {
        navigate("/owner/business/list");
      } else {
        navigate("/");
      }
    }
    return result;
  }

  if (!user.init) {
    return;
  }

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        height: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <CssBaseline />
      <Fade in={successReset} timeout={{ enter: 650, exit: 0 }} unmountOnExit>
        <Grid container sx={{ mt: 6 }}>
          <Grid item textAlign="center">
            <CheckCircle fontSize="large" color="success" />
            <p>
              Un email contenant les instructions de réinitialisation vous a été
              envoyé !
            </p>
            <p>Vous allez être redirigé dans 5 secondes.</p>
          </Grid>
        </Grid>
      </Fade>
      <Fade in={!successReset} timeout={{ enter: 0, exit: 650 }}>
        <Card variant="rounded">
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
            }}>
            {!successReset && (
              <Box sx={{ position: "absolute", left: 15, top: 15 }}>
                <RouterLink to="/">
                  <IconButton>
                    <ArrowBack />
                  </IconButton>
                </RouterLink>
              </Box>
            )}

            <Avatar
              src={user.user?.photoURL || ""}
              sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {isReset ? "Reinitialisation" : "Authentification"}
            </Typography>
            {error && (
              <Alert sx={{ mt: 2 }} severity="error">
                {error}
              </Alert>
            )}
            {!user.user && !isReset ? (
              <Stack spacing={2} sx={{ mt: 2 }}>
                <Button
                  variant="outlined"
                  onClick={signInWithGoogle}
                  startIcon={<GoogleSigninIcon />}>
                  Sign In with Google
                </Button>
                {canShowAppleSignin && (
                  <Button
                    variant="outlined"
                    onClick={signInWithApple}
                    startIcon={<Apple htmlColor="black" />}>
                    Sign In with Apple
                  </Button>
                )}
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1, width: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    error={!!error}
                    sx={{ mb: 0 }}
                  />
                  <Fade
                    in={!isReset}
                    timeout={{ enter: 650, exit: 0 }}
                    unmountOnExit>
                    <Box>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        error={!!error}
                        sx={{ mb: 0 }}
                      />
                      <LoadingButton
                        type="submit"
                        fullWidth
                        loading={busy}
                        variant="contained"
                        sx={{ mt: 2, mb: 2 }}>
                        Connexion
                      </LoadingButton>
                      <Box sx={{ textAlign: "right" }}>
                        <Link
                          onClick={() => {
                            setReset(true);
                            setError(null);
                          }}
                          href={"#"}
                          variant="body2">
                          Mot de passe oublié ?
                        </Link>
                      </Box>
                      <Link
                        component={RouterLink}
                        to={registerUrl}
                        variant="body2">
                        <Button
                          fullWidth
                          variant="outlined"
                          sx={{ mt: 3 }}
                          endIcon={<ArrowForward />}>
                          Créer un compte
                        </Button>
                      </Link>
                      <RouterLink to={registerUrlBusinessUser}>
                        <Button
                          fullWidth
                          variant="outlined"
                          sx={{ mt: 3, mb: 2 }}
                          endIcon={<ArrowForward />}>
                          Créer un compte (business)
                        </Button>
                      </RouterLink>
                    </Box>
                  </Fade>
                  <Fade
                    in={isReset}
                    timeout={{ enter: 650, exit: 0 }}
                    unmountOnExit>
                    <Box>
                      <LoadingButton
                        type="submit"
                        loading={busy}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}>
                        Envoyer un nouveau mot de passe
                      </LoadingButton>
                      <Grid container>
                        <Grid item xs>
                          <Link
                            onClick={() => {
                              setReset(false);
                              setError(null);
                            }}
                            variant="body2">
                            Retour au formulaire de connexion
                          </Link>
                        </Grid>
                      </Grid>
                    </Box>
                  </Fade>
                </Box>
              </Stack>
            ) : (
              <>
                <Typography sx={{ mt: 2 }} variant="h6">
                  Vous êtes déjà connecté
                </Typography>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => signOut(firebaseAppAuth)}>
                  Déconnexion
                </Button>
              </>
            )}
          </CardContent>
        </Card>
      </Fade>
    </Container>
  );
}
