import { Business } from "@common/model/business";
import { useMemo } from "react";

/**
 * Return promotionss for today
 * with current Promotions if any
 * @param business
 * @returns
 */
export const useHasOffer = (business?: Business) => {
  return useMemo(() => {
    if (!business) return { todayOffers: undefined, currentOffer: undefined };
    const today = new Date();
    const todayOffers = business.offers.filter((Offer) => {
      if (Offer.type === "onetime" && Offer.date) {
        const date = Offer.date.toDate();
        if (date.toDateString() === today.toDateString()) {
          return true;
        }
      } else {
        if (Offer.week_days.includes(-1)) {
          return true;
        }
        if (Offer.week_days.includes(today.getDay())) {
          return true;
        }
      }
    });

    const currentOffer = todayOffers.find((Offer) => {
      const start_hour = parseInt(Offer.start_hour.split(":")[0]);
      const end_hour = parseInt(Offer.end_hour.split(":")[0]);
      const currentHour = today.getHours();

      return currentHour >= start_hour && currentHour <= end_hour;
    });

    return { todayOffers, currentOffer };
  }, [business]);
};
