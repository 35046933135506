import { useGetPendingInvites } from "@/common/firebase/services/owner-hooks";
import { BusinessForOwner } from "@common/index";
import {
  Alert,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { BusinessUserInviteRow } from "./BusinessUserInviteRow";

export function BusinessUserInvitationTable({
  businessCollection,
  multiple = true,
}: {
  businessCollection: BusinessForOwner[];
  multiple?: boolean; // Indicate if displays invitations for multiple businesses or only one
}) {
  const {
    data,
    error,
    mutate: mutatePending,
    isLoading: is,
  } = useGetPendingInvites(businessCollection);

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Table sx={{ minWidth: 350 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell align="right">Roles</TableCell>
            <TableCell align="right">Accés</TableCell>
            <TableCell align="right">Statut</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!data?.length && (
            <TableRow>
              <TableCell colSpan={4}>
                <Alert severity="info">
                  Vous n'avez aucune invitation en attente.
                </Alert>
              </TableCell>
            </TableRow>
          )}
          {data?.map((invite) => (
            <BusinessUserInviteRow
              invite={invite}
              businessCollection={businessCollection}
              businessId={!multiple ? businessCollection[0].id : undefined}
              mutate={mutatePending}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
