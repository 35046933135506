import * as Sentry from "@sentry/react";
import { FirebaseError } from "firebase/app";
import { enqueueSnackbar } from "notistack";

const IsProd = import.meta.env.PROD;

export class Logger {
  static debug(...args: any[]): void {
    if (!IsProd) {
      console.log(args);
    }
  }

  static error(...args: any[]): void {
    this.errorSilent(...args);

    // if (firebaseError?.code === "permission-denied") {
    //   return;
    // }

    enqueueSnackbar(
      typeof args[0] === "string" ? args[0] : "Une erreur est survenue :(",
      {
        variant: "error",
      }
    );
  }

  static errorSilent(...args: any[]): void {
    const firebaseError: FirebaseError | undefined = args.find(
      (arg) => arg instanceof FirebaseError
    );
    if (!IsProd) {
      console.error(args);
    } else {
      Sentry.captureException(firebaseError || args);
    }
  }

  static success(label: string): void {
    enqueueSnackbar(label, {
      variant: "success",
    });
  }
}
