import { FBCloudFunctions, FBCollections } from "@common/firebase";
import { ContactFormRequest } from "@common/index";
import { AppConfig } from "@common/model/config";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { firebaseAppFirestoreDb, firebaseAppFunctions } from "../config";
import { converter } from "../converter";

export async function getAppSettings() {
  const docRef = doc(
    firebaseAppFirestoreDb,
    FBCollections.AppConfig,
    "config"
  ).withConverter(converter<AppConfig>());
  return (await getDoc(docRef)).data();
}

export async function setAppSettings(config: Partial<AppConfig>) {
  const docRef = doc(
    firebaseAppFirestoreDb,
    FBCollections.AppConfig,
    "config"
  ).withConverter(converter<AppConfig>());
  return await setDoc(docRef, config);
}

export const postContactForm = async (form: ContactFormRequest) => {
  const inviteCallable = httpsCallable<ContactFormRequest, number>(
    firebaseAppFunctions,
    FBCloudFunctions.ContactForm
  );
  return inviteCallable(form).then((result) => {
    return result.data;
  });
};
