import { Theme, useMediaQuery } from "@mui/material";
import type { Location } from "@remix-run/router";
import { useEffect } from "react";
import {
  Navigate,
  NavigateFunction,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.scss";
import ContactForm from "./ContactForm";
import RegisterBusinessUser from "./auth/signin/RegisterBusinessUser";
import RegisterCustomer from "./auth/signin/RegisterCustomer";
import SignIn from "./auth/signin/SignIn";
import NotFound from "./common/components/NotFound";
import { getAppSettings } from "./common/firebase/services/app-requests";
import { useGoogleMapApi } from "./common/google-map";
import { AppLayout } from "./common/layout/AppLayout";
import LoginLayout from "./common/layout/LoginLayout";
import { OwnerBasicLayout } from "./common/layout/OwnerBasicLayout";
import OwnerLayoutV2 from "./common/layout/OwnerLayoutV2";
import BusinessView from "./customer/BusinessView";
import * as DashboardPublic from "./customer/Dashboard";
import EditProfile from "./customer/EditProfile";
import Home from "./customer/Home";
import Loyalty from "./customer/Loyalty";
import Programs from "./customer/Programs";
import Search from "./customer/Search";
import BillingContainer from "./owner/BillingContainer";
import BusinessCreate from "./owner/BusinessCreate";
import BusinessList from "./owner/BusinessList";
import BusinessOwnerView from "./owner/BusinessView";
import OwnerSettings from "./owner/OwnerSettings";
import UserManagementContainer from "./owner/UserManagementContainer";
import BusinessDashboard from "./owner/tabs/BusinessDashboard";
import BusinessEdit from "./owner/tabs/BusinessEdit";
import BusinessEventPrograms from "./owner/tabs/BusinessEventPrograms";
import BusinessLotteryPrograms from "./owner/tabs/BusinessLotteryPrograms";
import BusinessLoyaltyPrograms from "./owner/tabs/BusinessLoyaltyPrograms";
import BusinessOffer from "./owner/tabs/BusinessOffer";
import UserManagement from "./owner/tabs/UserManagement";
import { useAppSettingsStore } from "./store/appSettingsStore";

export const history: {
  navigate: NavigateFunction | null;
  location: Location | null;
} = {
  navigate: null,
  location: null,
};

function App() {
  history.navigate = useNavigate();
  history.location = useLocation();
  useGoogleMapApi();
  const { setConfig } = useAppSettingsStore();

  const isSmMedia = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  useEffect(() => {
    getAppSettings().then((config) => {
      if (config) {
        setConfig(config);
      }
    });
  }, [setConfig]);

  return (
    <Routes>
      <Route path="/" element={<LoginLayout />}>
        <Route path="auth" element={<SignIn />} />
        <Route path="auth/register/customer" element={<RegisterCustomer />} />
        <Route
          path="auth/register/business"
          element={<RegisterBusinessUser />}
        />
      </Route>
      <Route path="owner" element={<OwnerLayoutV2 />}>
        <Route element={<OwnerBasicLayout />}>
          <Route index element={<Navigate to="/owner/business/list" />} />
          {/* <Route path="dashboard" element={<DashboardOwner.default />} /> */}
          <Route path="business/list" element={<BusinessList />} />
          <Route path="business/create" element={<BusinessCreate />} />
          <Route path="users" element={<UserManagementContainer />} />
          <Route path="billing" element={<BillingContainer />} />
          <Route path="settings" element={<OwnerSettings />} />
          <Route path="contact" element={<ContactForm />} />
        </Route>
        <Route path="business/:id" element={<BusinessOwnerView />}>
          <Route index element={<Navigate to="home" />} />
          <Route path="home" element={<BusinessDashboard />} />
          <Route path="offer" element={<BusinessOffer />} />
          <Route path="loyalty" element={<BusinessLoyaltyPrograms />} />
          <Route path="lottery" element={<BusinessLotteryPrograms />} />
          <Route path="events" element={<BusinessEventPrograms />} />
          <Route path="edit" element={<BusinessEdit />} />
          <Route path="users" element={<UserManagement />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="/" element={<AppLayout />}>
        <Route path="dashboard" element={<DashboardPublic.default />} />
        <Route path="dashboard/edit" element={<EditProfile />} />
        <Route path="loyalty" element={<Loyalty />} />
        <Route path="programs" element={<Programs />} />
        <Route path="business/:id" element={<BusinessView />} />
        <Route path="search" element={<Search />} />
        <Route path="contact" element={<ContactForm />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route
        path="/"
        element={<AppLayout hasSearch={true} hasHeader={!isSmMedia} />}>
        <Route index element={<Home />} />
      </Route>
    </Routes>
  );
}

export default App;
