import { FBCollections } from "@common/firebase";
import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Typography,
} from "@mui/material";
import { RefObject, useEffect, useRef, useState } from "react";
import { Logger } from "../error-handling";
import { fileUpload } from "../firebase/common";

export const ImageUploader = ({
  children,
  callback,
  collection,
  type,
  itemId,
  userId,
  parentInputRef,
}: {
  children: JSX.Element;
  callback: ({ url, key }: { url: string; key: string }) => void;
  collection: FBCollections;
  itemId: string;
  userId: string;
  parentInputRef?: RefObject<HTMLInputElement>;
  type?: string;
}) => {
  const [progress, setProgress] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const inputRef = parentInputRef || useRef<HTMLInputElement>(null);
  const upload = (file: File) => {
    fileUpload(
      file,
      collection,
      itemId,
      userId,
      setProgress,
      setLoading,
      callback,
      (e) => Logger.error("Erreur lors du téléchargement de l'image", e),
      type
    );
  };

  useEffect(() => {
    parentInputRef?.current?.files &&
      parentInputRef?.current?.files.length &&
      upload(parentInputRef?.current?.files[0]);
  }, []);

  if (parentInputRef) {
    return <></>;
  }

  return (
    <>
      <input
        hidden
        ref={inputRef}
        type="file"
        onChange={(e) => {
          e.target.files && e.target.files.length && upload(e.target.files[0]);
        }}
      />
      <Box
        sx={{ position: "relative", cursor: "pointer" }}
        onClick={() => inputRef.current?.click()}>
        {loading && (
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 9,
            }}>
            {progress ? (
              <CircularProgressWithLabel value={progress} />
            ) : (
              <CircularProgress />
            )}
          </Box>
        )}
        <Box sx={{ opacity: loading ? 0.3 : 1 }}>{children}</Box>
      </Box>
    </>
  );
};

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Typography
          variant="caption"
          component="div"
          color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
