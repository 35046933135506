import { Box, SxProps, Theme } from "@mui/material";
import { useRef } from "react";

export const ImageSelector = ({
  children,
  sx = {},
  callback,
}: {
  children: JSX.Element;
  sx?: SxProps<Theme>;
  callback: (file: File) => void;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <input
        hidden
        ref={inputRef}
        type="file"
        onChange={(e) => {
          e.target.files &&
            e.target.files.length &&
            callback(e.target.files[0]);
        }}
      />
      <Box
        sx={{ position: "relative", cursor: "pointer", ...sx }}
        onClick={() => inputRef.current?.click()}>
        {children}
      </Box>
    </>
  );
};
