import { Suspense } from "react";
import { Outlet } from "react-router";
import "./global.css";

import { useScrollToTop } from "./hooks/use-scroll-to-top";

import Logo from "@/assets/logo.webp";
import { Avatar, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ThemeProvider from "./theme";

// ----------------------------------------------------------------------

export default function LoginLayout() {
  useScrollToTop();

  return (
    <ThemeProvider>
      <Suspense>
        <Link
          component={RouterLink}
          to="/"
          sx={{
            position: "absolute",
            top: "24px",
            left: "24px",
            display: { xs: "none", sm: "block" },
          }}>
          <Avatar src={Logo}></Avatar>
        </Link>
        <Outlet />
      </Suspense>
    </ThemeProvider>
  );
}
