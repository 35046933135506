import {
  FirebaseMessaging,
  GetTokenResult,
} from "@capacitor-firebase/messaging";
import { updateCustomerSettings } from "./services/user-requests";

export async function initMessaging(userUid: string) {
  let tokenResult: GetTokenResult | undefined;
  const checkResult = await FirebaseMessaging.checkPermissions();
  if (checkResult.receive !== "granted") {
    const requestResult = await FirebaseMessaging.requestPermissions();
    if (requestResult.receive === "granted") {
      tokenResult = await FirebaseMessaging.getToken();
      await updateCustomerSettings(userUid, tokenResult.token);
    }
  } else {
    tokenResult = await FirebaseMessaging.getToken();
    await updateCustomerSettings(userUid, tokenResult.token);
  }

  if (tokenResult) {
    await FirebaseMessaging.addListener(
      "notificationActionPerformed",
      (event) => {
        console.log("notificationActionPerformed", { event });
      }
    );
  }
}
