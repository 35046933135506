import * as admin from "firebase-admin";
import { Timestamp } from "firebase/firestore";
import { Image } from "./common";
import { CustomerFirestore2 } from "./customer";
import { ProgramType, Scan } from "./scan";

export enum BusinessType {
  Restaurant = "restaurant",
  Bar = "bar",
}

export interface BusinessInvitation {
  email: string;
  created_at: Timestamp | admin.firestore.Timestamp;
  expire_at: Timestamp | admin.firestore.Timestamp;
  business_ids: string[];
  role: UserRole;
  claimed_by: string | null;
}

export interface BusinessInviteMapped extends BusinessInvitation {
  id: string;
}

export enum UserRole {
  Owner = "Owner",
  Admin = "Admin",
  Scanner = "Scanner",
  Viewer = "Viewer",
}

export interface BusinessInviteForm {
  emails: string[];
  role: UserRole;
  business_ids: Business[];
}

export interface BusinessInvitedUser {
  id: string;
  role: UserRole;
}

export interface BusinessInvitedUserMapped extends BusinessInvitedUser {
  businessIds: string[];
}
export interface BusinessFirestore {
  utc_offset_minutes: number;
  opening_hours: OpeningHour[];
  owner_id: string;
  users_permissions?: BusinessInvitedUser[];
  location: Location;
  description: string;
  name: string;
  type: BusinessType[];
  created: Created;
  address: Address;
  google_place_id: string;
  geohash: string;
  status: "draft" | "active";
  subscription_active: boolean;
  menu_pictures?: Image[];
  cover_picture?: Image;
  picture?: Image;
  // events?: BusinessEvent[];
  // programs?: {
  //   loyalty?: ProgramLoyalty[];
  //   lottery?: ProgramLottery[];
  //   survey?: {
  //     description: string;
  //     questions: {
  //       label: string;
  //       answers: {
  //         label: string;
  //       }[];
  //     }[];
  //     responses: {}[];
  //     reward?: Reward;
  //   };
  // };
  updated_at: Timestamp;
  lt: number; // Last any update timestamp in unix
}

export interface BusinessEventCreate extends Omit<BusinessEvent, "id"> {}
export interface BusinessOfferCreate extends Omit<BusinessOffer, "id"> {}

export interface BusinessEvent {
  title: string;
  description?: string;
  id: string;
  images?: Image[];
  date_start: Timestamp;
  date_end: Timestamp;
  created_at: Timestamp;
  updated_at: Timestamp;
}
export interface BusinessEventForm
  extends Omit<
    BusinessEvent,
    "date_start" | "date_end" | "id" | "created_at" | "images" | "updated_at"
  > {
  date_start: Date;
  created_at: Date;
  date_end: Date;
  updated_at: Date;
}

export interface BusinessOfferForm
  extends Omit<
    BusinessOffer,
    | "created_at"
    | "updated_at"
    | "archived_at"
    | "id"
    | "week_days"
    | "end_hour"
    | "start_hour"
    | "date"
  > {
  week_days: number[];
  date?: Date | null;
  created_at: Date;
  updated_at: Date;
  start_hour?: Date;
  end_hour?: Date;
}

export interface ProgramLottery<T = Timestamp> extends BusinessProgram<T> {
  startDate?: number;
  title: string;
  scans: number;
  conditions: {
    uniqueParticipant: boolean;
    numberOfWinnersToDraw: number;
    maximumEntries?: number;
    limit_date?: string | null;
    fulfillCondition: any;
    interval?: number;
  };
  drawResults?: WeightedParticipant<T>[];
}

export interface ProgramLotteryForm extends Omit<ProgramLottery, "conditions"> {
  conditions: {
    uniqueParticipant: boolean;
    numberOfWinnersToDraw: number;
    maximumEntries?: number;
    limit_date?: any | null;
    fulfillCondition: "maximum_participant" | "limit_date";
  };
}

export interface BusinessProgram<T = Timestamp> {
  id: string;
  description: string;
  status: "active" | "archived" | "draft";
  type: ProgramType;
  scans: number;
  reward: Reward[];
  created_at: T;
  updated_at: T;
  archived_at: T | null;
}

export interface BusinessOffer {
  id: string;
  title: string;
  description: string;
  type: "recurring" | "onetime";
  week_days: number[];
  date: Timestamp | null;
  start_hour: string;
  end_hour: string;
  created_at: Timestamp;
  updated_at: Timestamp;
  archived_at: Timestamp | null;
}

export interface ProgramLoyalty extends BusinessProgram {
  // totalScansToAchieve: number;
  duration?: number;
  id: string;
  conditions: {
    fulfillItemNumber: number;
    fullfillItemType: { label: string; value: number };
    rewardItemNumber: number;
    rewardItemType: { label: string; value: number };
    interval: number;
  };
}

export interface Reward {
  label: string;
  barcode?: string;
  qrcode?: string;
  customCode?: string;
}
export interface BusinessForOwner extends Business {
  current_user_permission: BusinessInvitedUser;
}
export interface Business extends Omit<BusinessFirestore, "events"> {
  id: string;
  utc_offset_minutes: number;
  opening_hours: OpeningHour[];
  owner_id: string;
  location: Location;
  description: string;
  name: string;
  created: Created;
  address: Address;
  google_place_id: string;
  geohash: string;
  subscribed_customers_count: number;
  stats?: {
    scansLast24: number;
    scansLast7Days: number;
  };
  offers: BusinessOffer[];
  rawEvents?: BusinessEvent[];
  events?: {
    today?: BusinessEvent;
    future: BusinessEvent[];
    past: BusinessEvent[];
  };
  programs?: {
    loyalty?: ProgramLoyalty[];
    filteredLoyalty: { active?: ProgramLoyalty; archived: ProgramLoyalty[] };
    lottery?: ProgramLottery[];
    filteredLottery: { active?: ProgramLottery; archived: ProgramLottery[] };
    survey?: {
      description: string;
      questions: {
        label: string;
        answers: {
          label: string;
        }[];
      }[];
      responses: {}[];
      reward?: Reward;
    };
  };
  updated_at: Timestamp;
  hasOwnership?: boolean;
}

export interface OpeningHour {
  close?: Close;
  c?: Close[];
  active: boolean;
  day: number;
  open?: Open;
  o?: Open[];
}

export interface Close {
  timestamp: string;
  value: string;
  day: number;
  minutes?: number;
}

export interface Open {
  day: number;
  value: string;
  timestamp: string;
  minutes?: number;
}

export interface Location {
  lat: number;
  lon: number;
}

export interface Created {
  seconds: number;
  nanoseconds: number;
}

export interface Address {
  street_address_addition: string;
  country: string;
  city: string;
  street_address: string;
  postal_code: string;
}

export type WeightedParticipants = {
  [key: string]: WeightedParticipant;
};

export type WeightedParticipations = {
  [key: string]: WeightedParticipation;
};

export type WeightedParticipation<T = Timestamp> = {
  data: Scan;
  weight: number;
  distributed: {
    distributed_on: T;
    distributed_by: string;
  } | null;
};

export type WeightedParticipant<T = Timestamp> = {
  customer: CustomerFirestore2;
} & WeightedParticipation<T>;
