import { yupResolver } from "@hookform/resolvers/yup";
import { EmojiEvents, EventAvailable, Mood } from "@mui/icons-material";
import {
  Dialog,
  List,
  ListItem,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
} from "@mui/material";
import {
  LocalizationProvider,
  StaticDatePicker,
  StaticTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { ObjectSchema, array, date, object, string } from "yup";

export interface MapFilters {
  when: ("now" | "date" | "time")[];
  date: Date | null;
  time: Date | null;
  programs: ("offer" | "event" | "reward")[];
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

function HomeFilters({
  currentFilters = {},
  onFilterChanged,
}: {
  currentFilters?: Partial<MapFilters>;
  onFilterChanged: (filters: MapFilters) => void;
}) {
  const schema: ObjectSchema<MapFilters> = object().shape({
    when: array().of(string<"now" | "date" | "time">().required()).required(),
    date: date().nullable().required(),
    time: date().nullable().required(),
    programs: array()
      .of(string<"offer" | "event" | "reward">().required())
      .required(),
  });

  const { setValue, control, watch } = useForm<MapFilters>({
    resolver: yupResolver(schema),
    defaultValues: {
      when: [],
      programs: [],
      time: null,
      date: null,
      ...currentFilters,
    },
  });
  const formValues = useWatch({ control });

  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogTime, setOpenDialogTime] = useState(false);
  const [filters, setFilters] = useState<
    Partial<MapFilters> | null | undefined
  >(currentFilters);

  useEffect(() => {
    setFilters(
      formValues.when?.length || formValues.programs?.length
        ? (formValues as MapFilters)
        : null
    );

    if (!formValues) {
      return;
    }
    onFilterChanged(formValues as MapFilters);
  }, [formValues]);

  return (
    <List sx={{ p: 0 }}>
      <ListItem sx={{ px: 1, flexDirection: "column", alignItems: "start" }}>
        <Typography variant="body2" color="grey.900">
          Ouvert : {!filters?.when?.length && "Peu importe"}
        </Typography>
        <Controller
          name="when"
          control={control}
          render={({ field }) => {
            return (
              <StyledToggleButtonGroup
                sx={{ background: "white" }}
                {...field}
                onChange={(event: React.MouseEvent<HTMLElement>, value) => {
                  if (value[value.length - 1] === "now") {
                    setValue(field.name, ["now"]);
                  } else {
                    setValue(
                      field.name,
                      value?.filter((v: string) => v !== "now")
                    );
                  }
                }}
                fullWidth
                aria-label="text formatting"
                color="secondary">
                <ToggleButton
                  fullWidth
                  sx={{ p: 1, m: "4px", borderRadius: "4px" }}
                  value="now"
                  aria-label="bold">
                  <Typography textTransform={"none"} variant="caption">
                    Maintenant
                  </Typography>
                </ToggleButton>
                <ToggleButton
                  fullWidth
                  value="date"
                  aria-label="italic"
                  onClick={(_, value) => {
                    if (
                      !field.value?.includes("date") &&
                      value?.includes("date")
                    ) {
                      setOpenDialog(true);
                    }
                  }}
                  sx={{ p: 1, m: "4px", borderRadius: "4px" }}>
                  <Typography textTransform={"none"} variant="caption">
                    {filters?.date
                      ? dayjs(filters.date).format("ddd DD MMM")
                      : "Date"}
                  </Typography>
                </ToggleButton>
                <ToggleButton
                  fullWidth
                  value="time"
                  aria-label="italic"
                  onClick={(_, value) => {
                    if (
                      !field.value?.includes("time") &&
                      value?.includes("time")
                    ) {
                      setOpenDialogTime(true);
                    }
                  }}
                  sx={{ p: 1, m: "4px", borderRadius: "4px" }}>
                  <Typography textTransform={"none"} variant="caption">
                    {filters?.time
                      ? dayjs(filters.time).format("HH:mm")
                      : "Heure"}
                  </Typography>
                </ToggleButton>
              </StyledToggleButtonGroup>
            );
          }}
        />
      </ListItem>
      <ListItem sx={{ px: 1, flexDirection: "column", alignItems: "start" }}>
        <Typography variant="body2" color="grey.900">
          Avec : {!filters?.programs?.length && "Peu importe"}
        </Typography>
        <Controller
          name="programs"
          control={control}
          render={({ field }) => {
            return (
              <ToggleButtonGroup
                {...field}
                sx={{ background: "white" }}
                onChange={(
                  event: React.MouseEvent<HTMLElement>,
                  value: string
                ) => {
                  setValue(field.name, value as any);
                }}
                fullWidth
                aria-label="text formatting"
                color="secondary">
                <ToggleButton
                  fullWidth
                  sx={{ p: 1 }}
                  value="offer"
                  aria-label="bold">
                  <Stack direction={"column"} alignItems={"center"}>
                    <Mood />
                    <Typography textTransform={"none"} variant="caption">
                      Promotions
                    </Typography>
                  </Stack>
                </ToggleButton>
                <ToggleButton
                  fullWidth
                  value="reward"
                  aria-label="italic"
                  color="primary"
                  sx={{ p: 1 }}>
                  <Stack direction={"column"} alignItems={"center"}>
                    <EmojiEvents />
                    <Typography textTransform={"none"} variant="caption">
                      Récompense
                    </Typography>
                  </Stack>
                </ToggleButton>
                <ToggleButton
                  fullWidth
                  value="event"
                  aria-label="italic"
                  color="primary"
                  sx={{ p: 1 }}>
                  <Stack direction={"column"} alignItems={"center"}>
                    <EventAvailable />
                    <Typography textTransform={"none"} variant="caption">
                      Évènement
                    </Typography>
                  </Stack>
                </ToggleButton>
              </ToggleButtonGroup>
            );
          }}
        />
      </ListItem>
      <Dialog
        onClose={() => setOpenDialog(false)}
        onAbort={() => setOpenDialog(false)}
        onSubmit={() => setOpenDialog(false)}
        open={openDialog}>
        <Controller
          name="date"
          control={control}
          render={({ field }) => {
            return (
              <LocalizationProvider
                adapterLocale={"fr"}
                dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                  value={dayjs(field.value)}
                  onClose={() => setOpenDialog(false)}
                  onAccept={(value: Dayjs | null) => {
                    setValue(field.name, value?.toDate() || null);
                    setOpenDialog(false);
                  }}></StaticDatePicker>
              </LocalizationProvider>
            );
          }}></Controller>
      </Dialog>
      <Dialog
        onClose={() => setOpenDialogTime(false)}
        onAbort={() => setOpenDialogTime(false)}
        onSubmit={() => setOpenDialogTime(false)}
        open={openDialogTime}>
        <Controller
          name="time"
          control={control}
          render={({ field }) => {
            return (
              <LocalizationProvider
                adapterLocale={"fr"}
                dateAdapter={AdapterDayjs}>
                <StaticTimePicker
                  value={dayjs(field.value)}
                  onClose={() => setOpenDialogTime(false)}
                  onAccept={(value: Dayjs | null) => {
                    setValue(field.name, value?.toDate() || null);
                    setOpenDialogTime(false);
                  }}></StaticTimePicker>
              </LocalizationProvider>
            );
          }}></Controller>
      </Dialog>
    </List>
  );
}

export default HomeFilters;
