import { Business } from "@common/model/business";
import dayjs, { Dayjs } from "dayjs";
import { useMemo } from "react";

export const useIsOpen = (business?: Business) => {
  return useMemo(() => {
    if (!business) return undefined;

    const utcOffset = business.utc_offset_minutes || 0;

    const currentLocalHour = dayjs().utcOffset(utcOffset);
    const correctedDayIndex = getCorrectedIndex(currentLocalHour);

    const isCurrentlyOpened = business.opening_hours.find((oh) => {
      if (!oh.active || !oh.open || !oh.close) return;

      const targetedDate = currentLocalHour.add(
        oh.day - correctedDayIndex,
        "day"
      );
      const openTime = oh.open.value.split(":");
      const closeTime = oh.close.value.split(":");

      const openLocalHour = dayjs().utcOffset(utcOffset).set({
        year: targetedDate.year(),
        month: targetedDate.month(),
        date: targetedDate.date(),
        hour: openTime[0],
        minute: openTime[1],
      });
      const closeLocalHour = dayjs()
        .utcOffset(utcOffset)
        .set({
          year: targetedDate.year(),
          month: targetedDate.month(),
          date: targetedDate.date(),
          hour: closeTime[0],
          minute: closeTime[1],
        })
        .add(oh.close.day !== oh.open.day ? 1 : 0, "day");
      // targetedDate.utcOffset(business.utc_offset_minutes);
      // closeLocalHour.utcOffset(business.utc_offset_minutes);
      // openLocalHour.utcOffset(business.utc_offset_minutes);
      // currentLocalHour.utcOffset(business.utc_offset_minutes);

      // console.log(
      //   business,
      //   currentLocalHour.format(),
      //   openLocalHour.format(),
      //   closeLocalHour.format()
      // );

      const isOpen =
        currentLocalHour.isBefore(closeLocalHour) &&
        currentLocalHour.isAfter(openLocalHour);
      // const isToday =
      //   getCorrectedIndex(currentLocalHour) === getCorrectedIndex(targetedDate);

      // console.log(
      //   oh,
      //   getCorrectedIndex(currentLocalHour),
      //   days[getCorrectedIndex(targetedDate)],
      //   currentLocalHour.day(),
      //   closeLocalHour.day(),
      //   getCorrectedIndex(currentLocalHour) ===
      //     getCorrectedIndex(closeLocalHour)
      // );
      // console.log(isOpen, isToday);

      return isOpen;
    });

    if (isCurrentlyOpened) {
      return isCurrentlyOpened;
    }

    return undefined;

    // const currentOpeningHours = business.opening_hours[correctedDayIndex];

    // const openLocalHour = dayjs(currentOpeningHours.open.value, "HH:mm");
    // const closeLocalHour = dayjs(currentOpeningHours.close.value, "HH:mm").add(
    //   currentOpeningHours.close.day !== currentOpeningHours.open.day ? 1 : 0,
    //   "day"
    // );
    // closeLocalHour.utcOffset(business.utc_offset_minutes);
    // openLocalHour.utcOffset(business.utc_offset_minutes);
    // currentLocalHour.utcOffset(business.utc_offset_minutes);

    // return (
    //   currentLocalHour.isBefore(closeLocalHour) &&
    //   currentLocalHour.isAfter(openLocalHour)
    // );
  }, [business]);
};

const getCorrectedIndex = (day: Dayjs) => {
  return day.day() === 0 ? 6 : day.day() - 1;
};
