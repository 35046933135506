import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { CheckCircle, Mail, Refresh } from "@mui/icons-material";
import { Alert, Box, Button, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import createTheme from "@mui/material/styles/createTheme";
import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import customParseFormat from "dayjs/plugin/customParseFormat";
import objectSupport from "dayjs/plugin/objectSupport";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import {
  CustomContentProps,
  SnackbarContent,
  SnackbarProvider,
  closeSnackbar,
} from "notistack";
import { forwardRef } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Link } from "react-router-dom";
import { SWRConfig } from "swr";
import App from "./App";
import AppUrlListener from "./AppUrlListener";
import { Logger } from "./common/error-handling";
import { CounterContextProvider } from "./common/google-map";
import "./i18n";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    rounded: true;
  }
}

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

if (!import.meta.env.VITE_FIREBASE_STORAGE_BUCKET) {
  Logger.errorSilent("Missing storage bucket");
  throw "Missing storage bucket";
}

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(objectSupport);
dayjs.locale("fr");

const basepath = import.meta.env.BASE_URL;

const theme = createTheme({
  palette: {
    background: { default: grey["100"] },
    primary: {
      main: "#3E63E9",
    },
  },
});
const style = {
  "*": {
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <CounterContextProvider>
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        // action={(snackbarId) => (
        //   <IconButton size="small" onClick={() => closeSnackbar(snackbarId)}>
        //     <Clear />
        //   </IconButton>
        // )}
        Components={{
          success: forwardRef<HTMLDivElement, CustomContentProps>(
            ({ id, message, action }, ref) => (
              <SnackbarContent ref={ref}>
                <Alert
                  severity="info"
                  icon={<CheckCircle color="info" />}
                  onClose={() => {
                    closeSnackbar(id);
                  }}
                  sx={{
                    width: 1,
                    border: "2px solid",
                    borderImageSlice: 1,
                    borderImageSource: `linear-gradient(to bottom, #3E63E9, #8355DF)`,
                    background: "white",
                  }}>
                  {message}
                </Alert>
              </SnackbarContent>
            )
          ),
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      />
      <BrowserRouter basename={basepath}>
        <SWRConfig
          value={{
            provider: () => new Map(),
            onError: (error, key) => {
              if (error.status !== 403 && error.status !== 404) {
                Logger.error(error, key);
              }
            },
          }}>
          <Sentry.ErrorBoundary
            fallback={
              <Box
                sx={{
                  height: 1,
                  width: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexFlow: "column",
                }}>
                <img src="https://i.imgur.com/qIufhof.png" width="120px" />
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Une erreur s'est produite :(
                </Typography>
                <Grid
                  container
                  justifyContent="center"
                  spacing={2}
                  sx={{ mt: 2 }}>
                  <Grid item>
                    <Button
                      component={Link}
                      to={"/contact"}
                      variant="outlined"
                      startIcon={<Mail />}>
                      Contactez nous
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      startIcon={<Refresh />}
                      onClick={() => location.reload()}>
                      Rafraichir la page
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            }>
            <AppUrlListener></AppUrlListener>
            <App />
          </Sentry.ErrorBoundary>
        </SWRConfig>
      </BrowserRouter>
    </ThemeProvider>
  </CounterContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
