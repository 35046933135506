import { useMemo, useState } from "react";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";

import { getImageUrl } from "@/common/firebase/common";
import { userMenu } from "@/common/layout/OwnerLayout";
import { useUserStore } from "@/store/userStore";
import { ListItemIcon } from "@mui/material";
import { useNavigate } from "react-router";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Home",
    icon: "eva:home-fill",
  },
  {
    label: "Profile",
    icon: "eva:person-fill",
  },
  {
    label: "Settings",
    icon: "eva:settings-2-fill",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const userState = useUserStore();
  const nav = useNavigate();

  const [open, setOpen] = useState<HTMLElement | null>(null);
  const initials = useMemo(() => {
    if (userState.user?.customer?.firstname) {
      return (
        userState.user?.customer?.username?.slice(0, 2) ||
        userState.user?.customer?.firstname?.slice(0, 2)
      );
    } else if (userState.user?.displayName) {
      const parts = userState.user.displayName.split(" ");
      return `${parts[0].charAt(0)}${parts[1]?.charAt(0)}`;
    }
    return "NA";
  }, [userState.user]);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = (url?: string) => {
    setOpen(null);
    if (url) {
      nav(url);
    }
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}>
        <Avatar
          alt={userState?.user?.displayName || ""}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
          src={
            (userState.user?.customer?.profile_picture &&
              getImageUrl(userState.user?.customer?.profile_picture.key)) ||
            ""
          }>
          {initials}
        </Avatar>
      </IconButton>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={() => handleClose()}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1,
            ml: 0.75,
            width: 200,
          },
        }}>
        <Box sx={{ my: 1.5, px: 2 }}>
          <Typography variant="subtitle2" noWrap>
            {userState.user?.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {userState.user?.businessUser?.phoneNumber.number}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        {userMenu.map((item) => (
          <MenuItem
            onClick={() => {
              if (item.action) item.action(nav);
              handleClose(item.to);
            }}
            key={item.label}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            {item.label}
          </MenuItem>
        ))}
      </Popover>
    </>
  );
}
