import { useBusinessDocumentForOwner } from "@/common/firebase/services/owner-hooks";
import { useResponsive } from "@/common/layout/hooks/use-responsive";
import { useAppSettingsStore } from "@/store/appSettingsStore";
import { ProgramType, UserRole } from "@common/index";
import { Edit, Menu, PeopleAltOutlined } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useParams,
} from "react-router-dom";
import { useUserStore } from "../store/userStore";

export default function BusinessOwnerView() {
  const { id } = useParams();
  const { user } = useUserStore();

  if (!id || !user) return <></>;

  return <View id={id} userId={user.uid} />;
}

const View = ({ id, userId }: { id: string; userId: string }) => {
  const { business, isLoading } = useBusinessDocumentForOwner(id, userId);
  const [tabList, setTabList] = useState<any[]>([]);
  const [tabs, setTabs] = useState<any[]>([]);
  const [currentTab, setCurrentTab] = useState(0);
  const { pathname } = useLocation();
  const { hasFeature, config } = useAppSettingsStore();
  const [accordionMenuExpanded, setAccordionMenuExpanded] = useState(false);
  const mdUp = useResponsive("up", "md");

  useEffect(() => {
    const list: { label: any; subLabel?: string; to: string }[] = [
      {
        label: "Accueil",
        to: "home",
      },
      {
        label: "Promotions",
        to: "offer",
      },
    ];
    if (hasFeature(ProgramType.loyalty)) {
      list.push({
        label: "Fidélité",
        to: "loyalty",
      });
    }
    if (hasFeature(ProgramType.lottery)) {
      list.push({
        label: "Lotterie",
        to: "lottery",
      });
    }
    list.push(
      {
        label: "Évènements",
        to: "events",
      },
      {
        label: <Edit />,
        subLabel: "Éditer",
        to: "edit",
      },
      {
        label: <PeopleAltOutlined />,
        subLabel: "Utilisateurs",
        to: "users",
      }
    );
    setTabList(list);
  }, [config]);

  useEffect(() => {
    const currentTabIndex = tabs.findIndex((tab) => pathname.endsWith(tab.to));
    setCurrentTab(currentTabIndex > -1 ? currentTabIndex : 0);
  }, [pathname]);

  useEffect(() => {
    if (business) {
      if ([UserRole.Scanner].includes(business.current_user_permission.role)) {
        setTabs(tabList.splice(0, 4));
        return;
      }
      if ([UserRole.Viewer].includes(business.current_user_permission.role)) {
        setTabs(tabList.splice(0, 4));
        return;
      }
      setTabs(tabList);
    } else {
      setTabs([]);
    }
  }, [business]);

  if (isLoading)
    return (
      <Container maxWidth="lg" sx={{ pt: 1, pb: 2 }}>
        <Grid container rowSpacing={2} columnSpacing={2.75}>
          <Grid item xs={12}>
            <Card>
              <Grid container sx={{ width: 1 }}>
                <Grid item xs={12} sm={"auto"} sx={{ position: "relative" }}>
                  <Skeleton
                    variant="rectangular"
                    sx={{ width: 1, minWidth: 150, height: 200 }}
                  />
                </Grid>
                <Grid item xs>
                  <CardContent>
                    <Grid container rowSpacing={2}>
                      <Grid item xs>
                        <Skeleton variant="text" width="74%" />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" width="66%" />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" width="100%" height={54} />
          </Grid>
          <Grid item xs={12}>
            <Grid container columnSpacing={2.75}>
              <Grid item xs={12} sm={6}>
                <Grid container>
                  <Grid item xs>
                    <Skeleton width="66%" />
                  </Grid>
                </Grid>
                <Skeleton height={54} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container>
                  <Grid item xs>
                    <Skeleton width="66%" />
                  </Grid>
                </Grid>
                <Skeleton height={54} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Skeleton height={254} sx={{ transform: "none" }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack spacing={2}>
              <Skeleton height={74} sx={{ transform: "none" }} />
              <Skeleton height={74} sx={{ transform: "none" }} />
              <Skeleton height={74} sx={{ transform: "none" }} />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    );

  return (
    <Grid container>
      {business?.status === "draft" && (
        <Grid item xs={12}>
          <Alert severity="warning" sx={{ alignItems: "center" }}>
            Cet établissement est en mode brouillon et n'est pas public pour le
            moment.{" "}
            <Button component={Link} to={"edit#publish"} sx={{ px: 0 }}>
              Cliquez ici pour le rendre visible au public
            </Button>
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        {tabList &&
          (mdUp ? (
            <Paper elevation={0}>
              <Tabs
                value={currentTab}
                variant="scrollable"
                scrollButtons={"auto"}
                aria-label="nav tabs example"
                className="tabnav">
                {tabs.map((tab, i) => (
                  <Tab
                    key={tab.to}
                    value={i}
                    component={NavLink}
                    label={tab.label}
                    to={tab.to}
                  />
                ))}
              </Tabs>
            </Paper>
          ) : (
            <Accordion
              expanded={accordionMenuExpanded}
              onChange={(_e, value) => setAccordionMenuExpanded(value)}>
              <AccordionSummary
                expandIcon={<Menu />}
                aria-controls="panel1-content"
                id="panel1-header">
                <Typography variant="h6">
                  {tabList[currentTab].subLabel || tabList[currentTab].label}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <MenuList>
                  {tabList.map((tab, i) => (
                    <MenuItem
                      key={tab.to}
                      selected={currentTab === i}
                      component={NavLink}
                      to={tab.to}
                      onClick={() => setAccordionMenuExpanded(false)}>
                      <ListItemText>{tab.subLabel || tab.label}</ListItemText>
                    </MenuItem>
                  ))}
                </MenuList>
              </AccordionDetails>
            </Accordion>
          ))}
      </Grid>
      <Grid item xs>
        <Container maxWidth="lg" sx={{ pt: 1, pb: 2 }}>
          <Outlet />
        </Container>
      </Grid>
    </Grid>
  );
};
