import { useSetCurrentPageName } from "@/store/appSettingsStore";
import { useUserStore } from "@/store/userStore";
import { ContactFormRequest } from "@common/index";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import { useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { CheckCircleOutline, Send } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { number, object, ObjectSchema, string } from "yup";
import { Logger } from "./common/error-handling";
import { postContactForm } from "./common/firebase/services/app-requests";

const schema: ObjectSchema<ContactFormRequest> = object().shape({
  email: string().email().required(),
  fullName: string().required(),
  message: string().required(),
  subject: string().required(),
  category: number().required(),
});

//& google.maps.places.PlaceOpeningHoursPeriod
export default function ContactForm() {
  useSetCurrentPageName("Assistance");
  const userState = useUserStore();
  const [loading, setLoading] = useState(false);
  const [messageSent, setMessageSent] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm<ContactFormRequest>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: userState.user?.email || "",
      fullName: userState.user?.displayName || "",
    },
  });

  const categoryValue = watch("category");

  const sendContactForm = async (formData: ContactFormRequest) => {
    setLoading(true);
    try {
      await postContactForm(formData);
      setMessageSent(true);
      reset();
    } catch (e) {
      Logger.error("Une erreur s'est produite", e);
    }
    setLoading(false);
  };

  return (
    <Container component="main" disableGutters sx={{ p: 2 }}>
      {messageSent ? (
        <Card>
          <CardContent>
            <Stack spacing={2} sx={{ textAlign: "center" }}>
              <Typography variant="h4">Message envoyé !</Typography>
              <Typography variant="body1">
                Vous allez bientôt recevoir un mail de confirmation. <br></br>{" "}
                Vous pouvez ajouter des informations dans votre ticket a tout
                moment.
                <br></br>Nous vous contacterons dans les plus bref délais pour
                répondre à votre demande !
              </Typography>
              <Box>
                <CheckCircleOutline color="success" sx={{ fontSize: 64 }} />
              </Box>
            </Stack>
          </CardContent>
        </Card>
      ) : (
        <Card>
          <CardContent
            component="form"
            onSubmit={handleSubmit(sendContactForm)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ my: 2 }}>
                <Grid container spacing={2} sx={{ justifyContent: "center" }}>
                  <Grid item xs={12} sx={{ textAlign: "center" }}>
                    <Typography
                      variant="h5"
                      color={errors.category ? "error" : ""}>
                      Choisissez une catégorie
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm="auto">
                    <Button
                      variant={categoryValue === 1 ? "contained" : "outlined"}
                      onClick={() => setValue("category", 1)}
                      className="max-w-fit"
                      fullWidth>
                      Question générale
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm="auto">
                    <Button
                      variant={categoryValue === 2 ? "contained" : "outlined"}
                      onClick={() => setValue("category", 2)}
                      className="max-w-fit"
                      fullWidth>
                      Assistance technique
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm="auto">
                    <Button
                      variant={categoryValue === 3 ? "contained" : "outlined"}
                      onClick={() => setValue("category", 3)}
                      className="max-w-fit"
                      fullWidth>
                      Suggestion
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm="auto">
                    <Button
                      variant={categoryValue === 4 ? "contained" : "outlined"}
                      onClick={() => setValue("category", 4)}
                      className="max-w-fit"
                      fullWidth>
                      Autre
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...register("fullName")}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label="Nom complet"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...register("email")}
                  InputLabelProps={{ shrink: true }}
                  required
                  fullWidth
                  label="Email"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...register("subject")}
                  InputLabelProps={{ shrink: true }}
                  required
                  fullWidth
                  placeholder="Sujet de la demande"
                  label="Objet"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...register("message")}
                  multiline
                  error={!!errors.message}
                  fullWidth
                  placeholder="Décrivez votre requête"
                  sx={{ minHeight: 85 }}
                  InputProps={{
                    inputComponent: TextareaAutosize,
                    inputProps: {
                      maxRows: 12,
                      minRows: 6,
                    },
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: 2, p: 0, textAlign: "center" }}>
                <LoadingButton
                  variant="outlined"
                  loading={loading}
                  sx={{ px: 8, py: 1 }}
                  endIcon={<Send />}
                  type="submit">
                  Envoyer
                </LoadingButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </Container>
  );
}
