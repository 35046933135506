import { array, object, string } from "yup";

export const YupRewardValidators = array(
  object({
    label: string().required(),
    //   customCode: yup.string().test("atleastoneofCode", (value, ctx) => {
    //     return (
    //       !!String(ctx.parent.qrcode) ||
    //       !!String(ctx.parent.barcode) ||
    //       !!String(ctx.parent.customCode)
    //     );
    //   }),
    //   barcode: yup.string().test("atleastoneofCode", (value, ctx) => {
    //     return (
    //       !!String(ctx.parent.qrcode) ||
    //       !!String(ctx.parent.barcode) ||
    //       !!String(ctx.parent.customCode)
    //     );
    //   }),
    //   qrcode: yup.string().test("atleastoneofCode", (value, ctx) => {
    //     return (
    //       !!String(ctx.parent.qrcode) ||
    //       !!String(ctx.parent.barcode) ||
    //       !!String(ctx.parent.customCode)
    //     );
    //   }),
  })
).min(1);
