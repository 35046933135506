import ActivateBusinessUser from "@/owner/ActivateBusinessUser";
import { ExitToApp, HelpCenter, Logout } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Avatar,
  CircularProgress,
  ClickAwayListener,
  Container,
  Drawer,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { signOut } from "firebase/auth";
import { FC, useState } from "react";
import {
  NavLink,
  NavigateFunction,
  Outlet,
  useNavigate,
} from "react-router-dom";
import { useAppSettingsStore } from "../../store/appSettingsStore";
import { useUserStore } from "../../store/userStore";
import { getImageUrl } from "../firebase/common";
import { firebaseAppAuth } from "../firebase/config";
import { useOwnerMenu } from "../hooks/useOwnerMenu";
import { useUserGuard } from "../hooks/userGuard";

export interface MenuNavItem {
  label: string;
  shortLabel?: string;
  color?: string;
  icon?: JSX.Element;
  to?: string;
  action?: (nav: NavigateFunction) => void;
}

const drawerWidth: number = 240;

export const userMenu: MenuNavItem[] = [
  {
    label: "Accéder a l'app",
    to: "/",
    icon: <ExitToApp />,
  },
  {
    label: "Assistance",
    to: "/owner/contact",
    icon: <HelpCenter />,
  },
  {
    label: "Déconnexion",
    action: (nav) => {
      nav("/auth");
      signOut(firebaseAppAuth);
    },
    icon: <Logout color="error" />,
    color: "error",
  },
];
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const OwnerLayout: FC = () => {
  const [isUserAuthenticated, navigate] = useUserGuard();
  const { currentPageName: pageTitle } = useAppSettingsStore();
  const nav = useNavigate();
  const ownerMenu = useOwnerMenu();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpenMenu = Boolean(anchorEl);
  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = (url?: string) => {
    setAnchorEl(null);
    if (url) {
      nav(url);
    }
  };

  const theme = useTheme();
  const greaterThanSM = useMediaQuery(theme.breakpoints.up("sm"));

  const user = useUserStore();
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  if (!isUserAuthenticated) {
    navigate();
    return null;
  }

  return (
    <Box
      id="content-root"
      sx={{
        display: "flex",
        flexFlow: "column",
        height: "100%",
        width: "100%",
      }}>
      <Box>
        <AppBar position="static">
          <Toolbar className="top-notch">
            {user.user && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: "36px",
                  ...(open && { display: "none" }),
                }}>
                <MenuIcon />
              </IconButton>
            )}
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1, textAlign: "left" }}>
              {pageTitle}
            </Typography>
            <ClickAwayListener onClickAway={() => closeMenu()}>
              <>
                <IconButton onClick={openMenu}>
                  <Avatar
                    src={
                      (user.user?.customer?.profile_picture &&
                        getImageUrl(
                          user.user?.customer?.profile_picture.key
                        )) ||
                      ""
                    }>
                    {user.user?.customer?.username?.slice(0, 2) ||
                      user.user?.customer?.firstname?.slice(0, 2)}
                  </Avatar>
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={isOpenMenu}
                  onClose={() => closeMenu()}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}>
                  {userMenu.map((item) => (
                    <MenuItem
                      sx={{ minWidth: 200 }}
                      onClick={() => {
                        if (item.action) item.action(nav);
                        closeMenu(item.to);
                      }}
                      key={item.label}>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ color: item.color }}>
                        {item.label}
                      </ListItemText>
                    </MenuItem>
                  ))}
                </Menu>
              </>
            </ClickAwayListener>
          </Toolbar>
        </AppBar>
        <Drawer anchor={"left"} open={open} onClose={() => setOpen(false)}>
          {ownerMenu.map((menus, i) => (
            <List component="nav" key={`menu-${i}`}>
              {menus.map((menu) => (
                <ListItemButton
                  component={NavLink}
                  key={menu.label}
                  sx={{
                    "&.active": {
                      background: menu.to ? "rgba(0,0,0,0.2)" : "",
                    },
                  }}
                  to={menu.to || ""}
                  onClick={() => {
                    if (menu.action) menu.action(nav);
                    toggleDrawer();
                  }}>
                  <ListItemIcon color={menu.color}>{menu.icon}</ListItemIcon>
                  <ListItemText color={menu.color} primary={menu.label} />
                </ListItemButton>
              ))}
              <Divider />
            </List>
          ))}
        </Drawer>
      </Box>
      <Box sx={{ display: "flex", flex: 1, overflow: "auto" }}>
        {user.user && user.init ? (
          user.user.businessUser ? (
            <>
              <Box>
                <Drawer
                  variant="permanent"
                  sx={{
                    height: 1,
                    display: { xs: "none", sm: "block" },
                    "& .MuiDrawer-paper": {
                      boxSizing: "border-box",
                      position: "relative",
                    },
                  }}
                  open>
                  {ownerMenu.map((menus, i) => (
                    <List component="nav" key={`menus2-${i}`}>
                      {menus.map((menu) => (
                        <ListItemButton
                          component={NavLink}
                          key={menu.label}
                          sx={{
                            "&.active": {
                              background: menu.to ? "rgba(0,0,0,0.2)" : "",
                            },
                          }}
                          to={menu.to || ""}
                          onClick={() => {
                            if (menu.action) menu.action(nav);
                          }}>
                          <ListItemIcon
                            sx={{ minWidth: { xs: "0", md: "56px" } }}
                            color={menu.color}>
                            {menu.icon}
                          </ListItemIcon>
                          <ListItemText
                            color={menu.color}
                            sx={{ display: { xs: "none", md: "block" } }}
                            primary={menu.label}
                          />
                        </ListItemButton>
                      ))}
                      <Divider />
                    </List>
                  ))}
                </Drawer>
              </Box>
              <Box sx={{ overflow: "auto", flex: 1 }}>
                <Box
                  sx={{
                    flexGrow: 1,
                    overflow: "auto",
                  }}>
                  <Outlet />
                </Box>
              </Box>
            </>
          ) : (
            <Container
              maxWidth="xs"
              sx={{ height: 1, alignItems: "center", display: "flex" }}>
              <ActivateBusinessUser />
            </Container>
          )
        ) : (
          <Box
            sx={{
              height: 1,
              width: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default OwnerLayout;
