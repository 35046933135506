import { Suspense } from "react";
import { Outlet } from "react-router";
import "./global.css";

import { useScrollToTop } from "./hooks/use-scroll-to-top";
import DashboardLayout from "./layouts/dashboard";

import ActivateBusinessUser from "@/owner/ActivateBusinessUser";
import { useUserStore } from "@/store/userStore";
import { Box, CircularProgress, Container } from "@mui/material";
import { ComponentErrorBoundary } from "../error-boundary";
import { useUserGuard } from "../hooks/userGuard";
import ThemeProvider from "./theme";

// ----------------------------------------------------------------------

export default function OwnerLayoutV2() {
  useScrollToTop();
  const user = useUserStore();
  const [isUserAuthenticated, navigate] = useUserGuard();

  if (!isUserAuthenticated) {
    navigate();
    return null;
  }

  return (
    <ThemeProvider>
      {user.user && user.init ? (
        user.user.businessUser ? (
          <DashboardLayout>
            <Suspense>
              <ComponentErrorBoundary>
                <Outlet />
              </ComponentErrorBoundary>
            </Suspense>
          </DashboardLayout>
        ) : (
          <Container
            maxWidth="xs"
            sx={{ height: 1, alignItems: "center", display: "flex" }}>
            <ActivateBusinessUser />
          </Container>
        )
      ) : (
        <Box
          sx={{
            height: 1,
            width: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <CircularProgress />
        </Box>
      )}
    </ThemeProvider>
  );
}
