import { Timestamp } from "firebase/firestore";
import { Business } from "./business";

export enum ProgramType {
  loyalty = "loyalty",
  lottery = "lottery",
}

export interface ScanFirestore<T = Timestamp> {
  business_id: string;
  //   business_ref: string;
  customer_id: string;
  //   customer_ref: string;
  program_id: string;
  program_type: ProgramType;
  created_at: T;
  collected: {
    at: T;
    id: string;
  } | null;
}

export interface Scan extends ScanFirestore {
  business?: Business;
  id: string;
}
