import { ProgramType } from "@common/index";

export function getUserQrCodeValue(userId: string) {
  return JSON.stringify({ u: userId });
}

export function getClaimQrCodeValue(
  userId: string,
  businessId: string,
  programId: string,
  programType: ProgramType
) {
  return JSON.stringify({
    u: userId,
    b: businessId,
    p: programId,
    pt: programType,
  });
}
