import { BusinessForOwner } from "@common/index";
import { Send } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Card,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import UpgradePremiumImage from "../assets/upgrade_premium.jpg";

export default function UpgradePremiumAd({
  business,
}: {
  business: BusinessForOwner;
}) {
  return (
    <ListItem
      sx={{ maxWidth: 450, pl: 1, pr: 12, textAlign: "center" }}
      component={Card}
      secondaryAction={
        <Link to={{ pathname: "/owner/billing", search: `?id=${business.id}` }}>
          <Button endIcon={<Send />} aria-label="delete">
            Par ici
          </Button>
        </Link>
      }>
      <ListItemAvatar>
        <Avatar
          sx={{ width: 82, height: 82, borderRadius: 1 }}
          variant="square"
          src={UpgradePremiumImage}></Avatar>
      </ListItemAvatar>
      <ListItemText primary="Abonnez-vous pour avoir accès à cette fonctionnalité !"></ListItemText>
    </ListItem>
  );
}
