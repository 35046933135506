import Logo from "@/assets/logo.webp";
import Background from "@assets/background3.svg";
import {
  AccountCircle,
  ArrowBackIos,
  Business,
  HelpCenter,
  Home,
  Login,
  Logout,
  Loyalty,
  QrCode,
  Search,
} from "@mui/icons-material";
import {
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
  ClickAwayListener,
  Container,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { signOut } from "firebase/auth";
import { FC, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Link,
  Outlet,
  Link as RouterLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useAppSettingsStore } from "../../store/appSettingsStore";
import { useSearchBarStore } from "../../store/searchBarStore";
import { useUserStore } from "../../store/userStore";
import BusinessSearch from "../components/BusinessSearch";
import { ComponentErrorBoundary } from "../error-boundary";
import { getImageUrl } from "../firebase/common";
import { firebaseAppAuth } from "../firebase/config";
import { MenuNavItem } from "./OwnerLayout";

const MENU: MenuNavItem[][] = [
  [
    {
      label: "Accueil",
      to: "/",
      icon: <Home />,
    },
    {
      label: "Connexion",
      to: "/auth",
      icon: <Login />,
    },
  ],
];
const SignedInMenuMobile: MenuNavItem[][] = [
  [
    {
      label: "Accueil",
      to: "/",
      icon: <Home />,
    },
    {
      label: "Ma carte",
      to: "/loyalty",
      icon: <QrCode />,
    },
    {
      label: "Mes participations",
      shortLabel: "Participations",
      to: "/programs",
      icon: <Loyalty />,
    },
    {
      label: "Mon profil",
      to: "/dashboard",
      icon: <AccountCircle />,
    },
  ],
];

function getSignedInMenu(hasBusiness: boolean) {
  const SignedInMenu: MenuNavItem[][] = [...SignedInMenuMobile];

  if (hasBusiness) {
    SignedInMenu.push(
      [
        {
          label: "Gérez vos établissements",
          to: "/owner/business/list",
          icon: <Business />,
        },
      ],
      [
        {
          label: "Déconnexion",
          action: (nav) => {
            nav("/auth");
            signOut(firebaseAppAuth);
          },
          icon: <Logout color="error" />,
          color: "error",
        },
      ]
    );
  } else {
    SignedInMenu.push(
      [
        {
          label: "Ajoutez votre établissement",
          to: "/owner/business/list",
          icon: <Business />,
        },
      ],
      [
        {
          label: "Déconnexion",
          action: (nav) => {
            nav("/auth");
            signOut(firebaseAppAuth);
          },
          icon: <Logout color="error" />,
          color: "error",
        },
      ]
    );
  }

  return SignedInMenu;
}

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const AppLayout: FC<{
  hasSearch?: boolean;
  hasHeader?: boolean;
}> = ({ hasSearch = false, hasHeader = true }) => {
  const user = useUserStore();
  const searchBarState = useSearchBarStore();
  const { currentPageName: pageTitle } = useAppSettingsStore();
  const nav = useNavigate();
  const theme = useTheme();
  const { pathname } = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [signedInMenu, setSignedInMenu] = useState<MenuNavItem[][]>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useMemo(() => {
    if (user.user?.businessUser) {
      setSignedInMenu(getSignedInMenu(true));
    } else {
      setSignedInMenu(getSignedInMenu(false));
    }
  }, [user.user]);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = (url?: string) => {
    setAnchorEl(null);
    if (url) {
      nav(url);
    }
  };

  const { register } = useForm({
    defaultValues: {
      searchInput: { description: searchBarState.result?.name || "" },
    },
  });

  const submit = () => {
    if (searchBarState.result)
      searchBarState.setResult({
        ...searchBarState.result,
      });
  };

  if (!user.init) {
    return (
      <Box
        style={{
          display: "flex",
          flexFlow: "column",
          height: "100%",
          width: "100%",
        }}>
        <Box>
          <Skeleton variant="rectangular" height={58} />
        </Box>
        <Box
          style={{
            display: "flex",
            flex: 1,
            overflow: "auto",
            position: "relative",
            background: `url(${Background}) no-repeat`,
            backgroundSize: "cover",
          }}>
          <Box sx={{ overflow: "auto", flex: 1, height: 1 }}>
            <Stack direction={"column"} spacing={2} padding={2}>
              <Skeleton variant="rectangular" sx={{ width: 1, height: 250 }} />
              <Skeleton variant="rectangular" sx={{ width: 1, height: 250 }} />
              <Skeleton variant="rectangular" sx={{ width: 1, height: 250 }} />
            </Stack>
          </Box>
        </Box>
        <Box sx={{ zIndex: 1000, display: { md: "none" } }}>
          <Grid container>
            {MENU[0].map((item) => (
              <Grid
                key={item.to}
                item
                xs
                sx={{ p: 1 }}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}>
                <Skeleton variant="circular" height={24} width={24} />
                <Skeleton variant="text" width={40} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      style={{
        display: "flex",
        flexFlow: "column",
        height: "100%",
        width: "100%",
      }}>
      <Box>
        {hasHeader ? (
          <AppBar position="static">
            <Toolbar
              prefix=""
              className="top-notch"
              sx={{
                background:
                  "linear-gradient(to right bottom, rgba(107, 10, 201, 1) 40%, rgba(62,99,233, 1) 90%)",
              }}>
              <Grid container position="relative">
                <Grid
                  sx={{ display: { xs: "none", sm: "flex" }, pr: 3 }}
                  item
                  md="auto"
                  display="flex"
                  alignItems="center">
                  <Link to="/">
                    <Avatar src={Logo}></Avatar>
                  </Link>
                </Grid>
                {pathname === "/dashboard/edit" && (
                  <Grid
                    sx={{
                      display: { xs: "flex", sm: "none" },
                      pr: 3,
                      position: "absolute",
                      top: 0,
                      alignItems: "center",
                      height: 1,
                    }}
                    item>
                    <Link to="/dashboard">
                      <IconButton>
                        <ArrowBackIos htmlColor="white" />
                      </IconButton>
                    </Link>
                  </Grid>
                )}
                <Grid item xs display="flex" alignItems="center">
                  <Container maxWidth="sm" sx={{ p: "0!important" }}>
                    {hasSearch ? (
                      <Paper
                        component="form"
                        onSubmit={submit}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          pl: "3px",
                        }}>
                        {/* <IconButton sx={{ p: "10px" }} aria-label="menu">
                        <MenuIcon />
                      </IconButton> */}
                        <BusinessSearch
                          register={register("searchInput")}
                          forwardPlace={(result) => {
                            if ("google_place_id" in result) {
                              searchBarState.setResult({
                                google_place_id: result.google_place_id,
                                lat: result.location.lat,
                                lng: result.location.lon,
                                name: result.name,
                              });
                              return;
                            }

                            if (!result.place_id) return;

                            searchBarState.setResult({
                              google_place_id: result.place_id,
                              lat: result.geometry?.location?.lat(),
                              lng: result.geometry?.location?.lng(),
                              name: result.name,
                              business_status: result.business_status,
                            });
                          }}
                          sx={{ ml: 1, flex: 1, border: 0 }}
                          label={null}
                          placeholder="Rechercher un bar, une ville ..."
                          value={searchBarState.result?.name}
                          googleMapsTypes={[]}
                          fullHeight={false}
                        />
                        <IconButton
                          type="button"
                          sx={{ p: "10px" }}
                          aria-label="search"
                          onClick={submit}>
                          <Search />
                        </IconButton>
                      </Paper>
                    ) : (
                      <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ textAlign: "center" }}>
                        {pageTitle}
                      </Typography>
                    )}
                  </Container>
                </Grid>
                <Grid
                  item
                  md="auto"
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                  sx={{ display: { xs: "none", sm: "flex" }, pl: 3 }}>
                  <ClickAwayListener onClickAway={() => closeMenu()}>
                    <>
                      {user.init ? (
                        <IconButton onClick={openMenu}>
                          <Avatar
                            src={
                              (user.user?.customer?.profile_picture &&
                                getImageUrl(
                                  user.user?.customer?.profile_picture.key
                                )) ||
                              ""
                            }>
                            {user.user?.customer?.username?.slice(0, 2) ||
                              user.user?.customer?.firstname?.slice(0, 2)}
                          </Avatar>
                        </IconButton>
                      ) : (
                        <IconButton>
                          <Skeleton
                            variant="circular"
                            sx={{ width: 45, height: 45 }}
                          />
                        </IconButton>
                      )}
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => closeMenu()}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}>
                        {(user.user ? signedInMenu : MENU).map((sub, i) => (
                          <Box key={i}>
                            {sub.map((item) => (
                              <MenuItem
                                sx={{ minWidth: 200 }}
                                onClick={() => {
                                  if (item.action) item.action(nav);
                                  closeMenu(item.to);
                                }}
                                key={item.label}
                                color="inherit">
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText
                                  primaryTypographyProps={{
                                    color: item.color,
                                  }}>
                                  {item.label}
                                </ListItemText>
                              </MenuItem>
                            ))}
                            {i <
                              (user.user ? signedInMenu : MENU).length - 1 && (
                              <Divider />
                            )}
                          </Box>
                        ))}
                        <MenuItem
                          sx={{ minWidth: 200 }}
                          component={Link}
                          to="/contact"
                          onClick={() => {
                            closeMenu();
                          }}>
                          <ListItemIcon>
                            <HelpCenter />
                          </ListItemIcon>
                          <ListItemText>Assistance</ListItemText>
                        </MenuItem>
                      </Menu>
                    </>
                  </ClickAwayListener>
                </Grid>
              </Grid>
              {/* <div style={{ flexGrow: 1 }}></div> */}
              {/* {!user.user ? (
              <Button
                component={NavLink}
                to="/auth"
                variant="outlined"
                color="inherit"
                sx={{ mt: 3, mb: 2 }}>
                Sign In
              </Button>
            ) : (
              ''
            )} */}
            </Toolbar>
          </AppBar>
        ) : (
          hasSearch && (
            <Box
              className="top-notch"
              sx={{
                position: "absolute",
                top: "15px",
                zIndex: 5,
                width: "calc(100% - 50px)",
                padding: "0 25px",
              }}>
              <Paper
                component="form"
                onSubmit={submit}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}>
                {/* <IconButton sx={{ p: "10px" }} aria-label="menu">
                        <MenuIcon />
                      </IconButton> */}
                <BusinessSearch
                  register={register("searchInput")}
                  forwardPlace={(result) => {
                    if ("google_place_id" in result) {
                      searchBarState.setResult({
                        google_place_id: result.google_place_id,
                        lat: result.location.lat,
                        lng: result.location.lon,
                        name: result.name,
                      });
                      return;
                    }

                    if (!result.place_id) return;

                    searchBarState.setResult({
                      google_place_id: result.place_id,
                      lat: result.geometry?.location?.lat(),
                      lng: result.geometry?.location?.lng(),
                      name: result.name,
                      business_status: result.business_status,
                    });
                  }}
                  sx={{ ml: 1, flex: 1, border: 0 }}
                  label={null}
                  placeholder="Rechercher un bar, une ville ..."
                  value={searchBarState.result?.name}
                  googleMapsTypes={[]}
                  fullHeight={false}
                />
                {/* <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                  onClick={submit}>
                  <Search />
                </IconButton> */}
              </Paper>
            </Box>
          )
        )}
      </Box>
      <Box
        style={{
          display: "flex",
          flex: 1,
          overflow: "auto",
          position: "relative",
          background: `url(${Background}) no-repeat`,
          backgroundSize: "cover",
        }}>
        <Box sx={{ overflow: "auto", flex: 1, height: 1 }}>
          <ComponentErrorBoundary>
            <Outlet />
          </ComponentErrorBoundary>
        </Box>
      </Box>
      {isMobile && (
        <Box
          sx={{ zIndex: 1200, background: "white" }}
          className="bottom-notch">
          <BottomNavigation
            showLabels
            // value={value}
            // onChange={(event, newValue) => {
            //   setValue(newValue);
            // }}
          >
            {(user.user ? SignedInMenuMobile : MENU).map((sub) =>
              sub.map((item) => (
                <BottomNavigationAction
                  component={RouterLink}
                  to={item.to as string}
                  key={item.label}
                  label={item.shortLabel || item.label}
                  icon={item.icon}
                />
              ))
            )}
          </BottomNavigation>
        </Box>
      )}
    </Box>
  );
};
