import { Mail } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import { ErrorBoundary as SentryBoundary } from "@sentry/react";
import { Link } from "react-router-dom";

export function ComponentErrorBoundary({
  children,
}: {
  children: JSX.Element;
}) {
  return (
    <SentryBoundary
      fallback={
        <Box
          sx={{
            height: 1,
            width: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexFlow: "column",
          }}>
          <Typography variant="body2" sx={{ mt: 1 }}>
            Une erreur s'est produite :(
          </Typography>
          <Grid container justifyContent="center" spacing={2} sx={{ mt: 2 }}>
            <Grid item>
              <Button
                component={Link}
                to={"/contact"}
                variant="outlined"
                startIcon={<Mail />}>
                Contactez nous
              </Button>
            </Grid>
          </Grid>
        </Box>
      }>
      {children}
    </SentryBoundary>
  );
}
