import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Avatar, Chip, Stack } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { debounce } from "@mui/material/utils";
import parse from "autosuggest-highlight/parse";
import { Fragment, useEffect, useMemo, useState } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { getPlaceType } from "../../owner/forms/BusinessForm";
import { AlgoliaSearchResponseHit, algoliaSearch } from "../algolia";
import { useGoogleMapApi } from "../google-map";

const autocompleteService: {
  current: google.maps.places.AutocompleteService | null;
} = { current: null };

export interface PlaceType extends google.maps.places.AutocompletePrediction {}

export default function BusinessSearch({
  register,
  forwardPlace,
  sx = {},
  label = "Nom de l'établissement",
  value: originalValue = "",
  placeholder,
  googleMapsTypes = ["cafe", "bar", "restaurant"],
  fullHeight = true,
}: {
  register: UseFormRegisterReturn;
  forwardPlace: getPlaceType;
  sx?: { [key: string]: any };
  label?: string | null;
  value?: any;
  placeholder?: string;
  googleMapsTypes?: string[];
  fullHeight?: boolean;
}) {
  const { google, placeService, status } = useGoogleMapApi();
  const [value, setValue] = useState<
    PlaceType | AlgoliaSearchResponseHit | null
  >(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<
    readonly (PlaceType | AlgoliaSearchResponseHit)[]
  >([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue({ description: originalValue } as any);
  }, [originalValue]);

  const fetch = useMemo(
    () =>
      debounce(
        async (
          request: { input: string },
          callback: (
            results: [AlgoliaSearchResponseHit[], PlaceType[] | null]
          ) => void
        ) => {
          if (!autocompleteService.current) return;
          const results = await Promise.all([
            algoliaSearch(request.input).then((response) => response.hits),
            new Promise<PlaceType[] | null>((resolve) =>
              autocompleteService.current?.getPlacePredictions(
                // https://developers.google.com/maps/documentation/javascript/supported_types?hl=fr
                {
                  ...request,
                  types: [
                    ...googleMapsTypes,
                  ] /*, componentRestrictions: {country: ''}*/,
                },
                resolve
              )
            ),
          ]);
          callback(results);
        },
        400
      ),
    []
  );

  useEffect(() => {
    let active = true;
    if (!autocompleteService.current && google) {
      autocompleteService.current =
        new google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      setLoading(false);
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      setLoading(false);
      return undefined;
    }
    setLoading(true);

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions: (PlaceType | AlgoliaSearchResponseHit)[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [
            ...newOptions,
            ...(results[0] || []),
            ...(results[1] || []),
          ];
        }
        setLoading(false);
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const getDetailsAndForward = (
    place: PlaceType | AlgoliaSearchResponseHit | null
  ) => {
    if (!place) return;

    if ("google_place_id" in place) {
      forwardPlace(place);
      return;
    }

    placeService?.getDetails({ placeId: place.place_id }, (results, status) => {
      if (status == google.maps.places.PlacesServiceStatus.OK && results) {
        forwardPlace(results);
      }
    });
  };

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) => {
        if ((option as AlgoliaSearchResponseHit).objectID) {
          return (option as AlgoliaSearchResponseHit).name as string;
        } else {
          return typeof option === "string"
            ? option
            : (option as PlaceType).structured_formatting?.main_text ||
                (option as PlaceType).description;
        }
      }}
      isOptionEqualToValue={(option, value) =>
        ((option as PlaceType).place_id ||
          (option as AlgoliaSearchResponseHit).google_place_id) ===
        ((value as PlaceType).place_id ||
          (value as AlgoliaSearchResponseHit).google_place_id)
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      autoHighlight
      includeInputInList
      filterSelectedOptions
      disableClearable={loading}
      value={value}
      loading={loading}
      onSubmit={console.log}
      noOptionsText="No locations"
      sx={{ p: 0, ...sx, marginLeft: 0 }}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        getDetailsAndForward(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        if (value && ("google_place_id" in value || "place_id" in value)) {
          setOptions([]);
        }
        setInputValue(newInputValue);
        register.onChange({
          target: { name: register.name, value: newInputValue },
        });
        setValue({ description: newInputValue } as any);
      }}
      popupIcon={""}
      renderInput={(params) => (
        <TextField
          {...params}
          {...register}
          label={label}
          placeholder={placeholder}
          fullWidth
          autoComplete={"false"}
          name="business-name"
          InputProps={{
            ...params.InputProps,
            sx: {
              p: !fullHeight ? "0!important" : "",
              // pt: "0!important",
              // pb: "0!important",
              pr: !!value && !loading ? "35px!important" : "",
            },
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} sx={{ mr: 2 }} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => {
        const algoliaType = option as AlgoliaSearchResponseHit;
        if (algoliaType.objectID) {
          return (
            <li
              {...props}
              key={algoliaType.google_place_id}
              style={{ paddingLeft: 10 }}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: "flex", width: 44 }}>
                  <Avatar src={algoliaType.cover_picture}>
                    {algoliaType.name?.slice(0, 2)}
                  </Avatar>
                </Grid>
                <Grid
                  item
                  sx={{
                    width: "calc(100% - 44px)",
                    wordWrap: "break-word",
                    pl: "6px",
                  }}>
                  <Box
                    component="span"
                    dangerouslySetInnerHTML={{
                      __html:
                        algoliaType._highlightResult?.name?.value.replaceAll(
                          "em>",
                          "b>"
                        ) || "",
                    }}></Box>
                  <Typography variant="body2" color="text.secondary">
                    {algoliaType.address.street_address},{" "}
                    {algoliaType.address.city}, {algoliaType.address.country}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }

        const placeType = option as PlaceType;
        if (
          !placeType.structured_formatting ||
          options.find(
            (o) =>
              "google_place_id" in o && o.google_place_id === placeType.place_id
          )
        ) {
          return;
        }
        const matches =
          placeType.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          placeType.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <li {...props} key={placeType.place_id}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? "bold" : "regular" }}>
                    {part.text}
                  </Box>
                ))}
                <Stack
                  direction="row"
                  spacing={1}
                  display="flex"
                  alignItems="center">
                  <Typography variant="body2" color="text.secondary">
                    {placeType.structured_formatting.secondary_text}
                  </Typography>
                  {placeType.types.includes("establishment") && (
                    <Chip
                      label="Non enregistré"
                      size="small"
                      sx={{ fontSize: 10, height: 14 }}
                    />
                  )}
                </Stack>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
