import Address from "@/common/components/Address";
import PicturePreview from "@/common/components/PicturePreview";
import { getImageUrl } from "@/common/firebase/common";
import { converter } from "@/common/firebase/converter";
import { useBusinessDocumentForOwner } from "@/common/firebase/services/owner-hooks";
import { useBusinessPermission } from "@/common/hooks/useBusinessPermission";
import { useBusinessSubscription } from "@/common/hooks/useBusinessSubscription";
import { useHasOffer } from "@/common/hooks/useHasOffer";
import NoImage from "@assets/no-image.jpg";
import { Capacitor } from "@capacitor/core";
import { FBCollections } from "@common/firebase";
import { ProgramType } from "@common/index";
import {
  Business,
  BusinessFirestore,
  BusinessForOwner,
} from "@common/model/business";
import {
  ArrowForward,
  ChevronRight,
  Discount,
  Done,
  Edit,
  ExpandMore,
  Face,
  LocalBar,
  NotInterested,
  QrCodeScanner,
  Redeem,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  ListItem,
  Modal,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { doc, Timestamp, updateDoc } from "firebase/firestore";
import { useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { KeyedMutator } from "swr";
import { BusinessOpeningHours } from "../../common/components/BusinessOpeningHours";
import { ImageUploader } from "../../common/components/ImageUploader";
import NotFound from "../../common/components/NotFound";
import { firebaseAppFirestoreDb } from "../../common/firebase/config";
import { useIsOpen } from "../../common/hooks/useIsOpen";
import {
  useAppSettingsStore,
  useSetCurrentPageName,
} from "../../store/appSettingsStore";
import { useUserStore } from "../../store/userStore";
import { QRScannerMobile } from "../QRScannerMobile";
import { QRScannerWeb } from "../QRScannerWeb";

export default function BusinessDashboard() {
  const { id } = useParams();
  const { user } = useUserStore();

  if (!id || !user) return <></>;

  return <View id={id} userId={user.uid} />;
}

const View = ({ id, userId }: { id: string; userId: string }) => {
  const { business, isLoading, mutate } = useBusinessDocumentForOwner(
    id,
    userId
  );
  useSetCurrentPageName(business?.name);
  const [openPicturePreviewIndex, setOpenPicturePreviewIndex] =
    useState<number>();
  const { subscriptionMode } = useBusinessSubscription(business);
  const [permissions] = useBusinessPermission(business);
  const { hasFeature, config } = useAppSettingsStore();
  const [open, setOpen] = useState(false);
  const [popperHover, setPopperHover] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const isOpen = useIsOpen(business);
  const { todayOffers, currentOffer } = useHasOffer(business);
  const canLottery = useMemo(() => hasFeature(ProgramType.lottery), [config]);
  const canLoyalty = useMemo(() => hasFeature(ProgramType.loyalty), [config]);

  if (isLoading) return <CircularProgress />;

  if (!business) return <NotFound />;

  const style = {
    position: "absolute",
    top: "0",
    left: "0",
    transform: "translate(0, 0)",
    width: 1,
    height: 1,
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={2.75}>
        <Grid item xs={12} sm={8}>
          <Card>
            <Grid container sx={{ width: 1 }}>
              <Grid item xs={12} sm={3} sx={{ position: "relative" }}>
                <ImageUploader
                  type="cover"
                  itemId={business.id}
                  userId={userId}
                  collection={FBCollections.Businesses}
                  callback={async (result) => {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    await updateDoc(
                      doc(
                        firebaseAppFirestoreDb,
                        FBCollections.Businesses,
                        business.id
                      ).withConverter(converter<BusinessFirestore>()),
                      {
                        cover_picture: {
                          key: result.key,
                          created_by: userId,
                          created_at: Timestamp.now(),
                        },
                      }
                    );
                    await mutate();
                  }}>
                  <CardMedia
                    component="img"
                    image={
                      (business.cover_picture &&
                        getImageUrl(business.cover_picture.key)) ||
                      NoImage
                    }
                    sx={{ width: 1, height: 200 }}
                  />
                </ImageUploader>
                <Stack
                  spacing={2}
                  sx={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    display: { sm: "none" },
                  }}>
                  <Box>
                    <Chip
                      label={isOpen ? "Ouvert" : "Fermé"}
                      color={isOpen ? "success" : "error"}
                      icon={isOpen ? <Done /> : <NotInterested />}
                    />
                  </Box>
                  <Box>
                    <Chip
                      icon={<Face />}
                      color="info"
                      label={`${business?.subscribed_customers_count} Abonnés`}
                    />
                  </Box>
                  {currentOffer && (
                    <Box>
                      <Chip
                        icon={<LocalBar />}
                        color="info"
                        label={`Promotions`}
                      />
                    </Box>
                  )}
                </Stack>
              </Grid>
              <Grid item xs>
                <CardContent>
                  <Grid container rowSpacing={2}>
                    <Grid item xs>
                      <Typography component="div" variant="h5">
                        {business?.name}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        component="div">
                        <Address address={business.address} />
                      </Typography>
                      <Link
                        to={{
                          pathname: "/business/" + business.id,
                          search: "preview=1",
                        }}>
                        <Button sx={{ mt: 2 }} variant="outlined">
                          Voir comme un client
                        </Button>
                      </Link>
                    </Grid>
                    <Grid
                      item
                      xs={"auto"}
                      sx={{ display: { xs: "none", sm: "block" } }}>
                      <Stack spacing={2}>
                        <Box>
                          <Chip
                            label={isOpen ? "Ouvert" : "Fermé"}
                            color={isOpen ? "success" : "error"}
                            icon={isOpen ? <Done /> : <NotInterested />}
                          />
                        </Box>
                        <Box>
                          <Chip
                            icon={<Face />}
                            label={`${business?.subscribed_customers_count} Abonnés`}
                          />
                        </Box>
                        {currentOffer && (
                          <Box>
                            <Chip
                              icon={<Redeem />}
                              color="info"
                              label={`Promotions`}
                            />
                          </Box>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4} sx={{ minHeight: "120px" }}>
          <Tooltip
            open={popperHover}
            title={
              !permissions.canScan
                ? !business.subscription_active && subscriptionMode
                  ? "Abonnez vous pour plus de fonctionnalités !"
                  : "Vous n'avez pas la permission de scanner"
                : !business.programs?.filteredLoyalty?.active &&
                  !business.programs?.filteredLottery?.active
                ? "Aucun programme de fidélité ou de lotterie trouvé"
                : null
            }
            arrow>
            <Box
              sx={{ height: 1 }}
              onClick={(e) => setPopperHover(!popperHover)}
              onMouseEnter={() => setPopperHover(true)}
              onMouseLeave={() => setPopperHover(false)}>
              <Button
                onClick={handleOpen}
                disabled={
                  !permissions.canScan ||
                  (!business.programs?.filteredLoyalty?.active &&
                    !business.programs?.filteredLottery?.active)
                }
                fullWidth
                variant="contained"
                sx={{ height: 1 }}>
                <QrCodeScanner fontSize="large" />
              </Button>
            </Box>
          </Tooltip>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            id="qr-scanner-modal"
            open={open}
            onClose={() => {
              handleClose();
              mutate();
            }}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}>
            <Fade in={open} id="qr-scanner-modal-fade">
              <Box sx={style} className="top-notch">
                {Capacitor.isNativePlatform() ? (
                  <QRScannerMobile
                    handleClose={() => {
                      handleClose();
                      mutate();
                    }}
                    business={business}
                  />
                ) : (
                  <QRScannerWeb
                    handleClose={() => {
                      handleClose();
                      mutate();
                    }}
                    business={business}
                  />
                )}
              </Box>
            </Fade>
          </Modal>
        </Grid>
        <Grid item xs={12} sm>
          <Card>
            <CardContent>
              <Stack direction="row">
                <Typography
                  sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}
                  color="text.secondary">
                  Nb abonnés
                </Typography>
                <Typography sx={{ fontSize: "22px" }}>
                  {business.subscribed_customers_count}
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} sm>
          <Card>
            <CardContent>
              <Stack direction="row">
                <Typography
                  sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}
                  color="text.secondary">
                  Scans (24h)
                </Typography>
                <Typography sx={{ fontSize: "22px" }}>
                  {business.stats?.scansLast24}
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} sm>
          <Card>
            <CardContent>
              <Stack direction="row">
                <Typography
                  sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}
                  color="text.secondary">
                  Scans (7j)
                </Typography>
                <Typography sx={{ fontSize: "22px" }}>
                  {business.stats?.scansLast7Days}
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography variant="h6">Informations générales</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={1}>
                <Card variant="outlined">
                  <CardHeader
                    subheader={"Description"}
                    action={
                      <IconButton component={Link} to={`../edit`}>
                        <Edit />
                      </IconButton>
                    }
                    sx={{ pb: 0 }}></CardHeader>
                  <CardContent>
                    <Typography>{business.description}</Typography>
                  </CardContent>
                </Card>
                <Card variant="outlined">
                  <CardHeader
                    subheader={"Adresse"}
                    action={
                      <IconButton component={Link} to={`../edit`}>
                        <Edit />
                      </IconButton>
                    }
                    sx={{ pb: 0 }}></CardHeader>
                  <CardContent>
                    <Typography component="p">
                      {business.address.street_address}{" "}
                      {business.address.street_address_addition
                        ? `, ${business.address.street_address_addition}`
                        : ""}
                    </Typography>
                    <Typography component="p">
                      {business.address.city} ({business.address.postal_code}),{" "}
                      {business.address.country}
                    </Typography>
                  </CardContent>
                </Card>
                <Card variant="outlined">
                  <CardHeader
                    subheader={"Heures d'ouverture"}
                    action={
                      <IconButton component={Link} to={`../edit`}>
                        <Edit />
                      </IconButton>
                    }
                    sx={{ pb: 0 }}></CardHeader>
                  <CardContent>
                    <BusinessOpeningHours business={business} />
                  </CardContent>
                </Card>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography variant="h6">Menu</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                spacing={1}
                columns={(business?.menu_pictures?.length || 0) % 4 || 4}>
                {!business?.menu_pictures?.length && (
                  <Grid item>Aucun menu ajouté</Grid>
                )}
                {business?.menu_pictures?.map((pic, i) => (
                  <Grid item key={pic.key} xs={1}>
                    <Box
                      onClick={() => setOpenPicturePreviewIndex(i)}
                      sx={{
                        maxHeight: 150,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "aliceblue",
                        cursor: "pointer",
                        overflow: "hidden",
                      }}>
                      <img
                        srcSet={`${getImageUrl(pic.key)}`}
                        src={`${getImageUrl(pic.key)}`}
                        loading="lazy"
                      />
                    </Box>
                  </Grid>
                ))}
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    justifyContent: !business?.menu_pictures?.length
                      ? "center"
                      : "end",
                  }}>
                  <Button component={Link} to="../edit#menu" variant="outlined">
                    Éditer <ChevronRight fontSize="small" />
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} md={6} sx={{ mt: 2 }}>
          <Grid container sx={{ mb: 2 }}>
            <Grid item xs>
              <ListItem
                secondaryAction={
                  <IconButton
                    component={Link}
                    to="../offer"
                    edge="end"
                    aria-label="comments">
                    <ArrowForward />
                  </IconButton>
                }
                disablePadding>
                <Typography variant="h5" component="h6">
                  Promotions
                </Typography>
              </ListItem>
            </Grid>
          </Grid>
          <Stack spacing={2}>
            {todayOffers && todayOffers.length ? (
              todayOffers.map((offer, i) => (
                <Card key={"hh" + i}>
                  <CardHeader
                    title={
                      <Grid container spacing={1}>
                        <Grid item xs="auto">
                          <Chip
                            label={`${offer.start_hour}h - ${offer.end_hour}h`}></Chip>
                        </Grid>
                        <Grid item>
                          {offer.week_days
                            .map((dayIndex) =>
                              dayjs().day(Math.max(0, dayIndex)).format("dddd")
                            )
                            .join(", ")}
                        </Grid>
                      </Grid>
                    }
                    subheader={
                      <Alert
                        icon={<Discount />}
                        sx={{
                          mt: 1,
                        }}>
                        {offer.description}
                      </Alert>
                    }
                  />
                </Card>
              ))
            ) : (
              <Box>
                <Alert severity="warning">
                  Aucune promotions pour le moment.
                </Alert>
              </Box>
            )}
          </Stack>
        </Grid>
        {canLoyalty && (
          <Grid item xs={12} md={6} sx={{ mt: 2 }}>
            <Grid container sx={{ mb: 2 }}>
              <Grid item xs>
                <ListItem
                  secondaryAction={
                    <IconButton
                      component={Link}
                      to="../loyalty"
                      edge="end"
                      aria-label="comments">
                      <ArrowForward />
                    </IconButton>
                  }
                  disablePadding>
                  <Typography variant="h5" component="h6">
                    Programme de fidélité
                  </Typography>
                </ListItem>
              </Grid>
            </Grid>
            <Stack spacing={2}>
              {business.programs?.filteredLoyalty?.active ? (
                [business.programs.filteredLoyalty.active].map((loyalty, i) => (
                  <Card key={i}>
                    <CardHeader
                      title={
                        <Grid container spacing={1}>
                          <Grid item>{loyalty.description}</Grid>
                          <Grid item>
                            <Chip label="en cours" color={"success"} />
                          </Grid>
                        </Grid>
                      }
                      subheader={
                        <Alert
                          sx={{
                            mt: 1,
                          }}>{`${loyalty.conditions.rewardItemNumber}
                      ${loyalty.conditions.rewardItemType.label} offerte a
                      partir de
                      ${loyalty.conditions.fulfillItemNumber}
                      ${loyalty.conditions.fullfillItemType.label}`}</Alert>
                      }
                    />
                  </Card>
                ))
              ) : (
                <Box>
                  <Alert severity="warning">
                    Aucun programme actuellement.
                  </Alert>
                </Box>
              )}
            </Stack>
          </Grid>
        )}
        {canLottery && (
          <Grid item xs={12} md={6} sx={{ mt: 2 }}>
            <Grid container sx={{ mb: 2 }}>
              <Grid item xs>
                <ListItem
                  secondaryAction={
                    <IconButton
                      component={Link}
                      to="../lottery"
                      edge="end"
                      aria-label="comments">
                      <ArrowForward />
                    </IconButton>
                  }
                  disablePadding>
                  <Typography variant="h5" component="h6">
                    Loteries
                  </Typography>
                </ListItem>
              </Grid>
            </Grid>
            <Stack spacing={2}>
              {business.programs?.filteredLottery.active ? (
                [business.programs.filteredLottery.active].map((lottery, i) => (
                  <Card key={i}>
                    <CardHeader
                      title={
                        <Grid container spacing={1}>
                          <Grid item>{lottery.title}</Grid>
                          <Grid item>
                            <Stack spacing={1} direction={"row"}>
                              {/* <Chip label="12 participants" color={"info"} /> */}
                              <Chip label="en cours" color={"success"} />
                            </Stack>
                          </Grid>
                        </Grid>
                      }
                      subheader={
                        <Alert
                          sx={{
                            mt: 1,
                          }}>
                          {lottery.conditions.numberOfWinnersToDraw} Gagnants
                          {lottery.conditions.limit_date &&
                            ` | Date de fin ${dayjs(
                              lottery.conditions.limit_date
                            ).format("MM/DD/YYYY - HH:mm")}`}
                        </Alert>
                      }
                    />
                    <CardContent sx={{ pt: 0 }}>
                      <Typography variant="body2" color="text.secondary">
                        {lottery.description}
                      </Typography>
                    </CardContent>
                  </Card>
                ))
              ) : (
                <Box>
                  <Alert severity="warning">
                    Aucune loterie pour le moment.
                  </Alert>
                </Box>
              )}
            </Stack>
          </Grid>
        )}
        <Grid item xs={12} md={6} sx={{ mt: 2 }}>
          <Grid container sx={{ mb: 2 }}>
            <Grid item xs>
              <ListItem
                secondaryAction={
                  <IconButton
                    component={Link}
                    to="../events"
                    edge="end"
                    aria-label="events">
                    <ArrowForward />
                  </IconButton>
                }
                disablePadding>
                <Typography variant="h5" component="h6">
                  Évènements
                </Typography>
              </ListItem>
            </Grid>
          </Grid>
          <Stack spacing={1}>
            {business.events?.today && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                  <Stack spacing={1} direction={"row"}>
                    <Typography variant="h6">
                      {business.events.today.title}
                    </Typography>
                    <Chip label={"Aujourd'hui"} color="success" />
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{business.events.today.description}</Typography>
                </AccordionDetails>
              </Accordion>
            )}
            {business.events?.future.length ? (
              business.events.future.map((event, i) => {
                const dayjsDate = dayjs(event.date_start.toDate()).utcOffset(
                  business.utc_offset_minutes
                );
                return (
                  <Accordion key={i}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header">
                      <Stack spacing={1} direction={"row"}>
                        <Typography variant="h6">{event.title}</Typography>
                        <Chip
                          label={dayjsDate.format("MM/DD/YYYY - HH:mm")}
                          color="success"
                        />
                        <Chip
                          label={dayjsDate.fromNow()}
                          color={dayjsDate.isAfter() ? "info" : "warning"}
                        />
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{event.description}</Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              })
            ) : (
              <Alert severity="warning">Aucun évènement à venir.</Alert>
            )}
          </Stack>
        </Grid>
      </Grid>
      {openPicturePreviewIndex !== undefined && (
        <PicturePreview
          pictureList={
            business?.menu_pictures?.map((pic) => getImageUrl(pic.key)) || []
          }
          activeIndex={openPicturePreviewIndex}
          setActiveIndex={setOpenPicturePreviewIndex}
        />
      )}
    </>
  );
};

export interface BusinessFormComponentProps {
  business: Business;
  handleClose: () => void;
  mutate: KeyedMutator<BusinessForOwner>;
}

export function ComboBox({ control, path, error, ...props }: any) {
  return (
    <ControlledAutocomplete
      name={path}
      control={control}
      freeSolo
      forcePopupIcon
      id="combo-box-demo"
      renderInput={(params: any) => <TextField {...params} error={error} />}
      {...props}
    />
  );
}

const ControlledAutocomplete = ({
  options = [],
  renderInput,
  getOptionLabel,
  onChange: ignored,
  control,
  defaultValue,
  name,
  renderOption,
}: any) => {
  return (
    <Controller
      render={({ field: { onChange, ...props } }) => (
        <Autocomplete
          options={options}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          renderInput={renderInput}
          onChange={(e, data) => onChange(data)}
          {...props}
        />
      )}
      // onChange={([, data]) => data}
      defaultValue={defaultValue}
      name={name}
      control={control}
    />
  );
};
