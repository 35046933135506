import { Logger } from "@/common/error-handling";
import { fileUpload } from "@/common/firebase/common";
import { firebaseAppFirestoreDb } from "@/common/firebase/config";
import { converter } from "@/common/firebase/converter";
import { useBusinessCollection } from "@/common/firebase/services/owner-hooks";
import { getBusinessByIdForOwner } from "@/common/firebase/services/owner-requests";
import { useBusinessSubscription } from "@/common/hooks/useBusinessSubscription";
import { useSetCurrentPageName } from "@/store/appSettingsStore";
import { useUserStore } from "@/store/userStore";
import { FBCollections } from "@common/firebase";
import { Business, BusinessFirestore } from "@common/index";
import { CheckCircle } from "@mui/icons-material";
import {
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { geohashForLocation } from "geofire-common";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import BusinessForm, { BusinessFormData } from "./forms/BusinessForm";
import ProductCarrousel from "./forms/ProductCarrousel";

//& google.maps.places.PlaceOpeningHoursPeriod
export default function BusinessCreate() {
  useSetCurrentPageName("Ajouter un établissement");

  const userState = useUserStore();
  const navigate = useNavigate();
  const { mutate: mutateCollection } = useBusinessCollection(
    userState.user?.uid as string
  );
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [business, setBusiness] = useState<Business | null>(null);

  const { subscriptionMode } = useBusinessSubscription(undefined);

  const createBusiness = async (
    formData: BusinessFormData,
    selectedImage: File | null
  ) => {
    try {
      setLoading(true);
      const hash = geohashForLocation([
        formData.location.lat,
        formData.location.lon,
      ]);
      const data: BusinessFirestore = {
        ...formData,
        geohash: hash,
        subscription_active: false,
        status: "draft",
        created: Timestamp.now(),
        lt: Date.now(),
      };
      const existingDocs = await getDocs(
        query(
          collection(firebaseAppFirestoreDb, "businesses"),
          where("google_place_id", "==", formData.google_place_id)
        )
      );

      if (!existingDocs.empty) {
        setLoading(false);
        Logger.error("Cet établissement existe déjà!", data);
        return;
      }

      const docRef = await addDoc(
        collection(firebaseAppFirestoreDb, "businesses"),
        data
      );

      await setDoc(
        doc(
          firebaseAppFirestoreDb,
          FBCollections.Businesses,
          docRef.id,
          "users_permissions",
          userState.user?.uid as string
        ),
        {
          id: userState.user?.uid as string,
          role: "Owner",
        }
      );

      if (selectedImage) {
        await fileUpload(
          selectedImage,
          FBCollections.Businesses,
          docRef.id,
          userState.user!.uid,
          (progress) => {},
          (loading) => {},
          async (url) => {
            await updateDoc(
              doc(
                firebaseAppFirestoreDb,
                FBCollections.Businesses,
                docRef.id
              ).withConverter(converter<BusinessFirestore>()),
              {
                cover_picture: {
                  key: url.key,
                  created_at: Timestamp.now(),
                  created_by: userState.user!.uid,
                },
              }
            );
            created(docRef.id);
          },
          (error) => {
            Logger.error(error);
            setLoading(false);
          },
          "cover"
        );
      } else {
        created(docRef.id);
      }
    } catch (e) {
      Logger.error(e);
      setLoading(false);
    }
  };

  const created = async (id: string) => {
    // navigate(`/owner/business/${id}`);

    const business = await getBusinessByIdForOwner(
      id,
      userState.user?.uid as string
    );
    setBusiness(business);
    mutateCollection();
    setLoading(false);
    setActiveStep(activeStep + 1);
    // Logger.success("Établissement créé");
  };

  return (
    <Container component="main" disableGutters>
      <CssBaseline />
      <Stepper alternativeLabel activeStep={activeStep}>
        {/* {steps.map((label) => ( */}
        <Step>
          <StepLabel>Informations</StepLabel>
        </Step>
        <Step>
          <StepLabel>Paiement</StepLabel>
        </Step>
        {/* ))} */}
      </Stepper>
      {activeStep === 0 && (
        <BusinessForm
          loading={loading}
          submit={async (data, image) => {
            await createBusiness(data, image);
          }}>
          Créer l'établissement
        </BusinessForm>
      )}
      {activeStep === 1 &&
        business &&
        (subscriptionMode ? (
          <ProductCarrousel
            business={business}
            disableFreePlan={false}
            from="create"
          />
        ) : (
          <Grid container textAlign="center" spacing={4} sx={{ mt: 2 }}>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <CheckCircle color="success" sx={{ fontSize: 48 }} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography variant="h6">
                Votre établissement a été créé en mode brouillon !
              </Typography>
              <Typography>
                Cliquez sur le bouton ci dessous pour le personnaliser et le
                publier.
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Button
                variant="contained"
                component={Link}
                to={"/owner/business/" + business?.id}>
                Accédez au tableau de bord
              </Button>
            </Grid>
          </Grid>
        ))}
    </Container>
  );
}
