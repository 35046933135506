import { Loader } from "@googlemaps/js-api-loader";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

type googleApiStatus = "idle" | "loading" | "ready";

type ContextType = {
  setGoogleApi: Dispatch<SetStateAction<typeof google>>;
  setPlaceService: Dispatch<
    SetStateAction<google.maps.places.PlacesService | undefined>
  >;
  setStatus: Dispatch<SetStateAction<googleApiStatus>>;
  googleApi: any;
  placeService: google.maps.places.PlacesService | undefined;
  status: googleApiStatus;
};

export const CounterContext = createContext<ContextType>({
  setGoogleApi: () => {},
  setPlaceService: () => {},
  setStatus: () => {},
  status: "idle",
  googleApi: undefined,
  placeService: undefined,
});

export const CounterContextProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [googleApi, setGoogleApi] = useState<any>();
  const [placeService, setPlaceService] =
    useState<google.maps.places.PlacesService>();
  const [status, setStatus] = useState<googleApiStatus>(
    window.google ? "ready" : "idle"
  );

  return (
    <CounterContext.Provider
      value={{
        status,
        setStatus,
        placeService,
        setPlaceService,
        googleApi,
        setGoogleApi,
      }}>
      {children}
    </CounterContext.Provider>
  );
};

export function useGoogleMapApi(): {
  google: any;
  placeService: google.maps.places.PlacesService | undefined;
  status: googleApiStatus;
} {
  const context = useContext(CounterContext);

  useEffect(() => {
    if (!context.googleApi && context.status !== "loading") {
      const loader = new Loader({
        apiKey: import.meta.env.VITE_MAPS_API_KEY,
        version: "weekly",
        libraries: ["places"],
      });
      context.setStatus("loading");
      console.info("Loading google maps api");
      loader
        .load()
        .then((google) => {
          window.google = google;
          context.setGoogleApi(google);
          context.setPlaceService(
            new google.maps.places.PlacesService(document.createElement("div"))
          );
        })
        .finally(() => {
          context.setStatus("ready");
        });
    }
  }, []);

  return {
    google: context.googleApi,
    placeService: context.placeService,
    status: context.status,
  };
}
