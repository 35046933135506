import { CurrencyMap } from "@/common/common";
import { Logger } from "@/common/error-handling";
import {
  createSubscriptionCheckout,
  useGetProductsPrice,
} from "@/common/firebase/services/stripe-requests";
import { useUserStore } from "@/store/userStore";
import { Capacitor } from "@capacitor/core";
import { Business } from "@common/index";
import { Check } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";

export default function ProductCarrousel({
  business,
  subscription,
  disableFreePlan,
  from,
}: {
  business: Business;
  from: "billing" | "create";
  subscription?: any;
  disableFreePlan: boolean;
}) {
  const { collection: productsCollections } = useGetProductsPrice();
  const [isCheckoutCreatingId, setIsCheckoutCreatingId] = useState<
    string | null
  >(null);
  const userState = useUserStore();
  const navigate = useNavigate();

  async function createCheckout(business: Business, priceId: string | null) {
    setIsCheckoutCreatingId(priceId);

    if (!priceId) {
      navigate({
        pathname: "/owner/business/" + business.id,
      });
      return;
    }

    await createSubscriptionCheckout(
      userState.user?.uid as string,
      business,
      priceId,
      from === "billing" ? "/owner/billing" : "/owner/business/" + business.id,
      from === "billing" ? "/owner/billing" : "/owner/business/" + business.id,
      (hasError) => {
        const isNative = Capacitor.isNativePlatform();

        if (!hasError && isNative) {
          navigate("/owner/business/" + business.id);
          Logger.success("Merci pour votre paiement !");
        }
        // setTimeout(() => {
        //   mutate();
        // }, 3000);

        setIsCheckoutCreatingId(null);
      }
    );
  }

  return (
    <Box display="flex" sx={{ pt: 4, width: 1 }}>
      <Grid
        container
        spacing={5}
        alignItems="flex-end"
        justifyContent={"center"}>
        {productsCollections
          ?.sort((a: any, b: any) => a.price.unit_amount - b.price.unit_amount)
          .map((product: any) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={product.id}
              xs={12}
              sm={6}
              md={4}
              sx={{ height: { xs: "auto", sm: 1 } }}>
              <Card
                sx={{
                  height: 1,
                  display: "flex",
                  flexFlow: "column",
                }}>
                <CardHeader
                  title={product.name}
                  subheader={product.description}
                  titleTypographyProps={{ align: "center" }}
                  subheaderTypographyProps={{
                    align: "center",
                  }}
                  action={
                    (!subscription && product.price.unit_amount === 0) ||
                    product.price.id === subscription?.price.id ? (
                      <Check />
                    ) : (
                      ""
                    )
                  }
                  sx={{
                    backgroundColor: (theme) =>
                      (!subscription && product.price.unit_amount === 0) ||
                      product.price.id === subscription?.price.id
                        ? theme.palette.primary.light
                        : theme.palette.grey[200],
                  }}
                />
                <CardContent sx={{ flex: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                      mb: 2,
                    }}>
                    {product.price.unit_amount ? (
                      <>
                        <Typography
                          component="h2"
                          variant="h4"
                          color="text.primary">
                          {CurrencyMap[product.price.currency as "eur"]}
                          {Number(product.price.unit_amount / 100).toFixed(2)}
                        </Typography>
                        <Typography variant="h6" color="text.secondary">
                          /mo
                        </Typography>
                      </>
                    ) : (
                      <Typography
                        component="h2"
                        variant="h4"
                        color="text.primary">
                        Gratuit
                      </Typography>
                    )}
                  </Box>
                  <ul>
                    {Object.keys(product.metadata)
                      .sort((a: any, b: any) => a - b)
                      .map((key: any) => (
                        <Typography
                          component="li"
                          variant="subtitle1"
                          key={product.metadata[key]}>
                          {product.metadata[key]}
                        </Typography>
                      ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    loading={isCheckoutCreatingId === product.price.id}
                    disabled={
                      !!isCheckoutCreatingId ||
                      (disableFreePlan && product.price.unit_amount === 0) ||
                      product.price.id === subscription?.price.id
                    }
                    onClick={() =>
                      createCheckout(
                        business,
                        product.price.unit_amount === 0
                          ? null
                          : product.price.id
                      )
                    }>
                    Sélectionner ce plan
                  </LoadingButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}
