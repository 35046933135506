import { ComponentErrorBoundary } from "@/common/error-boundary";
import { useBusinessDocumentForOwner } from "@/common/firebase/services/owner-hooks";
import { archiveBusinessLoyalty } from "@/common/firebase/services/owner-requests";
import { useBusinessPermission } from "@/common/hooks/useBusinessPermission";
import { ProgramLoyalty } from "@common/model/business";
import { MoreVert } from "@mui/icons-material";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Fade,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import "dayjs/locale/fr";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { AppModal } from "../../common/components/AppModal";
import { useUserStore } from "../../store/userStore";
import UpgradePremiumAd from "../PremiumAd";
import { LoyaltyForm } from "../forms/LoyaltyForm";

const style = {
  position: "absolute",
  top: "0",
  left: "0",
  transform: "translate(0, 0)",
  width: 1,
  height: 1,
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function BusinessLoyaltyPrograms() {
  const { id } = useParams();
  const { user } = useUserStore();

  if (!id || !user) return <></>;

  return <View id={id} userId={user.uid} />;
}

function View({ id, userId }: { id: string; userId: string }) {
  const { business, isLoading, mutate } = useBusinessDocumentForOwner(
    id,
    userId
  );
  const [permissions] = useBusinessPermission(business);

  const [openLoyalty, setOpenLoyalty] = useState(false);
  const [editingLoyalty, setEditingLoyalty] = useState<ProgramLoyalty | null>(
    null
  );
  const handleOpenLoyalty = (loyalty?: ProgramLoyalty) => {
    handleClose();
    setEditingLoyalty(loyalty || null);
    setOpenLoyalty(true);
  };
  const handleCloseLoyalty = () => {
    setOpenLoyalty(false);
    setEditingLoyalty(null);
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const archiveProgram = async (loyalty: ProgramLoyalty) => {
    if (!business) {
      return;
    }
    handleClose();
    await archiveBusinessLoyalty(business, loyalty.id);
    mutate();
  };

  if (!business) return <></>;

  return (
    <ComponentErrorBoundary>
      <Grid container>
        <Grid item xs sx={{ mt: 2 }}>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openLoyalty}
            onClose={handleCloseLoyalty}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}>
            <Fade in={openLoyalty}>
              <Box sx={style}>
                <AppModal
                  title="Créer un programme de fidélité"
                  handleClose={handleCloseLoyalty}>
                  <LoyaltyForm
                    business={business}
                    loyalty={editingLoyalty || undefined}
                    handleClose={handleCloseLoyalty}
                    mutate={mutate}
                  />
                </AppModal>
              </Box>
            </Fade>
          </Modal>
          <Stack spacing={2}>
            {!permissions.canEditPrograms && (
              <Box display="flex" justifyContent="center" sx={{ p: 4 }}>
                <UpgradePremiumAd business={business} />
              </Box>
            )}
            {business.programs?.filteredLoyalty?.active ? (
              [business.programs.filteredLoyalty.active].map((loyalty, i) => (
                <Card key={i}>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}>
                    <MenuItem
                      onClick={() => handleOpenLoyalty(loyalty)}
                      disabled={!permissions.canEditPrograms}>
                      Éditer
                    </MenuItem>
                    <MenuItem onClick={() => archiveProgram(loyalty)}>
                      Archiver
                    </MenuItem>
                  </Menu>
                  <CardHeader
                    action={
                      <IconButton
                        aria-label="settings"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}>
                        <MoreVert />
                      </IconButton>
                    }
                    title={
                      <Grid container spacing={1}>
                        <Grid item>{loyalty.description}</Grid>
                        <Grid item>
                          <Chip label="en cours" color={"success"} />
                        </Grid>
                      </Grid>
                    }
                    subheader={
                      <Alert
                        sx={{
                          mt: 1,
                        }}>{`${loyalty.conditions.rewardItemNumber}
                      ${loyalty.conditions.rewardItemType.label} offerte a
                      partir de
                      ${loyalty.conditions.fulfillItemNumber}
                      ${loyalty.conditions.fullfillItemType.label}`}</Alert>
                    }
                  />
                </Card>
              ))
            ) : (
              <>
                <Alert severity="warning">Aucun programme actuellement.</Alert>
                <Box textAlign={"center"}>
                  <Button
                    variant="contained"
                    onClick={() => handleOpenLoyalty()}
                    disabled={!permissions.canEditPrograms}>
                    Créer un programme
                  </Button>
                </Box>
              </>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography variant="h5">Historique</Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Stack spacing={2}>
            {business.programs?.filteredLoyalty?.archived?.length ? (
              business.programs.filteredLoyalty?.archived.map((loyalty, i) => (
                <Card key={i}>
                  <CardHeader
                    title={
                      <Grid container spacing={1}>
                        <Grid item>{loyalty.description}</Grid>
                        {/* <Grid item>
                        <Chip label="en cours" color={"success"} />
                      </Grid> */}
                      </Grid>
                    }
                    subheader={
                      <Alert
                        sx={{
                          mt: 1,
                        }}>{`${loyalty.conditions.rewardItemNumber}
                      ${loyalty.conditions.rewardItemType.label} offerte a
                      partir de
                      ${loyalty.conditions.fulfillItemNumber}
                      ${loyalty.conditions.fullfillItemType.label}`}</Alert>
                    }
                  />
                </Card>
              ))
            ) : (
              <>
                <Alert severity="warning">Aucun programme archivé.</Alert>
              </>
            )}
          </Stack>
        </Grid>
      </Grid>
    </ComponentErrorBoundary>
  );
}
