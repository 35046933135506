import { ComponentErrorBoundary } from "@/common/error-boundary";
import { Logger } from "@/common/error-handling";
import {
  createBusinessLoyalty,
  updateBusinessLoyalty,
} from "@/common/firebase/services/owner-requests";
import { ProgramLoyalty } from "@common/model/business";
import { ProgramType } from "@common/model/scan";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Grid,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { LocalizationProvider, TimeField } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { Timestamp } from "firebase/firestore";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { number, object, string } from "yup";
import {
  BusinessFormComponentProps,
  ComboBox,
} from "../tabs/BusinessDashboard";
import { YupRewardValidators } from "./utils";

const schema = object<ProgramLoyalty>({
  description: string().required(),
  conditions: object({
    fulfillItemNumber: number().min(1),
    fullfillItemType: object({
      label: string().required(),
      value: number(),
    }).required(),
    rewardItemNumber: number().min(1),
    rewardItemType: object({
      label: string().required(),
      value: number(),
    }).required(),
  }),
  reward: YupRewardValidators,
});

interface LoyaltyFormProps extends BusinessFormComponentProps {
  loyalty?: ProgramLoyalty;
}

export const LoyaltyForm = ({
  business,
  loyalty,
  handleClose,
  mutate,
}: LoyaltyFormProps) => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    control,
  } = useForm<ProgramLoyalty>({
    resolver: yupResolver(schema as any),
    defaultValues: {
      ...loyalty,
      type: ProgramType.loyalty,
      conditions: {
        fulfillItemNumber: loyalty?.conditions.fulfillItemNumber || 1,
        fullfillItemType: loyalty?.conditions.fullfillItemType || items[0],
        rewardItemNumber: loyalty?.conditions.rewardItemNumber || 1,
        rewardItemType: loyalty?.conditions.rewardItemType || itemsReward[0],
        interval: loyalty?.conditions.interval || 120,
      },
      created_at: loyalty?.created_at || Timestamp.now(),
      updated_at: Timestamp.now(),
      archived_at: loyalty?.archived_at || null,
    },
  });

  const intervalValue = useMemo(() => {
    const date = new Date();
    date.setHours(Math.floor(control._formValues.conditions.interval / 60));
    date.setMinutes(control._formValues.conditions.interval % 60);

    return dayjs(date);
  }, [control, control._formValues.conditions.interval]);

  const createLoyalty = async (formData: ProgramLoyalty) => {
    try {
      setLoading(true);
      if (loyalty) {
        await updateBusinessLoyalty(business, loyalty.id, formData);
        Logger.success("Programme mis à jour");
      } else {
        await createBusinessLoyalty(business.id, formData);
        Logger.success("Programme mis à jour");
      }
      mutate();
      setLoading(false);
      handleClose();
    } catch (e) {
      setLoading(false);
      Logger.error(e);
    }
  };

  return (
    <ComponentErrorBoundary>
      <Box
        component="form"
        onSubmit={handleSubmit(createLoyalty)}
        noValidate
        sx={{ mt: 1 }}>
        <Typography variant="h5" sx={{ mb: 2, mt: 2 }}>
          Description
        </Typography>
        <TextField
          {...register("description")}
          multiline
          rows={3}
          error={!!errors.description}
          fullWidth
          placeholder="Gagne 1 verre gratuit au bout de 5 consos !"
          sx={{ minHeight: 45 }}
          required
          InputProps={{
            inputComponent: TextareaAutosize,
            inputProps: {
              maxRows: 6,
              minRows: 4,
            },
          }}
        />
        <Typography variant="h5" sx={{ mb: 2, mt: 2 }}>
          Conditions
        </Typography>
        <Alert severity="info" sx={{ mb: 2 }}>
          {loyalty
            ? "Les conditions du programme ne sont pas éditable."
            : "Définissez les conditions du programme ainsi que les récompenses."}
        </Alert>
        {loyalty ? (
          <Alert
            sx={{
              mt: 1,
            }}>{`${loyalty.conditions.rewardItemNumber}
                      ${loyalty.conditions.rewardItemType.label} offerte a
                      partir de
                      ${loyalty.conditions.fulfillItemNumber}
                      ${loyalty.conditions.fullfillItemType.label}`}</Alert>
        ) : (
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={"auto"}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <Typography sx={{ fontStyle: "italic" }}>A partir de</Typography>
            </Grid>
            <Grid item xs={"auto"}>
              <Select
                {...register("conditions.fulfillItemNumber")}
                defaultValue={
                  control._defaultValues.conditions?.fulfillItemNumber
                }
                id="demo-simple-select">
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
              </Select>
            </Grid>
            <Grid item xs>
              <ComboBox
                control={control}
                path="conditions.fullfillItemType"
                options={items}
                error={!!errors.conditions?.fullfillItemType}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={"auto"}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <Typography sx={{ fontStyle: "italic" }}>Offrir</Typography>
            </Grid>
            <Grid item xs={"auto"}>
              <Select
                {...register("conditions.rewardItemNumber")}
                defaultValue={
                  control._defaultValues.conditions?.rewardItemNumber
                }
                id="demo-simple-select">
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
              </Select>
            </Grid>
            <Grid item xs>
              <ComboBox
                control={control}
                path="conditions.rewardItemType"
                options={itemsReward}
                error={!!errors.conditions?.rewardItemType}
              />
            </Grid>
          </Grid>
        )}
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ mb: 2, mt: 2 }}>
              Limite de participation
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 2, mt: 2 }}>
              Détermine à quelle fréquence un utilitsateur peut scanner sa carte
              de fidélité. (toutes les 2 heures par défaut)
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider
              adapterLocale={"fr"}
              dateAdapter={AdapterDayjs}>
              <TimeField
                label="Intervalle entre deux scans"
                format="HH:mm"
                defaultValue={dayjs(control._formValues.start_hour)}
                value={intervalValue}
                onChange={(e: Dayjs | null) => {
                  if (!e) {
                    return;
                  }

                  setValue("conditions.interval", e.hour() * 60 + e.minute());
                }}
                sx={{ width: 1 }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ mb: 2, mt: 2 }}>
              Informations techniques
            </Typography>
            {/* <Alert severity="info">
            Ici vous pouvez ajouter un code pour identifier un produit de votre
            inventaire pour simplifier la livraison des récompenses à la caisse.
          </Alert> */}
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register("reward.0.label")}
              margin="normal"
              fullWidth
              id="rewardLabel"
              label="Nom de la récompense"
              placeholder="1 Verre offert"
              required
              error={!!errors?.reward?.[0]?.label}
            />
            {/* <TextField
            {...register("reward.customCode")}
            margin="normal"
            fullWidth
            id="customCode"
            label="Code personnalisé"
            placeholder="YYYYY"
            error={!!errors?.reward?.customCode}
          />
          <TextField
            {...register("reward.barcode")}
            margin="normal"
            type="number"
            fullWidth
            id="barcode"
            label="Code barre"
            placeholder="00000000000000000"
            error={!!errors?.reward?.barcode}
          />
          <TextField
            {...register("reward.qrcode")}
            margin="normal"
            fullWidth
            id="qrcode"
            label="QRCode"
            placeholder="X1X1X1X1X1X1"
            error={!!errors?.reward?.qrcode}
          /> */}
          </Grid>
        </Grid>

        <LoadingButton
          type="submit"
          loading={loading}
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}>
          Sauvegarder
        </LoadingButton>
      </Box>
    </ComponentErrorBoundary>
  );
};

const items = [
  { label: "Consomation", value: 1 },
  { label: "Facture", value: 2 },
  { label: "Bouteille", value: 3 },
  { label: "Verre", value: 4 },
];

const itemsReward = [
  { label: "Consomation", value: 1 },
  { label: "Facture", value: 2 },
  { label: "Bouteille", value: 3 },
  { label: "Verre", value: 4 },
  { label: "% de réduction sur la commande", value: 5 },
  { label: "$ de réduction sur la commande", value: 6 },
];
