import { BusinessForOwner } from "@common/index";
import { useAppSettingsStore } from "../../store/appSettingsStore";

export const useBusinessSubscription = (
  business: BusinessForOwner | undefined
): { subscriptionActive: boolean; subscriptionMode: boolean } => {
  const { config } = useAppSettingsStore();

  return {
    subscriptionMode: config?.subscriptionMode === "subscription",
    subscriptionActive: !!business?.subscription_active,
  };
};
