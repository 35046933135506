import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

export const storageKey = "redirectUrl";

export const useRedirectUrl = (): {
  getCurrentRedirectUrl: Function;
  redirectFromCache: Function;
} => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  function getCurrentRedirectUrl() {
    const redirectUrl = searchParams.get(storageKey);
    if (redirectUrl) {
      return redirectUrl;
    }
  }

  function redirectFromCache() {
    const redirectUrl = searchParams.get(storageKey);
    if (redirectUrl) {
      localStorage.removeItem(storageKey);
      navigate(redirectUrl);
      return true;
    }
  }

  return { getCurrentRedirectUrl, redirectFromCache };
};
