import { ComponentErrorBoundary } from "@/common/error-boundary";
import { getImageUrl } from "@/common/firebase/common";
import { useBusinessDocumentForPublic } from "@/common/firebase/services/user-hooks";
import { ScannedBusinessFirestore } from "@common/index";
import {
  Alert,
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Stack,
} from "@mui/material";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { useSetCurrentPageName } from "../store/appSettingsStore";
import { AppUser, useUserStore } from "../store/userStore";
import { LotteryView, LoyaltyView } from "./BusinessView";

export default function Programs() {
  useSetCurrentPageName("Mes participations");
  const userState = useUserStore();

  if (!userState.user) {
    return (
      <Container component="main" maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography component="p">
          Pour acceder a nos super promos{" "}
          <Link to="/auth">cre toi un compte ou connecte toi ici</Link>
        </Typography>
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="lg" sx={{ overflow: "unset", p: 2 }}>
      <Grid container spacing={2}>
        {!userState.user.customer?.scanned_businesses.length && (
          <Grid item xs={12} sx={{ justifyContent: "center" }}>
            <Card>
              <CardContent>
                Faites scanner votre QR code par un membre du personnel d'un
                établissement partenaire lors de vos visites, pour voir
                apparaitre vos participations sur cette page.
              </CardContent>
            </Card>
          </Grid>
        )}
        {userState.user.customer?.scanned_businesses.map((sb) => (
          <ProgramCard
            key={sb.id}
            scannedBusiness={sb}
            user={userState.user!}
          />
        ))}
      </Grid>
    </Container>
  );
}
const ProgramCard = ({
  scannedBusiness,
  user,
}: {
  scannedBusiness: ScannedBusinessFirestore;
  user: AppUser;
}) => {
  const { business, isLoading } = useBusinessDocumentForPublic(
    scannedBusiness.ref.id
  );
  const loyaltyProgram = useMemo(() => {
    return (
      business?.programs?.filteredLoyalty.active ||
      business?.programs?.filteredLoyalty.archived[0]
    );
  }, [business]);
  const lotteryProgram = useMemo(() => {
    return (
      business?.programs?.filteredLottery.active ||
      business?.programs?.filteredLottery.archived[0]
    );
  }, [business]);

  if (!business?.programs) {
    return;
  }

  return (
    <ComponentErrorBoundary>
      <Grid item xs={12} sm={6}>
        <Card
          sx={{
            border: 0,
            boxShadow: 0,
            color: "common.white",
            backgroundImage: "url('" + business?.cover_picture + "')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            opacity: business?.programs?.filteredLoyalty.active ? 1 : 0.6,
          }}>
          <CardActionArea
            component={Link}
            sx={{ height: 1 }}
            to={`/business/${business?.id}`}>
            <CardContent
              sx={{
                background:
                  "linear-gradient(to right bottom, rgba(107, 10, 201, 1) 20%, rgba(62,99,233, 0.7) 75%)",
              }}>
              <Typography
                variant="h6"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "common.white",
                }}>
                <Avatar
                  sx={{ marginRight: 2.5 }}
                  src={
                    business?.cover_picture &&
                    getImageUrl(business?.cover_picture.key)
                  }>
                  {business?.name.slice(0, 2)}
                </Avatar>
                {business?.name}
              </Typography>
              {loyaltyProgram && (
                <Box sx={{ py: 2 }}>
                  {lotteryProgram && (
                    <Typography variant="subtitle2">Fidélité</Typography>
                  )}
                  <Stack spacing={2}>
                    <Box>
                      <Alert severity="info">
                        Obtiens {loyaltyProgram.conditions.rewardItemNumber}{" "}
                        {loyaltyProgram.conditions.rewardItemType.label}{" "}
                        gratuite au bout de{" "}
                        {loyaltyProgram.conditions.fulfillItemNumber}{" "}
                        {loyaltyProgram.conditions.fullfillItemType.label}.
                      </Alert>
                    </Box>
                    <LoyaltyView
                      business={business}
                      program={loyaltyProgram}
                      userId={user.uid}
                    />
                  </Stack>
                </Box>
              )}
              {lotteryProgram && (
                <Box sx={{ py: 2 }}>
                  {loyaltyProgram && (
                    <Typography variant="subtitle2">Lotterie</Typography>
                  )}
                  <Stack spacing={2}>
                    <Box>
                      <Alert severity="success">
                        {lotteryProgram.title} -{" "}
                        {lotteryProgram.conditions.numberOfWinnersToDraw}{" "}
                        Gagnant(s)
                        {!lotteryProgram.archived_at &&
                          lotteryProgram.conditions.limit_date &&
                          ` | Tirage le ${dayjs(
                            lotteryProgram.conditions.limit_date
                          ).format("MM/DD/YYYY - HH:mm")}`}
                        {lotteryProgram.archived_at &&
                          ` | Tirée le ${dayjs(
                            lotteryProgram.archived_at.toDate()
                          ).format("MM/DD/YYYY - HH:mm")}`}
                      </Alert>
                    </Box>
                    <LotteryView
                      businessId={business.id}
                      program={
                        business.programs.filteredLottery.active ||
                        business.programs.filteredLottery.archived[0]
                      }
                      userId={user.uid}
                    />
                  </Stack>
                </Box>
              )}
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </ComponentErrorBoundary>
  );
};
