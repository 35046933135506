import { Logger } from "@/common/error-handling";
import { registerNewUser } from "@/common/firebase/services/user-requests";
import { useRedirectUrl } from "@/common/hooks/useRedirectUrl";
import { useUserStore } from "@/store/userStore";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {
  sendEmailVerification,
  signInWithEmailAndPassword,
} from "firebase/auth";
import jwtDecode from "jwt-decode";
import { isValidPhoneNumber } from "libphonenumber-js";
import { MuiTelInput } from "mui-tel-input";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Link as RouterLink,
  To,
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ObjectSchema, boolean, object, string } from "yup";
import { firebaseAppAuth } from "../../common/firebase/config";
import { ArrowBack } from "@mui/icons-material";

export interface RegisterInput {
  email: string;
  password: string;
  firstname: string;
  lastname: string;
  phoneNumber: string;
  inviteToken?: string;
  contactConsent: boolean;
}

const schema: ObjectSchema<RegisterInput> = object().shape({
  email: string().email().required(),
  password: string().required(),
  firstname: string().required(),
  lastname: string().required(),
  phoneNumber: string()
    .required()
    .test("isValidPhoneNumber", "Numéro de téléphone invalide", (value) => {
      return isValidPhoneNumber(value);
    }),
  inviteToken: string(),
  contactConsent: boolean()
    .required()
    .oneOf([true], "You need to accept the terms and conditions"),
});

export default function RegisterBusinessUser() {
  const userState = useUserStore();
  const [searchParams] = useSearchParams();
  const [inviteToken, setInviteToken] = useState(searchParams.get("it"));
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isValid },
    control,
    setError,
  } = useForm<RegisterInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      inviteToken: inviteToken || undefined,
      phoneNumber: "+33",
      contactConsent: false,
    },
    mode: "all",
  });
  const { getCurrentRedirectUrl, redirectFromCache } = useRedirectUrl();

  const signinUrl = useMemo((): To => {
    const redirectUrl = getCurrentRedirectUrl();
    if (redirectUrl) {
      return {
        pathname: "/auth",
        search: `?${createSearchParams({ redirectUrl })}`,
      };
    } else {
      return {
        pathname: "/auth",
        search: `?${createSearchParams({
          redirectUrl: "/owner/business/list",
        })}`,
      };
    }
  }, []);

  useEffect(() => {
    if (!inviteToken) return;
    const decodedToken = jwtDecode<{ invite_id: string; email: string }>(
      inviteToken
    );
    setValue("email", decodedToken.email);
  }, [inviteToken]);

  useEffect(() => {
    if (userState.init && userState.user) {
      const redirectUrl = getCurrentRedirectUrl();
      if (redirectUrl) {
        navigate(redirectUrl.replace("/app", ""));
      }
    }
  }, [userState.init, userState.user, getCurrentRedirectUrl, navigate]);

  const createUser = async (userData: RegisterInput) => {
    if (
      userData.email &&
      userData.password &&
      userData.firstname &&
      userData.lastname &&
      userData.contactConsent
    ) {
      setIsLoading(true);
      try {
        const response = await registerNewUser(userData);

        if (response?.error) {
          if (response?.error === "auth/email-already-exists") {
            Logger.error("Adresse email déjà enregistré !");
            setError("email", { type: "pattern" });
          } else {
            Logger.error(response);
          }
        } else {
          const result = await signInWithEmailAndPassword(
            firebaseAppAuth,
            userData.email.toString(),
            userData.password.toString()
          );
          await sendEmailVerification(result.user);

          navigate("/owner/business/list");
        }
      } catch (e) {
        Logger.error(e);
      }
      setIsLoading(false);
    }
  };

  if (!userState.init) {
    return;
  }

  if (userState.user) {
    return (
      <Container
        component="main"
        maxWidth="md"
        sx={{
          height: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          pt: { xs: 10, md: 0 },
        }}>
        <CssBaseline />
        <Box sx={{ py: 1, overflow: "auto" }}>
          <Card>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}>
              <Typography component="h1" variant="h5" sx={{ mb: 0 }}>
                Vous êtes déjà connecté
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Container>
    );
  }

  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{
        height: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        pt: { xs: 10, md: 0 },
      }}>
      <CssBaseline />
      <Box sx={{ py: 1, overflow: "auto" }}>
        <Card>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
            }}>
            <Box sx={{ position: "absolute", left: 15, top: 15 }}>
              <RouterLink to="/">
                <IconButton>
                  <ArrowBack />
                </IconButton>
              </RouterLink>
            </Box>
            <Typography component="h1" variant="h5" sx={{ mb: 0 }}>
              Créer un compte (Business)
            </Typography>

            <Grid container justifyContent={"center"}>
              <Grid
                item
                xs={12}
                sm={8}
                md={6}
                sx={{ p: 2 }}
                textAlign={"left"}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column">
                <Typography
                  component="p"
                  variant="body1"
                  sx={{ textAlign: "center" }}>
                  Vous serez en mesure d'enregistrer votre/vos établissement(s)
                  dans notre base de données et de les promouvoir grâce à nos
                  outils !
                </Typography>

                <ul>
                  <li>
                    <Typography variant="body2">
                      Ajoutez vos établissements
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2">
                      Gérez vos collaborateurs
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2">
                      Annoncez vos promotions
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2">
                      Fidélisez vos clients !
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2">Et plus encore ...</Typography>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} sm={8} md={6}>
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit(createUser)}
                  sx={{ mt: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        {...register("firstname")}
                        error={!!errors.firstname}
                        autoComplete="given-name"
                        required
                        fullWidth
                        id="firstName"
                        label="Prénom"
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        {...register("lastname")}
                        error={!!errors.lastname}
                        required
                        fullWidth
                        id="lastName"
                        label="Nom"
                        autoComplete="family-name"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        {...register("email")}
                        required
                        error={!!errors.email}
                        disabled={!!inviteToken}
                        fullWidth
                        id="email"
                        label="Adresse email"
                        autoComplete="email"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="phoneNumber"
                        control={control}
                        render={({ field }) => {
                          return (
                            <MuiTelInput
                              {...field}
                              error={!!errors.phoneNumber}
                              onChange={(e) => field.onChange(e)}
                              fullWidth
                              id="phoneNumber"
                              label="Numéro de téléphone"
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        {...register("password")}
                        required
                        fullWidth
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                      />
                    </Grid>

                    <Grid item xs={12} sx={{ mt: 2 }}>
                      <FormControlLabel
                        control={
                          <Controller
                            name="contactConsent"
                            control={control}
                            render={({ field }) => {
                              return (
                                <Checkbox
                                  {...field}
                                  checked={field.value}
                                  onChange={(e) =>
                                    field.onChange(e.target.checked)
                                  }
                                />
                              );
                            }}
                          />
                        }
                        label={
                          <Typography
                            variant="caption"
                            color={errors.contactConsent ? "error" : ""}>
                            Vous acceptez d'être contacté par un membre de notre
                            équipe dans des buts de vérifications et de suivis
                            de dossier.
                          </Typography>
                        }
                      />
                    </Grid>
                  </Grid>
                  <LoadingButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    loading={isLoading}
                    sx={{ mt: 4, mb: 3 }}>
                    Créer le compte
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>

            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link component={RouterLink} to={signinUrl} variant="body2">
                  Vous avez un compte ? Connectez vous
                </Link>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
              <Grid item>
                <Link
                  component={RouterLink}
                  to="/auth/register/customer"
                  variant="body2">
                  Créer un compte client
                </Link>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}
