import { getImageUrl } from "@/common/firebase/common";
import { useBusinessDocumentForPublic } from "@/common/firebase/services/user-hooks";
import NoImage from "@assets/no-image.jpg";
import { Business, HelpCenter, Settings } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Skeleton,
} from "@mui/material";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useSetCurrentPageName } from "../store/appSettingsStore";
import { useUserStore } from "../store/userStore";

export default function Dashboard() {
  useSetCurrentPageName("Profil");
  const userState = useUserStore();

  // useEffect(() => {
  //   // postContactForm;
  // });

  if (!userState.user) {
    return (
      <Container component="main" maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography component="p">
          Pour acceder a nos super promos{" "}
          <Link to="/auth">cre toi un compte ou connecte toi ici</Link>
        </Typography>
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="lg" sx={{ overflow: "unset" }}>
      <Grid container rowSpacing={2} columnSpacing={2.75}>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Grid container>
            <Grid item xs sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h5" component="h1">
                Bonjour {userState.user?.displayName}
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <IconButton component={Link} to="edit">
                <Settings color="info" fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="outlined"
            component={Link}
            to="/owner/business/list"
            startIcon={<Business />}>
            {userState.user.businessUser
              ? "Gérez vos établissements"
              : "Ajoutez votre établissement"}
          </Button>
        </Grid>
        {/* <Grid item xs={12}>
          <Button
            component={Link}
            to="/search"
            fullWidth
            startIcon={<Search />}
            variant="outlined">
            Rechercher un établissement
          </Button>
        </Grid> */}
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="outlined"
            component={Link}
            to="/contact"
            startIcon={<HelpCenter />}>
            Assistance / Contactez nous
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" component="h2">
                Tes abonnements
              </Typography>
            </Grid>
            {userState.user.customer?.subscribed_businesses?.map((business) => (
              <Grid
                item
                xs={6}
                md={4}
                lg={3}
                sx={{ height: "200px" }}
                key={business.id}>
                <BusinessCard id={business.id} />{" "}
              </Grid>
            ))}
            {!userState.user.customer?.subscribed_businesses?.length && (
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography>Aucun lieux préféré</Typography>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="h5" component="h1">
                Tes lieux préférés
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {userState.user.customer?.scanned_businesses?.map(
                  (business) => (
                    <Grid
                      item
                      xs={6}
                      md={4}
                      lg={3}
                      key={business.id}
                      sx={{ height: "200px" }}>
                      <BusinessCard id={business.id} scans={business.scans} />
                    </Grid>
                  )
                )}
                {!userState.user.customer?.scanned_businesses?.length && (
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Typography>Aucun scan pour le moment</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

const BusinessCard = ({ id, scans }: { id: string; scans?: number }) => {
  const { business, isLoading } = useBusinessDocumentForPublic(id);

  if (isLoading)
    return (
      <Card sx={{ height: "100%" }}>
        <CardContent sx={{ height: 1, p: 0 }}>
          <Skeleton variant="rectangular" sx={{ width: 1, height: 1 }} />
        </CardContent>
      </Card>
    );

  if (!business)
    return (
      <Card sx={{ height: 1 }}>
        <CardContent>Contenu non trouvé</CardContent>
      </Card>
    );

  return (
    <Card sx={{ height: "100%" }}>
      <CardActionArea
        component={Link}
        sx={{ height: 1 }}
        to={`/business/${id}`}>
        <CardMedia
          sx={{ height: 1, display: "flex", alignItems: "end" }}
          image={
            (business.cover_picture &&
              getImageUrl(business.cover_picture.key)) ||
            NoImage
          }
          title="green iguana">
          <CardContent
            sx={{
              p: 1,
              width: 1,
              "&:last-child": { pb: 1 },
              background: "rgba(0,0,0,0.4)",
            }}>
            <Typography
              color="white"
              gutterBottom
              variant="subtitle1"
              component="div">
              {business.name}
            </Typography>
            {scans && (
              <Typography color="white" variant="body2">
                {scans} scans
              </Typography>
            )}
          </CardContent>
        </CardMedia>
      </CardActionArea>
    </Card>
  );
};
