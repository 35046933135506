import { ArrowBack, ArrowForward, Close } from "@mui/icons-material";
import { Chip, CircularProgress, Fab, Modal } from "@mui/material";
import Box from "@mui/material/Box";
import { useState } from "react";

export default function PicturePreview({
  pictureList = [],
  activeIndex = 0,
  setActiveIndex,
}: {
  pictureList: string[];
  activeIndex: number;
  setActiveIndex: (value: undefined) => void;
}) {
  const [index, setIndex] = useState(activeIndex);
  const [loading, setLoading] = useState(true);

  return (
    <Modal
      open={!!pictureList?.length}
      onClose={() => setActiveIndex(undefined)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box
        className="top-notch"
        sx={{
          p: 2,
          display: "flex",
          alignItems: "center",
          height: 1,
          overflow: "auto",
          flexDirection: "column",
        }}>
        <Fab
          className="background-gradient-left"
          sx={{ position: "absolute", right: 20, top: 10 }}
          onClick={() => setActiveIndex(undefined)}>
          <Close htmlColor="white" />
        </Fab>
        {pictureList?.length && (
          <Chip
            color="secondary"
            sx={{ mb: 1 }}
            className="background-gradient-left"
            label={`${index + 1}/${pictureList?.length}`}></Chip>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 1,
          }}>
          {loading && <CircularProgress variant="indeterminate" />}
          <Box
            sx={{ display: loading ? "none" : "block", textAlign: "center" }}>
            {/* Rerender img tag */}
            {index % 2 === 0 && (
              <img
                style={{ maxWidth: "75%" }}
                onLoad={() => setLoading(false)}
                src={pictureList?.[index]}
              />
            )}
            {index % 2 !== 0 && (
              <img
                style={{ maxWidth: "75%" }}
                onLoad={() => setLoading(false)}
                src={pictureList?.[index]}
              />
            )}
          </Box>
        </Box>
        <Fab
          size="medium"
          sx={{ position: "absolute", top: "50%", left: "15px" }}
          disabled={!(index - 1 > -1)}
          onClick={() => {
            setLoading(true);
            setIndex(index - 1);
          }}>
          <ArrowBack />
        </Fab>
        <Fab
          size="medium"
          sx={{ position: "absolute", top: "50%", right: "15px" }}
          disabled={!(index + 1 < pictureList?.length)}
          onClick={() => {
            setLoading(true);
            setIndex(index + 1);
          }}>
          <ArrowForward />
        </Fab>
      </Box>
    </Modal>
  );
}
