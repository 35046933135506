import { Logger } from "@/common/error-handling";
import { registerNewUser } from "@/common/firebase/services/user-requests";
import { useRedirectUrl } from "@/common/hooks/useRedirectUrl";
import { useUserStore } from "@/store/userStore";
import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowBack } from "@mui/icons-material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { LoadingButton } from "@mui/lab";
import { Card, CardContent, IconButton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {
  sendEmailVerification,
  signInWithEmailAndPassword,
} from "firebase/auth";
import jwtDecode from "jwt-decode";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Link as RouterLink,
  To,
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ObjectSchema, object, string } from "yup";
import { firebaseAppAuth } from "../../common/firebase/config";

export interface RegisterInput {
  email: string;
  password: string;
  firstname: string;
  lastname: string;
  inviteToken?: string;
}

const schema: ObjectSchema<RegisterInput> = object().shape({
  email: string().email().required(),
  password: string().required(),
  firstname: string().required(),
  lastname: string().required(),
  inviteToken: string(),
});

export default function RegisterCustomer() {
  const userState = useUserStore();
  const [searchParams] = useSearchParams();
  const [inviteToken, setInviteToken] = useState(searchParams.get("it"));
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    setError,
  } = useForm<RegisterInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      inviteToken: inviteToken || undefined,
    },
  });
  const { getCurrentRedirectUrl, redirectFromCache } = useRedirectUrl();

  const signinUrl = useMemo((): To => {
    const redirectUrl = getCurrentRedirectUrl();
    if (redirectUrl) {
      return {
        pathname: "/auth",
        search: `?${createSearchParams({ redirectUrl })}`,
      };
    } else {
      return {
        pathname: "/auth",
      };
    }
  }, []);

  useEffect(() => {
    if (!inviteToken) return;
    const decodedToken = jwtDecode<{ invite_id: string; email: string }>(
      inviteToken
    );
    setValue("email", decodedToken.email);
  }, [inviteToken]);

  useEffect(() => {
    if (userState.init && userState.user) {
      const redirectUrl = getCurrentRedirectUrl();
      if (redirectUrl) {
        navigate(redirectUrl.replace("/app", ""));
      }
    }
  }, [userState.init, userState.user, getCurrentRedirectUrl, navigate]);

  const createUser = async (userData: RegisterInput) => {
    if (
      userData.email &&
      userData.password &&
      userData.firstname &&
      userData.lastname
    ) {
      setIsLoading(true);
      try {
        const response = await registerNewUser(userData);

        if (response?.error) {
          if (response?.error === "auth/email-already-exists") {
            Logger.error("Adresse email déjà enregistré !");
            setError("email", { type: "pattern" });
          } else {
            Logger.error(response);
          }
        } else {
          const result = await signInWithEmailAndPassword(
            firebaseAppAuth,
            userData.email.toString(),
            userData.password.toString()
          );
          await sendEmailVerification(result.user);

          if (!redirectFromCache()) {
            navigate("/");
          }
        }
      } catch (e) {
        Logger.error(e);
      }
      setIsLoading(false);
    }
  };

  if (!userState.init) {
    return;
  }

  if (userState.user) {
    return (
      <Container
        component="main"
        maxWidth="md"
        sx={{
          height: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          pt: { xs: 10, md: 0 },
        }}>
        <CssBaseline />
        <Box sx={{ py: 1, overflow: "auto" }}>
          <Card>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
              }}>
              <Box sx={{ position: "absolute", left: 15, top: 15 }}>
                <RouterLink to="/">
                  <IconButton>
                    <ArrowBack />
                  </IconButton>
                </RouterLink>
              </Box>
              <Typography component="h1" variant="h5" sx={{ mb: 0 }}>
                Vous êtes déjà connecté
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Container>
    );
  }

  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{
        height: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <CssBaseline />
      <Box sx={{ py: 1, overflow: "auto" }}>
        <Card variant="rounded">
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
            }}>
            <Box sx={{ position: "absolute", left: 15, top: 15 }}>
              <RouterLink to="/">
                <IconButton>
                  <ArrowBack />
                </IconButton>
              </RouterLink>
            </Box>
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Nouveau compte
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(createUser)}
              sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    {...register("firstname")}
                    error={!!errors.firstname}
                    autoComplete="given-name"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    {...register("lastname")}
                    error={!!errors.lastname}
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    {...register("email")}
                    error={!!errors.email}
                    required
                    disabled={!!inviteToken}
                    fullWidth
                    id="email"
                    label="Email Address"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    {...register("password")}
                    error={!!errors.password}
                    required
                    fullWidth
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                  />
                </Grid>
              </Grid>
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                loading={isLoading}
                sx={{ mt: 3, mb: 2 }}>
                Créer le compte
              </LoadingButton>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link component={RouterLink} to={signinUrl} variant="body2">
                    Vous avez un compte ? Connectez vous
                  </Link>
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                <Grid item>
                  <Link
                    component={RouterLink}
                    to="/auth/register/business"
                    variant="body2">
                    Créer un compte business
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}
