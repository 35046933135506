import useSWR from "swr";
import {
  getBusinessByIdForPublic,
  getBusinessCollectionForUser,
  getCustomerById,
  getScansHistoryForUser,
} from "./user-requests";

export const useBusinessCollection = () => {
  const { data, error, isLoading, mutate } = useSWR(
    "useBusinessCollectionnull",
    () => getBusinessCollectionForUser(),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return {
    collection: data,
    isLoading,
    isError: error,
    mutate,
  };
};

export const useBusinessDocumentForPublic = (id: string, userId?: string) => {
  const { data, error, isLoading, mutate } = useSWR(
    id,
    () => getBusinessByIdForPublic(id, userId),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return {
    business: data,
    isLoading,
    isError: error,
    mutate,
  };
};

export const useBusinessScansForUser = (
  businessId: string,
  userId: string,
  programId: string,
  collected = false
) => {
  const { data, error, isLoading, mutate } = useSWR(
    `${businessId}${userId}${programId}`,
    () => getScansHistoryForUser(businessId, userId, programId, collected),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return {
    scans: data || [],
    isLoading,
    isError: error,
    mutate,
  };
};

export const useCustomer = (id: string) => {
  const { data, error, isLoading, mutate } = useSWR(id, getCustomerById, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return {
    customer: data,
    isLoading,
    isError: error,
    mutate,
  };
};
