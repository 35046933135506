import { Logger } from "@/common/error-handling";
import { useCountdown } from "@/common/hooks/useCountdown";
import { useSetCurrentPageName } from "@/store/appSettingsStore";
import { useUserStore } from "@/store/userStore";
import { ArrowForward } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { sendEmailVerification } from "firebase/auth";
import { useState } from "react";
import { Link } from "react-router-dom";

//& google.maps.places.PlaceOpeningHoursPeriod
export default function OwnerSettings() {
  useSetCurrentPageName("Paramètres");
  const [disabledEmailVerif, setDisabledEmailVerif] = useState(false);

  const userState = useUserStore();
  const { startCountdown, remainingValue } = useCountdown();

  if (!userState.user) {
    return;
  }

  return (
    <Container component="main" disableGutters>
      <CssBaseline />
      <Card sx={{ my: 2 }}>
        <CardContent>
          <Grid container>
            <Grid item sx={{ pr: 2 }}>
              <Avatar
                sx={{ height: 56, width: 56 }}
                src={userState.user.photoURL || ""}>
                {userState.user?.customer?.username?.slice(0, 2) ||
                  userState.user?.customer?.firstname?.slice(0, 2)}
              </Avatar>
            </Grid>
            <Grid item xs>
              <Typography variant="h5" component="div">
                {userState.user.customer?.firstname}{" "}
                {userState.user.customer?.lastname}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {userState.user.customer?.username}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: "end" }}>
          <Button
            size="small"
            color="primary"
            endIcon={<ArrowForward />}
            component={Link}
            to="/dashboard/edit">
            Modifier le profil public
          </Button>
        </CardActions>
      </Card>
      <Typography variant="h4" sx={{ mb: 1 }}>
        Contact
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title="Téléphone"></CardHeader>
            <CardContent>
              <Grid container>
                <Grid
                  item
                  xs
                  sm={6}
                  sx={{ display: "flex", alignItems: "center" }}>
                  <Typography>
                    {userState.user?.businessUser?.phoneNumber.number}
                  </Typography>
                </Grid>
                <Grid item xs={"auto"} sm={6} sx={{ textAlign: "center" }}>
                  <Chip
                    label={
                      userState.user?.businessUser?.phoneNumber.validated_on
                        ? "Validé"
                        : "En attente de validation"
                    }
                    color={
                      userState.user?.businessUser?.phoneNumber.validated_on
                        ? "success"
                        : "default"
                    }></Chip>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title="Email"></CardHeader>
            <CardContent>
              <Grid container>
                <Grid
                  item
                  xs
                  sm={6}
                  sx={{ display: "flex", alignItems: "center" }}>
                  <Typography>{userState.user?.email}</Typography>
                </Grid>
                <Grid item xs={"auto"} sm={6} sx={{ textAlign: "center" }}>
                  <Chip
                    label={
                      userState.user?.emailVerified
                        ? "Validé"
                        : "En attente de validation"
                    }
                    color={
                      userState.user?.emailVerified ? "success" : "default"
                    }></Chip>
                </Grid>
                {!userState.user?.emailVerified && (
                  <Grid item xs={12} sx={{ textAlign: "right", pt: 2 }}>
                    <Button
                      disabled={disabledEmailVerif}
                      onClick={() => {
                        if (userState.user?.firebaseUser) {
                          setDisabledEmailVerif(true);
                          startCountdown(60);
                          sendEmailVerification(
                            userState.user.firebaseUser
                          ).catch((e) => {
                            Logger.error(
                              "Veuillez attendre avant de redemander un mail de confirmation."
                            );
                          });
                          setTimeout(() => setDisabledEmailVerif(false), 60000);
                        }
                      }}>
                      Renvoyer le mail de validation{" "}
                      {disabledEmailVerif && `(${remainingValue}s)`}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
