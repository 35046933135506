import { useLocation, useNavigate } from "react-router";
import { useUserStore } from "../../store/userStore";

export const useUserGuard = (): [boolean, Function] => {
  const userState = useUserStore();
  const navigate = useNavigate();
  const location = useLocation();

  if (userState.init && !userState.user) {
    return [false, () => navigate("/auth?redirectUrl=" + location.pathname)];
  }
  return [true, () => {}];
};
