import { RegisterInput } from "@/auth/signin/RegisterCustomer";
import { useUserStore } from "@/store/userStore";
import { FBCloudFunctions, FBCollections } from "@common/firebase";
import {
  Business,
  BusinessFirestore,
  Customer,
  CustomerFirestore,
  CustomerFirestore2,
  CustomerSettings,
  Scan,
  ScanFirestore,
} from "@common/index";
import { FirebaseError } from "firebase/app";
import { Unsubscribe, User } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import {
  firebaseAppAuth,
  firebaseAppFirestoreDb,
  firebaseAppFunctions,
} from "../config";
import { converter } from "../converter";
import { getAppUser, getBusinessForPublic } from "../mapper";

export const getBusinessByIdForPublic = async (
  id: string,
  userId?: string
): Promise<Business> => {
  const docRef = doc(firebaseAppFirestoreDb, FBCollections.Businesses, id);
  return await getDoc(docRef).then((doc) => getBusinessForPublic(doc, userId));
};

export const getScansHistoryForUser = async (
  businessId: string,
  userId: string,
  programId: string,
  collected = false
): Promise<Scan[]> => {
  return await getCustomerScanForBusiness(
    userId,
    businessId,
    programId,
    collected
  );
};

/**
 * TODO this is too much consuming the database at line 221
 * @param userId
 * @param callback
 * @param limit2
 * @returns
 */
export const getCustomerScansRealTime = (
  userId: string,
  callback: Function,
  limit2: number = 10
): Unsubscribe | null => {
  try {
    return onSnapshot(
      query(
        collection(firebaseAppFirestoreDb, FBCollections.Scans),
        orderBy("created_at", "desc"),
        where("customer_id", "==", userId),
        limit(limit2)
      ),
      async (scans) => {
        callback(
          await Promise.all(
            scans.docs.map(async (doc) => {
              const data = doc.data() as ScanFirestore;
              const business = data.business_id
                ? await getBusinessByIdForPublic(data.business_id, userId)
                : undefined;

              return {
                ...data,
                business,
                id: doc.id,
              };
            })
          )
        );
      }
    );
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const deleteUserAccount = (): Promise<
  { error?: FirebaseError["code"] } | undefined
> => {
  const inviteCallable = httpsCallable<
    RegisterInput,
    { error?: FirebaseError["code"] }
  >(firebaseAppFunctions, FBCloudFunctions.RemoveUser);
  return inviteCallable().then((result) => {
    return result.data;
  });
};

export const registerNewUser = (
  userData: RegisterInput
): Promise<{ error?: FirebaseError["code"] } | undefined> => {
  const inviteCallable = httpsCallable<
    RegisterInput,
    { error?: FirebaseError["code"] }
  >(firebaseAppFunctions, FBCloudFunctions.RegisterNewUser);
  return inviteCallable(userData).then((result) => {
    return result.data;
  });
};

export const createCustomerProfile = (
  user: User,
  firstname: string,
  lastname: string = ""
) => {
  const data: CustomerFirestore = {
    username: `${firstname}${lastname[0] || ""}`,
    firstname,
    lastname,
    subscribed_businesses: [],
  };
  return setDoc(
    doc(
      firebaseAppFirestoreDb,
      FBCollections.Customers,
      user.uid
    ).withConverter(converter<CustomerFirestore>()),
    data
  );
};

export const updateCustomerSettings = async (
  userUid: string,
  fcmToken: string
) => {
  const data: CustomerSettings = {
    messagingToken: fcmToken,
  };
  await setDoc(
    doc(
      firebaseAppFirestoreDb,
      FBCollections.CustomerSettings,
      userUid
    ).withConverter(converter<CustomerSettings>()),
    data,
    {
      merge: true,
    }
  );
};

export const subscribeToBusiness = async (
  userId: string,
  businessId: string
) => {
  const customerRef = doc(
    firebaseAppFirestoreDb,
    `${FBCollections.Customers}/${userId}`
  );
  const businessRef = doc(
    firebaseAppFirestoreDb,
    `${FBCollections.Businesses}/${businessId}`
  );
  const customerSubRef = doc(
    firebaseAppFirestoreDb,
    `${FBCollections.Customers}/${userId}/subscribed_businesses/${businessId}`
  );
  const businessSubRef = doc(
    firebaseAppFirestoreDb,
    `${FBCollections.Businesses}/${businessId}/subscribed_customers/${userId}`
  );
  const batch = await writeBatch(firebaseAppFirestoreDb);
  batch.set(customerSubRef, {
    ref: businessRef,
  });
  batch.set(businessSubRef, {
    ref: customerRef,
  });
  await batch.commit();
};

export const unsubscribeToBusiness = async (
  userId: string,
  businessId: string
) => {
  const customerSubRef = doc(
    firebaseAppFirestoreDb,
    `${FBCollections.Customers}/${userId}/subscribed_businesses/${businessId}`
  );
  const businessSubRef = doc(
    firebaseAppFirestoreDb,
    `${FBCollections.Businesses}/${businessId}/subscribed_customers/${userId}`
  );
  const batch = await writeBatch(firebaseAppFirestoreDb);
  batch.delete(customerSubRef);
  batch.delete(businessSubRef);
  await batch.commit();
};

export const getCustomerById = async (
  id: string
): Promise<CustomerFirestore2> => {
  const docRef = doc(firebaseAppFirestoreDb, FBCollections.Customers, id);
  return getDoc(docRef).then((doc) => ({
    ...(doc.data() as CustomerFirestore),
    id: doc.id,
  }));
};

export const updateCustomer = async (id: string, data: Partial<Customer>) => {
  const userStore = useUserStore.getState();

  const docRef = doc(firebaseAppFirestoreDb, FBCollections.Customers, id);
  await updateDoc(docRef, data);

  userStore.setUser(await getAppUser(firebaseAppAuth.currentUser!));
};

export const updateUser = async () => {
  const userStore = useUserStore.getState();
  if (userStore.user) {
    userStore.setUser(await getAppUser(userStore.user));
  } else {
    userStore.setUser(null);
  }
};

export const getCustomerScanForBusiness = async (
  userId: string,
  businessId: string,
  programId: string,
  collected = false
): Promise<Scan[]> => {
  try {
    const scanQuery = query(
      collection(firebaseAppFirestoreDb, FBCollections.Scans),
      where("customer_id", "==", userId),
      where("business_id", "==", businessId),
      where("program_id", "==", programId),
      ...(collected ? [] : [where("collected", "==", null)]),
      orderBy("created_at", "desc")
    );

    const scans = await getDocs(scanQuery);

    return await Promise.all(
      scans.docs.map(async (doc) => {
        const data = doc.data() as ScanFirestore;

        return {
          ...data,
          id: doc.id,
        };
      })
    );
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const getBusinessCollectionForUser = async (): Promise<Business[]> => {
  const docCollection = await getDocs(
    query(
      collection(firebaseAppFirestoreDb, FBCollections.Businesses),
      orderBy("created", "desc")
    )
  );
  return Promise.all(
    docCollection.docs.map((doc) => getBusinessForPublic(doc))
  );
};

/**
 *
 * @param lat
 * @param lon
 * @deprecated
 */
export const getNearestBusinesses = (
  lat: number,
  lon: number
): Promise<BusinessFirestore[]> => {
  const getNearestCallable = httpsCallable<any, BusinessFirestore[]>(
    firebaseAppFunctions,
    FBCloudFunctions.NearestBusinesses
  );
  return getNearestCallable({ lat, lon }).then((result) => {
    return result.data;
  });
};
