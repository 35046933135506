import { ComponentErrorBoundary } from "@/common/error-boundary";
import { Logger } from "@/common/error-handling";
import { getImageUrl } from "@/common/firebase/common";
import {
  firebaseAppAuth,
  firebaseAppFirestoreDb,
} from "@/common/firebase/config";
import { converter } from "@/common/firebase/converter";
import { useBusinessDocumentForOwner } from "@/common/firebase/services/owner-hooks";
import {
  archiveBusinessLottery,
  makeLotteryDraw,
} from "@/common/firebase/services/owner-requests";
import { useBusinessPermission } from "@/common/hooks/useBusinessPermission";
import { useResponsive } from "@/common/layout/hooks/use-responsive";
import { Capacitor } from "@capacitor/core";
import { BusinessSubCollections, FBCollections } from "@common/firebase";
import { ClaimScanResponse, CustomerFirestore2 } from "@common/index";
import { ProgramLottery, WeightedParticipant } from "@common/model/business";
import { MoreVert, Person } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fade,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { doc, Timestamp, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppModal } from "../../common/components/AppModal";
import { useUserStore } from "../../store/userStore";
import ClaimRewardDialog from "../ClaimRewardDialog";
import UpgradePremiumAd from "../PremiumAd";
import { QRScannerMobile } from "../QRScannerMobile";
import { QRScannerWeb } from "../QRScannerWeb";
import { LotteryForm } from "../forms/LotteryForm";

const style = {
  position: "absolute",
  top: "0",
  left: "0",
  transform: "translate(0, 0)",
  width: 1,
  height: 1,
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function BusinessLotteryPrograms() {
  const { id } = useParams();
  const { user } = useUserStore();

  if (!id || !user) return <></>;

  return <View id={id} userId={user.uid} />;
}

function View({ id, userId }: { id: string; userId: string }) {
  const downSM = useResponsive("down", "sm");
  const { business, isLoading, mutate } = useBusinessDocumentForOwner(
    id,
    userId
  );
  const [permissions] = useBusinessPermission(business);

  const [openLottery, setOpenLottery] = useState(false);
  const [editingLottery, setEditingLottery] = useState<ProgramLottery | null>(
    null
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [isDrawingResults, setIsDrawingResults] = useState(false);
  const [drawResults, setDrawResults] = useState<{
    lottery: ProgramLottery;
    results: WeightedParticipant[];
  }>();
  const [openScanner, setOpenScanner] = useState<ProgramLottery>();
  const [loadingDistribution, setLoadingDistribution] = useState(false);
  const [showClaimRewardDialog, setShowClaimRewardDialog] =
    useState<ClaimScanResponse>();

  //Necessary for distribute reward dialog data to be reactive
  useEffect(() => {
    if (drawResults) {
      const lottery = business?.programs?.filteredLottery.archived.find(
        (lottery) => lottery.id === drawResults.lottery.id
      );
      if (lottery) {
        setDrawResults({
          lottery,
          results: lottery.drawResults as WeightedParticipant[],
        });
      }
    }
  }, [business]);

  const handleOpenLottery = (lottery?: ProgramLottery) => {
    handleClose();
    setEditingLottery(lottery || null);
    setOpenLottery(true);
  };
  const handleCloseLottery = () => {
    setOpenLottery(false);
    setEditingLottery(null);
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseScanner = () => setOpenScanner(undefined);

  const archiveProgram = async (lottery: ProgramLottery) => {
    if (!business) {
      return;
    }
    handleClose();
    await archiveBusinessLottery(business, lottery.id);
    mutate();
  };

  const drawLottery = async (lottery: ProgramLottery) => {
    if (!business) {
      return;
    }
    try {
      setIsDrawingResults(true);
      const results = await makeLotteryDraw(lottery.id, business.id);

      if (results === null) {
        Logger.error("Une erreur s'est produite lors du tirage");
      } else if (results.drawResults) {
        setDrawResults({ lottery, results: results.drawResults });
        mutate();
      }
    } catch (e) {
      Logger.error(e);
    }

    setIsDrawingResults(false);
    setOpenDialog(false);
  };

  function submitScan(
    customer: CustomerFirestore2,
    scannedValue: string,
    businessId: string
  ) {
    mutate();
  }

  async function distributeScan(
    program: ProgramLottery,
    winner: WeightedParticipant
  ) {
    setLoadingDistribution(true);
    const ref = doc(
      firebaseAppFirestoreDb,
      FBCollections.Businesses,
      id,
      BusinessSubCollections.Lottery,
      program.id
    ).withConverter(converter<ProgramLottery>());

    await updateDoc(ref, {
      drawResults: program.drawResults!.map((result) => {
        if (winner.customer.id === result.customer.id) {
          result.distributed = {
            distributed_by: firebaseAppAuth.currentUser!.uid,
            distributed_on: Timestamp.now(),
          };
        }
        return result;
      }),
    });
    await mutate();

    setShowClaimRewardDialog({
      type: "claim",
      data: {
        ...program,
        reward: [
          program.reward[
            program.drawResults?.findIndex(
              (result) => result.customer.id === winner.customer.id
            ) || 0
          ],
        ],
      },
    });

    setLoadingDistribution(false);
  }

  if (!business) return <></>;

  return (
    <ComponentErrorBoundary>
      <Grid container>
        <Grid item xs sx={{ mt: 2 }}>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openLottery}
            onClose={handleCloseLottery}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}>
            <Fade in={openLottery}>
              <Box sx={style}>
                <AppModal
                  title="Créer une lotterie"
                  handleClose={handleCloseLottery}>
                  <LotteryForm
                    business={business}
                    lottery={editingLottery || undefined}
                    handleClose={handleCloseLottery}
                    mutate={mutate}
                  />
                </AppModal>
              </Box>
            </Fade>
          </Modal>
          <Stack spacing={2}>
            {!permissions.canEditPrograms && (
              <Box display="flex" justifyContent="center" sx={{ p: 4 }}>
                <UpgradePremiumAd business={business} />
              </Box>
            )}
            {business.programs?.filteredLottery.active ? (
              [business.programs.filteredLottery.active].map((lottery, i) => (
                <Card key={lottery.id}>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}>
                    <MenuItem
                      onClick={() => handleOpenLottery(lottery)}
                      disabled={!permissions.canEditPrograms}>
                      Éditer
                    </MenuItem>
                    <MenuItem onClick={() => archiveProgram(lottery)}>
                      Archiver
                    </MenuItem>
                  </Menu>
                  <CardHeader
                    action={
                      <IconButton
                        aria-label="settings"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}>
                        <MoreVert />
                      </IconButton>
                    }
                    title={
                      <Grid container spacing={1}>
                        <Grid item>{lottery.title}</Grid>
                        <Grid item>
                          <Stack spacing={1} direction={"row"}>
                            <Chip
                              label={(lottery.scans || "0") + " participations"}
                              color={"info"}
                            />
                            <Chip label="en cours" color={"success"} />
                          </Stack>
                        </Grid>
                      </Grid>
                    }
                    subheader={
                      <Alert
                        sx={{
                          mt: 1,
                        }}>
                        {lottery.conditions.numberOfWinnersToDraw} Gagnants
                        {lottery.conditions.limit_date &&
                          ` | Date de fin ${dayjs(
                            lottery.conditions.limit_date
                          ).format("MM/DD/YYYY - HH:mm")}`}
                      </Alert>
                    }
                  />
                  <CardActions sx={{ justifyContent: "center" }}>
                    <Button
                      variant="contained"
                      onClick={() => setOpenDialog(true)}
                      disabled={!lottery.scans}>
                      Procéder au tirage
                    </Button>
                  </CardActions>
                  <Dialog maxWidth="xs" open={openDialog}>
                    <DialogTitle>Procéder au tirage</DialogTitle>
                    <DialogContent dividers>
                      {isDrawingResults ? (
                        <>
                          <Grid container sx={{ textAlign: "center" }}>
                            <Grid item xs={12}>
                              Tirage en cours
                            </Grid>
                            <Grid item xs={12} sx={{ py: 4 }}>
                              <CircularProgress />
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <>
                          Procéder au tirage met fin a la lotterie et tire des
                          gagnants selon les conditons que vous avez paramétré
                          lors de la création de cette lotterie.
                          <br />
                          Êtes vous sûr ?
                        </>
                      )}
                    </DialogContent>
                    {!isDrawingResults && (
                      <DialogActions>
                        <Button onClick={() => setOpenDialog(false)}>
                          Annuler
                        </Button>
                        <Button onClick={() => drawLottery(lottery)}>
                          Oui!
                        </Button>
                      </DialogActions>
                    )}
                  </Dialog>
                </Card>
              ))
            ) : (
              <>
                <Alert severity="warning">Aucune lotterie actuellement.</Alert>

                <Box textAlign={"center"}>
                  <Button
                    variant="contained"
                    disabled={!permissions.canEditPrograms}
                    onClick={() => handleOpenLottery()}>
                    Créer une lotterie
                  </Button>
                </Box>
              </>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography variant="h5">Historique</Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Stack spacing={2}>
            {business.programs?.filteredLottery?.archived.length ? (
              business.programs.filteredLottery.archived.map((lottery, i) => (
                <Card key={`archived-${lottery.id}`}>
                  <CardHeader
                    title={
                      <Grid container spacing={1}>
                        <Grid item>{lottery.title}</Grid>
                        <Grid item>
                          <Stack spacing={1} direction={"row"}>
                            <Chip
                              label={(lottery.scans || "0") + " participations"}
                              color={"info"}
                            />
                            <Chip
                              label={`Tirée le ${dayjs(
                                lottery.archived_at?.toDate()
                              ).format("MM/DD/YYYY - HH:mm")}`}
                              color={"success"}
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                    }
                    subheader={
                      <Alert
                        sx={{
                          mt: 1,
                        }}>
                        {lottery.conditions.numberOfWinnersToDraw} Gagnants
                        {lottery.conditions.limit_date &&
                          ` | Date de fin ${dayjs(
                            lottery.conditions.limit_date
                          ).format("MM/DD/YYYY - HH:mm")}`}
                      </Alert>
                    }
                  />
                  <CardContent>
                    <Alert severity="info">
                      {lottery.description} <br />
                      {lottery.reward.map((reward, i) => (
                        <pre>
                          {i + 1} - {reward.label}
                        </pre>
                      ))}
                    </Alert>
                  </CardContent>
                  {lottery.drawResults && (
                    <CardActions sx={{ justifyContent: "center" }}>
                      <Button
                        variant="outlined"
                        onClick={() =>
                          setDrawResults({
                            lottery,
                            results:
                              lottery.drawResults as WeightedParticipant[],
                          })
                        }>
                        Voir les résultats
                      </Button>
                    </CardActions>
                  )}
                </Card>
              ))
            ) : (
              <>
                <Alert severity="warning">Aucune lotterie archivée.</Alert>
              </>
            )}
          </Stack>
        </Grid>
        <Dialog fullScreen={downSM} open={!!drawResults}>
          <DialogTitle>Résultats du tirage</DialogTitle>
          <DialogContent dividers>
            <Stack spacing={2}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => setOpenScanner(drawResults!.lottery)}>
                {/* disabled={drawResults?.results.every((r) => r.distributed)} */}
                Scanner un QR code
              </Button>
              <Divider>ou</Divider>
              <List sx={{ pt: 0 }}>
                {drawResults?.results.map((result, i) => (
                  <ListItem
                    disableGutters
                    key={result.customer?.id}
                    sx={{ p: 0 }}>
                    <Box sx={{ pr: 2 }}>{i + 1}</Box>
                    <ListItemAvatar>
                      <Avatar
                        src={
                          result.customer?.profile_picture &&
                          getImageUrl(result.customer?.profile_picture.key)
                        }
                        sx={{ bgcolor: blue[100], color: blue[600] }}>
                        <Person />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                      <Grid container>
                        <Grid item xs>
                          <Stack>
                            {`${result.customer?.username}`}
                            <Typography variant="caption">
                              {`${result.customer?.firstname} ${result.customer?.lastname}`}
                            </Typography>
                          </Stack>
                        </Grid>
                        {!result.distributed ? (
                          <Grid
                            item
                            sx={{
                              ml: 3,
                              display: "flex",
                              alignItems: "center",
                            }}>
                            <LoadingButton
                              aria-label="delete"
                              loading={loadingDistribution}
                              onClick={() =>
                                distributeScan(drawResults.lottery, result)
                              }>
                              Distribuer
                            </LoadingButton>
                          </Grid>
                        ) : (
                          <Grid
                            item
                            sx={{
                              ml: 3,
                              display: "flex",
                              alignItems: "center",
                              textAlign: "center",
                            }}>
                            <LoadingButton
                              aria-label="delete"
                              loading={loadingDistribution}
                              disabled>
                              <Typography variant="caption">
                                Distribué le
                                <br />
                                {dayjs(
                                  result.distributed.distributed_on.toDate()
                                ).format("MM/DD/YYYY - HH:mm")}
                              </Typography>
                            </LoadingButton>
                          </Grid>
                        )}
                      </Grid>
                    </ListItemText>
                    {showClaimRewardDialog && (
                      <ClaimRewardDialog
                        program={drawResults.lottery}
                        reward={showClaimRewardDialog.data.reward[0]}
                        customer={result.customer}
                        onCloseRewardDialog={() =>
                          setShowClaimRewardDialog(undefined)
                        }
                      />
                    )}
                  </ListItem>
                ))}
              </List>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDrawResults(undefined)}>Fermer</Button>
          </DialogActions>
        </Dialog>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          id="qr-scanner-modal"
          open={!!openScanner}
          onClose={() => {
            handleCloseScanner();
            mutate();
          }}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}>
          <Fade in={!!openScanner} id="qr-scanner-modal-fade">
            <Box sx={style} className="top-notch">
              {Capacitor.isNativePlatform() ? (
                <QRScannerMobile
                  submitScanCallback={submitScan}
                  handleClose={() => {
                    handleCloseScanner();
                    mutate();
                  }}
                  business={business}
                  program={openScanner}
                />
              ) : (
                <QRScannerWeb
                  submitScanCallback={submitScan}
                  handleClose={() => {
                    handleCloseScanner();
                    mutate();
                  }}
                  business={business}
                  program={openScanner}
                />
              )}
            </Box>
          </Fade>
        </Modal>
      </Grid>
    </ComponentErrorBoundary>
  );
}
