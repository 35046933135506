import { Logger } from "@/common/error-handling";
import { getImageUrl } from "@/common/firebase/common";
import { converter } from "@/common/firebase/converter";
import {
  deleteUserAccount,
  updateCustomer,
  updateUser,
} from "@/common/firebase/services/user-requests";
import { useSetCurrentPageName } from "@/store/appSettingsStore";
import { FBCollections } from "@common/firebase";
import { CustomerFirestore } from "@common/index";
import { DeleteForever, Logout, Photo } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { signOut, updateProfile } from "firebase/auth";
import { doc, setDoc, Timestamp } from "firebase/firestore";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ImageUploader } from "../common/components/ImageUploader";
import {
  firebaseAppAuth,
  firebaseAppFirestoreDb,
} from "../common/firebase/config";
import { useUserStore } from "../store/userStore";

export default function EditProfile() {
  useSetCurrentPageName("Profil");
  const userState = useUserStore();
  const [editing, setEditing] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);

  if (!userState.user) {
    return (
      <Container component="main" maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography component="p">
          Pour acceder a nos super promos{" "}
          <Link to="/auth">cre toi un compte ou connecte toi ici</Link>
        </Typography>
      </Container>
    );
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    if (!userState.user) return;
    setEditing(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const userData = {
      username: data.get("username")?.toString(),
      lastname: data.get("lastname")?.toString(),
      firstname: data.get("firstname")?.toString(),
    };

    try {
      await updateCustomer(userState.user.uid, userData);
      Logger.success("Profil mis à jour");
    } catch (e) {
      Logger.error(e);
    }
    setEditing(false);
  };

  return (
    <Container component="main" maxWidth="md" sx={{ overflow: "unset", mt: 4 }}>
      <Grid container rowSpacing={1} columnSpacing={2.75}>
        <Grid item xs={12} sx={{ justifyContent: "center", display: "flex" }}>
          <ImageUploader
            type="profile"
            itemId={userState.user.uid}
            userId={userState.user.uid}
            collection={FBCollections.Customers}
            callback={async (result) => {
              if (!userState.user) return;

              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              await setDoc(
                doc(
                  firebaseAppFirestoreDb,
                  FBCollections.Customers,
                  userState.user.uid
                ).withConverter(converter<CustomerFirestore>()),
                {
                  profile_picture: {
                    key: result.key,
                    created_at: Timestamp.now(),
                    created_by: userState.user!.uid,
                  },
                },
                {
                  merge: true,
                }
              );
              const user = firebaseAppAuth.currentUser;
              if (user) {
                await updateProfile(user, { photoURL: result.url });
              }
              await updateUser();
              Logger.success("Image mise à jour");
            }}>
            <Avatar
              sx={{ height: 82, width: 82, cursor: "pointer" }}
              src={
                userState.user.customer?.profile_picture &&
                getImageUrl(userState.user.customer?.profile_picture?.key)
              }>
              <Photo fontSize="large" />
            </Avatar>
          </ImageUploader>
        </Grid>
        <Grid item xs>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              defaultValue={userState.user.customer?.username}
              required
              fullWidth
              id="username"
              label="Pseudonyme"
              name="username"
              autoComplete="username"
            />
            <TextField
              margin="normal"
              defaultValue={userState.user.customer?.lastname}
              required
              fullWidth
              id="lastname"
              label="Nom"
              name="lastname"
              autoComplete="lastname"
            />
            <TextField
              margin="normal"
              defaultValue={userState.user.customer?.firstname}
              required
              fullWidth
              id="firstname"
              label="Prénom"
              name="firstname"
              autoComplete="firstname"
            />
            <LoadingButton
              type="submit"
              fullWidth
              loading={editing}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}>
              Sauvegarder
            </LoadingButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="error"
            sx={{ mt: 3, mb: 2 }}
            startIcon={<Logout />}
            onClick={() => signOut(firebaseAppAuth)}>
            Déconnexion
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Dialog
            onClose={() => setOpenDialogDelete(false)}
            open={openDialogDelete}>
            <DialogTitle>
              Êtes-vous sûr de vouloir supprimer votre compte ?
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Cette action est irréversible et vous perdrez votre compte.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDialogDelete(false)}>
                Annuler
              </Button>
              <LoadingButton
                loading={removing}
                type="submit"
                onClick={async () => {
                  setRemoving(true);
                  try {
                    await deleteUserAccount();
                    signOut(firebaseAppAuth);
                  } catch (e) {
                    Logger.error(e);
                  }
                  setOpenDialogDelete(false);
                  setRemoving(false);
                }}>
                Oui
              </LoadingButton>
            </DialogActions>
          </Dialog>
          <Button
            fullWidth
            variant="contained"
            color="error"
            startIcon={<DeleteForever />}
            onClick={() => {
              setOpenDialogDelete(true);
            }}>
            Supprimer le compte
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
