import { Logger } from "@/common/error-handling";
import { createBusinessUser } from "@/common/firebase/services/owner-requests";
import { updateUser } from "@/common/firebase/services/user-requests";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { isValidPhoneNumber } from "libphonenumber-js";
import { MuiTelInput } from "mui-tel-input";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ObjectSchema, boolean, object, string } from "yup";
import { useSetCurrentPageName } from "../store/appSettingsStore";
import { useUserStore } from "../store/userStore";

export interface ValidateInput {
  phoneNumber: string;
  contactConsent: boolean;
}

const schema: ObjectSchema<ValidateInput> = object().shape({
  phoneNumber: string()
    .required()
    .test("isValidPhoneNumber", "Numéro de téléphone invalide", (value) => {
      return isValidPhoneNumber(value);
    }),
  contactConsent: boolean()
    .required()
    .oneOf([true], "You need to accept the terms and conditions"),
});

export default function ActivateBusinessUser() {
  useSetCurrentPageName("Informations supplémentaires");
  const user = useUserStore();
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    control,
  } = useForm<ValidateInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      phoneNumber: "+33",
      contactConsent: false,
    },
    mode: "onChange",
  });

  async function activateBusinessUser(formValues: ValidateInput) {
    if (!isValid || !user.user) {
      return;
    }
    try {
      setIsLoading(true);
      await createBusinessUser(user.user.uid, formValues.phoneNumber);
      await updateUser();
    } catch (e) {
      Logger.error(e);
    }
    setIsLoading(false);
  }

  return (
    <Box component="form" onSubmit={handleSubmit(activateBusinessUser)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ mb: 1 }}>
            Activez votre compte gérant
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Nous avons besoins d'informations supplémentaires avant d'activer
            votre compte et vous permettre d'ajouter votre établissement.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field }) => {
              return (
                <MuiTelInput
                  {...field}
                  error={!!errors.phoneNumber}
                  fullWidth
                  id="phoneNumber"
                  label="Numéro de téléphone"
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <FormControlLabel
            control={<Checkbox value={true} {...register("contactConsent")} />}
            label={
              <Typography
                variant="caption"
                color={errors.contactConsent ? "error" : ""}>
                Vous acceptez d'être contacté par un membre de notre équipe dans
                des buts de vérifications et de suivis de dossier.
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            loading={isLoading}
            type="submit"
            fullWidth
            variant="contained"
            disabled={!isValid}
            sx={{ mt: 3, mb: 3 }}>
            Activer le compte business
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
}
