import { getImageUrl } from "@/common/firebase/common";
import { mapEvents } from "@/common/firebase/mapper";
import { useGetBusinessEvents } from "@/common/firebase/services/owner-hooks";
import { BusinessEvent } from "@common/model/business";
import { LocationOn, Photo } from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Fade,
  IconButton,
  IconButtonProps,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

interface MapMarkerProps extends IconButtonProps {
  business?: any;
  hover?: boolean;
  name?: string;
  lat: number;
  lng: number;
  mode?: "dot" | "marker";
}

const K_WIDTH = 40;
const K_HEIGHT = 40;
export const MapMarker = ({
  business,
  hover,
  name,
  mode = "marker",
  ...props
}: MapMarkerProps) => {
  const { data: events } = useGetBusinessEvents(business?.firebase_key || null);
  const [popperHover, setPopperHover] = useState(false);
  const [todayEvent, setTodayEvent] = useState<BusinessEvent | undefined>();
  const ref = useRef<HTMLButtonElement>(null);
  const styles = {
    hovered: {
      transform: "scale(1.3)",
    },
  };

  useEffect(() => {
    if (!events) return;

    const mappedEvents = mapEvents(events);
    setTodayEvent(mappedEvents.today);
  }, [events]);

  return (
    <>
      {mode === "dot" ? (
        <Box
          sx={{
            width: 10,
            height: 10,
            borderRadius: "50%",
            backgroundColor: "red",
          }}></Box>
      ) : (
        <>
          <IconButton
            {...props}
            style={{
              ...greatPlaceStyle,
              ...(hover ? styles.hovered : {}),
            }}
            ref={ref}
            sx={{
              zIndex: 5,
              transition: (theme) =>
                theme.transitions.create(["background-color", "transform"], {
                  duration: theme.transitions.duration.standard,
                }),
              ":hover": {
                transform: "scale(1.3)",
              },
              p: 0,
            }}>
            <Badge
              invisible={!todayEvent}
              variant="dot"
              overlap="circular"
              color="error"
              slotProps={{ badge: { style: { margin: 3 } } }}>
              <LocationOn
                color={hover ? "warning" : "primary"}
                fontSize="large"
              />
            </Badge>
          </IconButton>
          <Popper
            open={popperHover || !!hover}
            anchorEl={ref.current}
            placement="top"
            onMouseEnter={() => setPopperHover(true)}
            onMouseLeave={() => setPopperHover(false)}
            transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper component="div" elevation={2}>
                  {business ? (
                    <List dense={true} sx={{ p: 0, maxWidth: 350 }}>
                      <ListItemButton
                        component={Link}
                        to={`/business/${business.firebase_key}`}>
                        <ListItemAvatar>
                          <Avatar
                            src={
                              business.cover_picture &&
                              getImageUrl(business.cover_picture.key)
                            }>
                            <Photo />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={business.name}
                          secondary={business.address?.street_address}
                        />
                      </ListItemButton>
                    </List>
                  ) : (
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper component="div" elevation={2}>
                        <List dense={true} sx={{ px: 2, maxWidth: 250 }}>
                          <Typography variant="caption">
                            Malheureusement <i>'{name}'</i> n'est pas
                            enregistré.
                          </Typography>
                        </List>
                      </Paper>
                    </Fade>
                  )}
                </Paper>
              </Fade>
            )}
          </Popper>
        </>
      )}
    </>
  );
};

const greatPlaceStyle: CSSProperties = {
  // initially any map object has left top corner at lat lng coordinates
  // it's on you to set object origin to 0,0 coordinates
  position: "absolute",
  width: K_WIDTH,
  height: K_HEIGHT,
  left: -K_WIDTH / 2,
  top: -K_HEIGHT / 2,
  textAlign: "center",
  color: "#3f51b5",
};
