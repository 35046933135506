import { Groups, Payment, Settings, Store } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { MenuNavItem } from "../layout/OwnerLayout";
import { useBusinessSubscription } from "./useBusinessSubscription";

export function useOwnerMenu() {
  const { subscriptionMode } = useBusinessSubscription(undefined);
  const [ownerMenu, setOwnerMenu] = useState<MenuNavItem[][]>([
    [
      {
        label: "Établissements",
        to: "/owner/business/list",
        icon: <Store />,
      },
      {
        label: "Utilisateurs",
        to: "/owner/users",
        icon: <Groups />,
      },
      {
        label: "Paramètres",
        to: "/owner/settings",
        icon: <Settings />,
      },
    ],
  ]);

  useEffect(() => {
    if (subscriptionMode) {
      setOwnerMenu([
        [
          ownerMenu[0][0],
          ownerMenu[0][1],
          {
            label: "Facturation",
            to: "/owner/billing",
            icon: <Payment />,
          },
          ownerMenu[0][2],
        ],
      ]);
    }
  }, [subscriptionMode]);

  return ownerMenu;
}
