import {
  Done,
  ExpandMore,
  Face,
  LocalBar,
  NotInterested,
} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItem,
  Skeleton,
  Stack,
  SxProps,
} from "@mui/material";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { green } from "@mui/material/colors";
import dayjs from "dayjs";

import PicturePreview from "@/common/components/PicturePreview";
import { Logger } from "@/common/error-handling";
import { getImageUrl } from "@/common/firebase/common";
import {
  useBusinessDocumentForPublic,
  useBusinessScansForUser,
} from "@/common/firebase/services/user-hooks";
import {
  subscribeToBusiness,
  unsubscribeToBusiness,
  updateUser,
} from "@/common/firebase/services/user-requests";
import { useHasOffer } from "@/common/hooks/useHasOffer";
import { getClaimQrCodeValue } from "@/common/qr-code";
import NoImage from "@assets/no-image.jpg";
import { ProgramType } from "@common/index";
import {
  Business,
  ProgramLottery,
  ProgramLoyalty,
} from "@common/model/business";
import { useEffect, useMemo, useState } from "react";
import QRCode from "react-qr-code";
import { useParams } from "react-router";
import { NavLink, useSearchParams } from "react-router-dom";
import { BusinessOpeningHours } from "../common/components/BusinessOpeningHours";
import NotFound from "../common/components/NotFound";
import { useIsOpen } from "../common/hooks/useIsOpen";
import {
  useAppSettingsStore,
  useSetCurrentPageName,
} from "../store/appSettingsStore";
import { useUserStore } from "../store/userStore";

export const SubscribeButton = ({ businessId }: { businessId: string }) => {
  const { user } = useUserStore();
  const [loading, setLoading] = useState(false);
  const { mutate } = useBusinessDocumentForPublic(businessId);

  const isSubscribed = useMemo(() => {
    return !!user?.customer?.subscribed_businesses?.find(
      (business) => business.id === businessId
    );
  }, [user]);

  const subsribe = async () => {
    if (!user || !businessId) return;
    try {
      setLoading(true);
      if (isSubscribed) {
        await unsubscribeToBusiness(user.uid, businessId);
      } else {
        await subscribeToBusiness(user.uid, businessId);
      }
      await updateUser();
      mutate();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      Logger.error(e);
    }
  };

  return (
    <LoadingButton
      loading={loading}
      variant={isSubscribed ? "contained" : "outlined"}
      onClick={subsribe}>
      {isSubscribed ? "Se désabonner" : "S'abonner"}
    </LoadingButton>
  );
};

export const LotteryView = ({
  businessId,
  program,
  userId,
}: {
  businessId: string;
  program: ProgramLottery;
  userId: string;
}) => {
  const { scans, isLoading } = useBusinessScansForUser(
    businessId,
    userId,
    program.id,
    true
  );

  if (isLoading) {
    return <Skeleton variant="rectangular" width="100%" height="64px" />;
  }

  if (scans.length) {
    return (
      <Alert severity="info">
        Tu as participé ! {scans.length > 1 && scans.length + " fois"}
        {program.drawResults?.some(
          (result) => result.data.customer_id === userId
        ) && `Tu as gagné !`}
        <br />
        <b>
          {program.drawResults &&
            program.reward[
              program.drawResults.findIndex(
                (result) => result.customer.id === userId
              )
            ].label}
        </b>
      </Alert>
    );
  }
};

export const LoyaltyView = ({
  business,
  program,
  userId,
  sx = {},
}: {
  business: Business;
  program: ProgramLoyalty;
  userId: string;
  sx?: SxProps;
}) => {
  const [openRewardDialog, setOpenRewardDialog] = useState(false);
  const { scans, isLoading } = useBusinessScansForUser(
    business.id,
    userId,
    program.id
  );
  const qrCodeValue = useMemo(
    () =>
      getClaimQrCodeValue(userId, business.id, program.id, ProgramType.loyalty),
    []
  );

  if (isLoading) {
    return (
      <AvatarGroup max={10} sx={{ justifyContent: "flex-end" }}>
        <Skeleton variant="circular" animation="wave">
          <Avatar />
        </Skeleton>
        <Skeleton variant="circular" animation="wave">
          <Avatar />
        </Skeleton>
        <Skeleton variant="circular" animation="wave">
          <Avatar />
        </Skeleton>
        <Skeleton variant="circular" animation="wave">
          <Avatar />
        </Skeleton>
        <Skeleton variant="circular" animation="wave">
          <Avatar />
        </Skeleton>
      </AvatarGroup>
    );
  }

  return (
    <>
      <div>
        <Grid
          container
          sx={{ flexWrap: "wrap", justifyContent: "center" }}
          spacing={1}>
          {[...Array(program.conditions.fulfillItemNumber)].map((_, index) => (
            <Grid item key={index}>
              {index + 1 <=
              scans.length % program.conditions.fulfillItemNumber ? (
                <Avatar
                  key={scans[index].id}
                  sx={{ bgcolor: green[500] }}
                  alt="Complet">
                  <LocalBar />
                </Avatar>
              ) : (
                <Avatar alt="Complet" key={index}>
                  <LocalBar />
                </Avatar>
              )}
            </Grid>
          ))}
        </Grid>
        {[
          ...Array(
            Math.trunc(scans.length / program.conditions.fulfillItemNumber)
          ),
        ].map((_, i) => {
          const scanLength =
            scans.length - i * program.conditions.fulfillItemNumber;
          return (
            <Grid
              key={"program_" + i}
              container
              sx={{ flexWrap: "wrap", justifyContent: "center" }}
              spacing={1}>
              {[...Array(program.conditions.fulfillItemNumber)].map(
                (_, index) => (
                  <Grid item key={scans[index].id}>
                    {index + 1 <= scanLength ? (
                      <Avatar sx={{ bgcolor: green[500] }} alt="Complet">
                        <LocalBar />
                      </Avatar>
                    ) : (
                      <Avatar key={index} alt="Complet">
                        <LocalBar />
                      </Avatar>
                    )}
                  </Grid>
                )
              )}
            </Grid>
          );
        })}
      </div>
      {program.conditions.fulfillItemNumber <= scans.length && (
        <Box sx={{ pt: 1, textAlign: "center" }}>
          <Button
            variant="contained"
            color="success"
            onClick={(e) => {
              e.preventDefault();
              setOpenRewardDialog(true);
            }}>
            Réclamer{" "}
            {Math.trunc(scans.length / program.conditions.fulfillItemNumber) > 1
              ? `(${Math.trunc(
                  scans.length / program.conditions.fulfillItemNumber
                )} récompenses)`
              : ``}
          </Button>
        </Box>
      )}
      <Dialog maxWidth="xs" open={openRewardDialog}>
        <DialogTitle>Félicitations !</DialogTitle>
        <DialogContent dividers>
          <Typography variant="subtitle1">
            Vous avez gagné{" "}
            <b>
              {program.conditions.rewardItemNumber}{" "}
              {program.conditions.rewardItemType.label}
            </b>{" "}
            ({program.reward[0].label}) au <b>{business.name}</b>
          </Typography>
          <Typography variant="subtitle2" sx={{ py: 2 }}>
            Montrez ce QR code à un membre du personnel de l'établissement pour
            récupérer votre récompense.
          </Typography>

          <Box sx={{ py: 2, textAlign: "center" }}>
            <QRCode value={qrCodeValue} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setOpenRewardDialog(false);
            }}>
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function View({ id, userId }: { id: string; userId?: string }) {
  const setPageName = useSetCurrentPageName("Établissement");
  const { business, isLoading } = useBusinessDocumentForPublic(id, userId);
  const [searchParams] = useSearchParams();
  const { hasFeature, config } = useAppSettingsStore();
  const [openPicturePreviewIndex, setOpenPicturePreviewIndex] =
    useState<number>();

  const canLottery = useMemo(() => hasFeature(ProgramType.lottery), [config]);
  const canLoyalty = useMemo(() => hasFeature(ProgramType.loyalty), [config]);

  useEffect(() => {
    if (business) {
      setPageName(business.name);
    }
  }, [business]);

  const isPreview = useMemo(() => {
    return searchParams.has("preview") && business?.hasOwnership;
  }, [searchParams, business]);

  const isOpen = useIsOpen(business);
  const { todayOffers, currentOffer } = useHasOffer(business);

  if (isLoading)
    return (
      <Grid container rowSpacing={2} columnSpacing={2.75} sx={{ pt: 1, pb: 2 }}>
        <Grid item xs={12}>
          <Card>
            <Grid container sx={{ width: 1 }}>
              <Grid item xs={12} sm={"auto"} sx={{ position: "relative" }}>
                <Skeleton
                  variant="rectangular"
                  sx={{ width: 1, minWidth: 150, height: 200 }}
                />
              </Grid>
              <Grid item xs>
                <CardContent>
                  <Grid container rowSpacing={2}>
                    <Grid item xs>
                      <Skeleton variant="text" width="74%" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" width="66%" />
                    </Grid>
                  </Grid>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" width="100%" height={54} />
        </Grid>
        <Grid item xs={12}>
          <Grid container columnSpacing={2.75}>
            <Grid item xs={12} sm={6}>
              <Grid container>
                <Grid item xs>
                  <Skeleton width="66%" />
                </Grid>
              </Grid>
              <Skeleton height={54} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container>
                <Grid item xs>
                  <Skeleton width="66%" />
                </Grid>
              </Grid>
              <Skeleton height={54} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton height={254} sx={{ transform: "none" }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack spacing={2}>
            <Skeleton height={74} sx={{ transform: "none" }} />
            <Skeleton height={74} sx={{ transform: "none" }} />
            <Skeleton height={74} sx={{ transform: "none" }} />
          </Stack>
        </Grid>
      </Grid>
    );

  if (!business) return <NotFound />;

  if (business.status === "draft" && !isPreview) {
    return (
      <Grid item xs={12}>
        <Alert severity="warning">
          Cet établissement n'est pas encore public.
        </Alert>
      </Grid>
    );
  }

  return (
    <Grid container rowSpacing={2} columnSpacing={2.75} sx={{ pt: 1, pb: 2 }}>
      {isPreview && (
        <Grid item xs={12}>
          <Alert severity="warning">
            Votre page apparaitra comme ceci aux autres utilisateurs.{" "}
            <NavLink to={"/owner/business/" + business.id}>Retour</NavLink>
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <Card>
          <Grid container sx={{ width: 1 }}>
            <Grid item xs={12} sm={3} sx={{ position: "relative" }}>
              <CardMedia
                component="img"
                image={
                  (business.cover_picture &&
                    getImageUrl(business.cover_picture.key)) ||
                  NoImage
                }
                sx={{ width: 1, height: 200 }}
              />
              {business.events?.today && (
                <Stack
                  spacing={2}
                  sx={{
                    position: "absolute",
                    top: 10,
                    left: 10,
                    display: { sm: "none" },
                  }}>
                  <Box>
                    <Chip label="Évènement en cours" color="success" />
                  </Box>
                </Stack>
              )}
              <Stack
                spacing={2}
                sx={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  display: { sm: "none" },
                }}>
                <Box>
                  <Chip
                    label={isOpen ? "Ouvert" : "Fermé"}
                    color={isOpen ? "success" : "error"}
                    icon={isOpen ? <Done /> : <NotInterested />}
                  />
                </Box>
                <Box>
                  <Chip
                    icon={<Face />}
                    color="info"
                    label={`${business?.subscribed_customers_count} Abonnés`}
                  />
                </Box>
                {currentOffer && (
                  <Box>
                    <Chip
                      icon={<LocalBar />}
                      color="info"
                      label={`Promotions`}
                    />
                  </Box>
                )}
              </Stack>
            </Grid>
            <Grid item xs>
              <CardContent>
                <Grid container rowSpacing={2}>
                  <Grid item xs>
                    <Typography component="div" variant="h5">
                      {business.name}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      component="div">
                      {business.address.street_address}{" "}
                      {business.address.street_address_addition}{" "}
                      {business.address.city}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                      {userId && <SubscribeButton businessId={id} />}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={"auto"}
                    sx={{ display: { xs: "none", sm: "block" } }}>
                    <Stack spacing={2}>
                      <Box>
                        <Chip
                          label={isOpen ? "Ouvert" : "Fermé"}
                          color={isOpen ? "success" : "error"}
                          icon={isOpen ? <Done /> : <NotInterested />}
                        />
                      </Box>
                      {business.events?.today && (
                        <Box>
                          <Chip label="Évènement en cours" color="info" />
                        </Box>
                      )}
                      <Box>
                        <Chip
                          icon={<Face />}
                          label={`${business?.subscribed_customers_count} Abonnés`}
                        />
                      </Box>
                      {currentOffer && (
                        <Box>
                          <Chip
                            icon={<LocalBar />}
                            color="info"
                            label={`Promotions`}
                          />
                        </Box>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      {todayOffers &&
        todayOffers.map((offer) => (
          <Grid key={offer.id} item xs={12} sm={6}>
            <Alert icon={false} color="warning" className="alert-fullwidth">
              <Grid container>
                <Grid item xs>
                  <AlertTitle>Promotions</AlertTitle>
                </Grid>
                <Grid item xs="auto">
                  <Chip
                    label={`${offer.start_hour}h - ${offer.end_hour}h`}></Chip>
                </Grid>
              </Grid>
              {offer.description}
            </Alert>
          </Grid>
        ))}
      {business.description && (
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography>{business.description}</Typography>
            </CardContent>
          </Card>
        </Grid>
      )}
      <Grid item xs={12}>
        <Card>
          <CardHeader subheader="Menu" sx={{ pt: 1, pb: 0 }}></CardHeader>
          <CardContent sx={{ py: 1 }}>
            <Grid
              container
              spacing={1}
              columns={(business?.menu_pictures?.length || 0) % 4 || 4}>
              {!business?.menu_pictures?.length && (
                <Grid item>Aucun menu ajouté</Grid>
              )}
              {business?.menu_pictures?.map((pic, i) => (
                <Grid item key={pic.key} xs={1}>
                  <Box
                    onClick={() => setOpenPicturePreviewIndex(i)}
                    sx={{
                      maxHeight: 150,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "aliceblue",
                      cursor: "pointer",
                      overflow: "hidden",
                    }}>
                    <img
                      srcSet={`${getImageUrl(pic.key)}`}
                      src={`${getImageUrl(pic.key)}`}
                      loading="lazy"
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {canLoyalty && (
        <Grid item xs={12} sm={6}>
          <Grid container>
            <Grid item xs>
              <ListItem disablePadding>
                <Typography variant="h6" component="h6">
                  Programme de fidélité
                </Typography>
              </ListItem>
            </Grid>
          </Grid>
          {business.programs?.filteredLoyalty.active ? (
            <Card>
              <CardContent>
                <Stack spacing={2}>
                  <Alert severity="info">
                    Obtiens{" "}
                    {
                      business.programs.filteredLoyalty.active.conditions
                        .rewardItemNumber
                    }{" "}
                    {
                      business.programs.filteredLoyalty.active.conditions
                        .rewardItemType.label
                    }{" "}
                    au bout de{" "}
                    {
                      business.programs.filteredLoyalty.active.conditions
                        .fulfillItemNumber
                    }{" "}
                    {
                      business.programs.filteredLoyalty.active.conditions
                        .fullfillItemType.label
                    }
                    .
                  </Alert>
                  {userId && (
                    <LoyaltyView
                      business={business}
                      program={business.programs.filteredLoyalty.active}
                      userId={userId}
                    />
                  )}
                </Stack>
              </CardContent>
              {/* <CardActions sx={{ justifyContent: "end" }}>
                  <Button variant="contained">Réclamer</Button>
                </CardActions> */}
            </Card>
          ) : (
            <Alert severity="info">Aucun programme pour le moment.</Alert>
          )}
        </Grid>
      )}
      {canLottery && (
        <Grid item xs={12} sm={6}>
          <Grid container>
            <Grid item xs>
              <ListItem disablePadding>
                <Typography variant="h6" component="h6">
                  Lotterie
                </Typography>
              </ListItem>
            </Grid>
          </Grid>
          {business.programs?.filteredLottery.active ||
          business.programs?.filteredLottery.archived?.length ? (
            <Stack spacing={2}>
              {business.programs.filteredLottery.active && (
                <Card sx={{ p: 1 }}>
                  <Alert severity="success">
                    {business.programs.filteredLottery.active.title} -
                    {
                      business.programs.filteredLottery.active.conditions
                        .numberOfWinnersToDraw
                    }{" "}
                    Gagnants
                    {business.programs.filteredLottery.active.conditions
                      .limit_date &&
                      ` | Tirage le ${dayjs(
                        business.programs.filteredLottery.active.conditions
                          .limit_date
                      ).format("MM/DD/YYYY - HH:mm")}`}
                  </Alert>
                  {userId && (
                    <LotteryView
                      businessId={business.id}
                      program={business.programs.filteredLottery.active}
                      userId={userId}
                    />
                  )}
                </Card>
              )}

              {business.programs.filteredLottery.archived?.[0] && (
                <Card sx={{ p: 1 }}>
                  <Alert severity="success">
                    {business.programs.filteredLottery.archived[0].title} -{" "}
                    {
                      business.programs.filteredLottery.archived[0].conditions
                        .numberOfWinnersToDraw
                    }{" "}
                    Gagnants
                    {business.programs.filteredLottery.archived[0]
                      .archived_at &&
                      ` | Tirée le ${dayjs(
                        business.programs.filteredLottery.archived[0].archived_at.toDate()
                      ).format("MM/DD/YYYY - HH:mm")}`}
                  </Alert>

                  {userId && (
                    <LotteryView
                      businessId={business.id}
                      program={business.programs.filteredLottery.archived[0]}
                      userId={userId}
                    />
                  )}
                </Card>
              )}
            </Stack>
          ) : (
            <Alert severity="info">Aucune lotterie pour le moment.</Alert>
          )}
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <Stack spacing={1}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Évènements
          </Typography>
          {business.events?.today && (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Stack spacing={1} direction={"row"} useFlexGap flexWrap="wrap">
                  <Typography variant="h6">
                    {business.events.today.title}
                  </Typography>
                  <Chip
                    label={
                      "Aujourd'hui - " +
                      dayjs(business.events.today.date_start.toDate())
                        .utcOffset(business.utc_offset_minutes)
                        .format("HH:mm")
                    }
                    color="success"
                  />
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{business.events.today.description}</Typography>
              </AccordionDetails>
            </Accordion>
          )}
          {business.events?.future.length ? (
            business.events.future.slice(0, 4).map((event, i) => {
              const dayjsDate = dayjs(event.date_start.toDate()).utcOffset(
                business.utc_offset_minutes
              );
              return (
                <Accordion key={i}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Stack
                      spacing={1}
                      direction={"row"}
                      useFlexGap
                      flexWrap="wrap">
                      <Typography variant="h6">{event.title}</Typography>
                      <Chip
                        label={dayjsDate.format("MM/DD/YYYY - HH:mm")}
                        color="success"
                      />
                      <Chip
                        label={dayjsDate.fromNow()}
                        color={dayjsDate.isAfter() ? "info" : "warning"}
                      />
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{event.description}</Typography>
                  </AccordionDetails>
                </Accordion>
              );
            })
          ) : (
            <>
              <Alert severity="warning">Aucun évènement à venir.</Alert>
            </>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card>
          <CardHeader
            subheader={"Heures d'ouverture"}
            sx={{ pb: 0 }}></CardHeader>
          <CardContent>
            <BusinessOpeningHours business={business} isOpen={isOpen} />
          </CardContent>
        </Card>
      </Grid>
      {openPicturePreviewIndex !== undefined && (
        <PicturePreview
          pictureList={
            business?.menu_pictures?.map((pic) => getImageUrl(pic.key)) || []
          }
          activeIndex={openPicturePreviewIndex}
          setActiveIndex={setOpenPicturePreviewIndex}
        />
      )}
    </Grid>
  );
}

export default function BusinessView() {
  const { id } = useParams();
  const { user } = useUserStore();

  if (!id) return <div>loading...</div>;

  return (
    <Container component="main" maxWidth="lg" sx={{ overflow: "unset", mt: 2 }}>
      <View id={id} userId={user?.uid} />
    </Container>
  );
}
