import { ProgramType } from "@common/index";
import { AppConfig } from "@common/model/config";
import { useEffect } from "react";
import { create } from "zustand";

interface AppSettingsState {
  currentPageName: string;
  config: AppConfig | null;
  setCurrentPageName: (title: string) => void;
  setConfig: (config: AppConfig) => void;
  hasFeature: (feature: ProgramType) => boolean;
}

export const useAppSettingsStore = create<AppSettingsState>((set, get) => ({
  currentPageName: "",
  config: null,
  setCurrentPageName: (title: string) => set({ currentPageName: title }),
  setConfig: (config: AppConfig) => set({ config }),
  hasFeature: (feature: ProgramType) => !!get().config?.features?.[feature],
}));

export const useSetCurrentPageName = (pageName?: string) => {
  const setCurrentPageName = useAppSettingsStore(
    (state) => state.setCurrentPageName
  );
  useEffect(() => {
    if (pageName) {
      setCurrentPageName(pageName);
    }
  }, []);

  return setCurrentPageName;
};
