import { Logger } from "@/common/error-handling";
import { Capacitor } from "@capacitor/core";
import { InAppBrowser } from "@capgo/inappbrowser";
import { FBCloudFunctions, FBCollections } from "@common/firebase";
import { Business } from "@common/index";
import {
  Timestamp,
  addDoc,
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import Stripe from "stripe";
import useSWR from "swr";
import { firebaseAppFirestoreDb, firebaseAppFunctions } from "../config";

export const useGetBusinessSubscription = (businessId: string) => {
  const { data, error, isLoading, mutate } = useSWR(
    "useGetBusinessSubscription" + businessId,
    () => getBusinessSubscription(businessId),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return {
    collection: data,
    isLoading,
    isError: error,
    mutate,
  };
};

export const getBusinessSubscription = async (
  businessId: string
): Promise<any> => {
  const doc = await getDocs(
    query(
      collection(
        firebaseAppFirestoreDb,
        FBCollections.Businesses,
        businessId,
        "stripe_subscriptions"
      ),
      where("status", "in", ["trialing", "active"])
    )
  ).then((ref) => {
    if (!ref.docs[0]) {
      return;
    }
    return {
      id: ref.docs[0].id,
      ...ref.docs[0].data(),
    } as any;
  });

  if (!doc) {
    return;
  }

  const invoices = (
    await getDocs(
      query(
        collection(
          firebaseAppFirestoreDb,
          "business_customers",
          doc.metadata.userId,
          "subscriptions",
          doc.id,
          "invoices"
        )
      )
    )
  ).docs.map((invoiceRef) => {
    return {
      id: invoiceRef.id,
      ...invoiceRef.data(),
    };
  });

  return {
    ...doc,
    invoices,
  };
};

export const useGetProductsPrice = () => {
  const { data, error, isLoading, mutate } = useSWR(
    "useGetProductsPrice",
    () => getProductsPrices(),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return {
    collection: data,
    isLoading,
    isError: error,
    mutate,
  };
};

export const getProductsPrices = async () => {
  const products = await getDocs(
    query(
      collection(firebaseAppFirestoreDb, "business_products"),
      where("active", "==", true)
    )
  );

  return await Promise.all(
    products.docs.map(async function (doc) {
      const priceSnap = await getDocs(collection(doc.ref, "prices"));
      priceSnap.docs.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());
      });

      return {
        id: doc.id,
        ...doc.data(),
        price: priceSnap.docs.map((doc) => {
          return { id: doc.id, ...doc.data() };
        })[0],
      };
    })
  );
};

export const createSubscriptionCheckout = async (
  userId: string,
  business: Business,
  priceId: string,
  redirectSuccess: string,
  redirectCancel: string,
  callback: (err: boolean) => void
): Promise<void> => {
  const isNative = Capacitor.isNativePlatform();
  const cancelUrl = isNative
    ? import.meta.env.VITE_WEBSITE_URL +
      "/payment/cancel?redirectUrl=" +
      redirectCancel
    : import.meta.env.VITE_APP_URL + redirectCancel;
  const successUrl = isNative
    ? import.meta.env.VITE_WEBSITE_URL +
      "/payment/success?redirectUrl=" +
      redirectSuccess
    : import.meta.env.VITE_APP_URL + redirectSuccess;

  const payload: any = {
    //   automatic_tax: true,
    price: priceId,
    line_items: [
      {
        price: priceId,
        quantity: 1,
      },
      {
        quantity: 1,
        price_data: {
          currency: "eur",
          unit_amount: 0,
          recurring: {
            interval: "month",
          },
          product_data: {
            name: "Établissement " + business.name,
          },
        },
      },
    ],
    cancel_url: cancelUrl,
    success_url: successUrl,
    metadata: {
      businessId: business.id,
      userId,
    },
    description: "Établissement " + business.name,
    invoice_creation: true,
    invoice_data: {
      description: "Test invoice",
    },
  };

  const docRef = await addDoc(
    collection(
      firebaseAppFirestoreDb,
      "business_customers",
      userId,
      "checkout_sessions"
    ),
    payload
  );
  // Wait for the CheckoutSession to get attached by the extension
  onSnapshot(docRef, async (snap) => {
    const { error, url } = snap.data() as any;
    if (error) {
      // Show an error to your customer and
      // inspect your Cloud Function logs in the Firebase console.
      alert(`An error occured: ${error.message}`);
      callback(true);
    }
    if (url) {
      if (Capacitor.isNativePlatform()) {
        try {
          await InAppBrowser.open({ url });
          const browserHandlerCloseEvent = await InAppBrowser.addListener(
            "closeEvent",
            () => {
              callback(true);
              Logger.error("Paiement intérompu");
              browserHandlerCloseEvent.remove();
            }
          );

          const browserHandlerUrlEvent = await InAppBrowser.addListener(
            "urlChangeEvent",
            (changedRoute) => {
              if (
                changedRoute.url.search(successUrl) > 0 ||
                changedRoute.url.search(cancelUrl) > 0
              ) {
                callback(false);
                browserHandlerUrlEvent.remove();
              }
            }
          );

          const docRef = await query(
            collection(
              firebaseAppFirestoreDb,
              "business_customers",
              userId,
              "payments"
            ),
            where("created", ">", Timestamp.now().seconds),
            orderBy("created", "desc")
          );
          const unsubscribe = onSnapshot(docRef, (snap) => {
            if (snap.empty) {
              return;
            }
            const { error, status, ...data } = snap.docs[0].data() as any;

            // TODO add businessId as metadata to the payment to better match
            if (status === "succeeded") {
              setTimeout(async () => {
                await InAppBrowser.close();
                callback(false);
              }, 1000);
              unsubscribe();
              browserHandlerCloseEvent.remove();
              browserHandlerUrlEvent.remove();
            }
            if (error) {
              Logger.error(error.message);
              setTimeout(async () => {
                await InAppBrowser.close();
                callback(true);
              }, 1000);
              unsubscribe();
              browserHandlerCloseEvent.remove();
              browserHandlerUrlEvent.remove();
            }
          });
        } catch (e) {
          Logger.error(e);
        }
      } else {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
        callback(false);
      }
    }
  });
};

export function useListStripePaymentMethods() {
  const { data, error, isLoading, mutate } = useSWR(
    "useListStripePaymentMethods",
    async () => {
      const functionRef = httpsCallable(
        firebaseAppFunctions,
        FBCloudFunctions.GetPaymentMethods
      );

      const { data } = await functionRef();
      const paymentMethods = (data as any)?.data as Stripe.PaymentMethod[];
      return paymentMethods;
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return {
    collection: data,
    isLoading,
    isError: error,
    mutate,
  };
}
