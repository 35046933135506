import Box from "@mui/material/Box";

import { useResponsive } from "@/common/layout/hooks/use-responsive";

import { SxProps, Theme } from "@mui/material";
import { PropsWithChildren } from "react";
import { HEADER, NAV } from "./config-layout";

// ----------------------------------------------------------------------

const SPACING = 8;

export default function Main({
  children,
  sx,
  ...other
}: PropsWithChildren & { sx?: SxProps<Theme> }) {
  const lgUp = useResponsive("up", "lg");

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        minHeight: 1,
        display: "flex",
        flexDirection: "column",
        py: `${HEADER.H_MOBILE}px`,
        ...(lgUp && {
          px: 0,
          py: `${HEADER.H_DESKTOP}px`,
          width: `calc(100% - ${NAV.WIDTH}px)`,
        }),
        ...sx,
      }}
      {...other}>
      {children}
    </Box>
  );
}
