import { Logger } from "@/common/error-handling";
import {
  fileUpload,
  generateFirestoreId,
  getImageUrl,
} from "@/common/firebase/common";
import {
  addBusinessEvent,
  updateBusinessEvent,
} from "@/common/firebase/services/owner-requests";
import { useUserStore } from "@/store/userStore";
import NoImage from "@assets/no-image.jpg";
import { FBCollections } from "@common/firebase";
import { Image } from "@common/index";
import {
  BusinessEvent,
  BusinessEventCreate,
  BusinessEventForm,
} from "@common/model/business";
import { yupResolver } from "@hookform/resolvers/yup";
import { Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Timestamp } from "firebase/firestore";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { date, object, string } from "yup";
import { BusinessFormComponentProps } from "../tabs/BusinessDashboard";

interface EventFormProps extends BusinessFormComponentProps {
  currentEvent?: BusinessEvent;
}

const schema = object({
  title: string().required(),
  description: string().required(),
  created_at: date().required(),
  date_start: date().required(),
  date_end: date().required(),
  updated_at: date().required(),
});

const images = [
  {
    url: NoImage,
  },
  {
    url: NoImage,
  },
  {
    url: NoImage,
  },
];

export const EventForm = ({
  business,
  handleClose,
  mutate,
  currentEvent,
}: EventFormProps) => {
  const user = useUserStore();
  const [loading, setLoading] = useState(false);
  const [imageProgress, setImageProgress] = useState<number[]>([]);
  const [selectedImage, setSelectedImage] = useState<(File | Image)[]>(
    currentEvent?.images || []
  );
  const inputFileRef = useRef<HTMLInputElement>(null);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    control,
    setValue,
    resetField,
  } = useForm<BusinessEventForm>({
    resolver: yupResolver<BusinessEventForm>(schema),
    defaultValues: {
      ...currentEvent,
      created_at: currentEvent?.created_at.toDate() || new Date(),
      updated_at: new Date(),
      date_end:
        (currentEvent?.date_end &&
          (dayjs(currentEvent?.date_end.toDate()) as any)) ||
        null,
      date_start:
        (currentEvent?.date_start &&
          (dayjs(currentEvent?.date_start.toDate()) as any)) ||
        null,
    },
  });

  const createEvent = async (formData: BusinessEventForm) => {
    if (currentEvent) {
      await updateEvent(formData, currentEvent.id);
      return;
    }

    try {
      setLoading(true);
      const eventValues: BusinessEventCreate = {
        ...formData,
        created_at: Timestamp.now(),
        updated_at: Timestamp.now(),
        date_start: Timestamp.fromDate(formData.date_start),
        date_end: Timestamp.fromDate(formData.date_end),
      };
      const insertedEvent = await addBusinessEvent(business.id, eventValues);

      if (selectedImage.length) {
        insertedEvent.images = [];
        for (const [i, image] of selectedImage.entries()) {
          if (!(image instanceof File)) {
            continue;
          }
          const uuid = generateFirestoreId();
          await fileUpload(
            image,
            FBCollections.Businesses,
            business.id + "/events/" + insertedEvent.id,
            user.user!.uid,
            (progress) => {
              imageProgress[i] = progress;
              setImageProgress([...imageProgress]);
            },
            (isLoading) => {},
            async (url) => {
              insertedEvent.images?.push({
                key: url.key,
                created_at: Timestamp.now(),
                created_by: user.user!.uid,
              });
              await updateBusinessEvent(business.id, insertedEvent.id, {
                images: insertedEvent.images,
              });

              if (i === selectedImage.length - 1) {
                closeHandler();
              }
            },
            (error) => {
              if (i === selectedImage.length - 1) {
                Logger.error(error);
                closeHandler();
              }
            }
          );
        }
        closeHandler();
      } else {
        closeHandler();
      }
    } catch (e) {
      setLoading(false);
      Logger.error(e);
    }
  };

  function closeHandler(isUpdate = false) {
    mutate();
    setLoading(false);
    Logger.success(isUpdate ? "Évènement mis à jour" : "Évènement créé");
    handleClose();
  }

  const updateEvent = async (
    formData: BusinessEventForm,
    currentEventId: string
  ) => {
    try {
      setLoading(true);
      const { created_at, ...data } = formData;
      const eventValues: Partial<BusinessEvent> = {
        ...data,
        date_start: Timestamp.fromDate(formData.date_start),
        date_end: Timestamp.fromDate(formData.date_end),
        updated_at: Timestamp.now(),
      };

      const images = selectedImage.filter(
        (i) => !(i instanceof File)
      ) as Image[];
      eventValues.images = images;

      const insertedEvent = await updateBusinessEvent(
        business.id,
        currentEventId,
        eventValues
      );

      if (selectedImage.length) {
        insertedEvent.images = [];
        for (const [i, image] of selectedImage.entries()) {
          if (!(image instanceof File)) {
            if (i === selectedImage.length - 1) {
              closeHandler(true);
            }
            continue;
          }
          const uuid = generateFirestoreId();
          await fileUpload(
            image,
            FBCollections.Businesses,
            business.id + "/events/" + insertedEvent.id,
            user.user!.uid,
            (progress) => {
              imageProgress[i] = progress;
              setImageProgress([...imageProgress]);
            },
            (isLoading) => {},
            async (url) => {
              insertedEvent.images?.push({
                key: url.key,
                created_at: Timestamp.now(),
                created_by: user.user!.uid,
              });
              await updateBusinessEvent(business.id, insertedEvent.id, {
                images: insertedEvent.images,
              });
              if (i === selectedImage.length - 1) {
                closeHandler(true);
              }
            },
            (error) => {
              if (i === selectedImage.length - 1) {
                Logger.error(error);
                closeHandler(true);
              }
            }
          );
        }
      } else {
        closeHandler(true);
      }
    } catch (e) {
      setLoading(false);
      Logger.error(e);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(createEvent)}
      noValidate
      sx={{ mt: 1 }}>
      <Typography variant="h5" sx={{ mb: 2, mt: 2 }}>
        Informations
      </Typography>

      <Stack spacing={1}>
        <TextField
          {...register("title")}
          error={!!errors.title}
          id="demo-simple-select"
          fullWidth
          label={"Nom de l'évènement"}
          required
        />
        <TextField
          {...register("description")}
          multiline
          rows={3}
          error={!!errors.description}
          fullWidth
          placeholder="Profite de notre lotterie de juin pour fêter le printemps et tenter de gagner une bouteille de vin !"
          sx={{ minHeight: 45 }}
          InputProps={{
            inputComponent: TextareaAutosize,
            inputProps: {
              maxRows: 6,
              minRows: 4,
            },
          }}
          required
        />

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
          <Controller
            name="date_start"
            control={control}
            render={({ field }) => (
              <DateTimePicker
                {...field}
                sx={{ width: 1 }}
                label="Date de début"
                disablePast
                onChange={(event: any | null, values) => {
                  field.onChange(event, values);
                  if (event) {
                    setValue("date_end", event.add(1, "hour"));
                  } else {
                    resetField("date_end");
                  }
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: !!errors.date_start,
                    required: true,
                  },
                }}
              />
            )}
          />
          <Controller
            name="date_end"
            control={control}
            render={({ field }) => (
              <DateTimePicker
                {...field}
                sx={{ width: 1 }}
                label="Date de fin"
                disablePast
                minDateTime={control._formValues.date_start}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: !!errors.date_end,
                    required: true,
                  },
                }}
              />
            )}
          />
        </LocalizationProvider>
        <input
          type="file"
          max={1}
          ref={inputFileRef}
          hidden
          onChange={(e) => {
            e.target.files &&
              e.target.files.length &&
              setSelectedImage([...selectedImage, e.target.files[0]]);
          }}
        />
        <ImageList variant="masonry" sx={{ width: 1 }} cols={3} rowHeight={164}>
          {images.map((item, i) => {
            const image = selectedImage[i];
            const url: boolean | string =
              image &&
              ("key" in image
                ? getImageUrl(image.key)
                : URL.createObjectURL(image));
            const key =
              image && ("key" in image ? getImageUrl(image.key) : image.name);

            return (
              <ImageListItem key={`${i}${key}`}>
                <img
                  srcSet={`${url || item.url}`}
                  src={`${url || item.url}`}
                  loading="lazy"
                  onClick={() =>
                    !selectedImage[i] && inputFileRef.current?.click()
                  }
                />
                {selectedImage[i] && (
                  <ImageListItemBar
                    title={
                      imageProgress[i] && Math.ceil(imageProgress[i]) + "%"
                    }
                    actionIcon={
                      !loading ? (
                        <IconButton
                          onClick={() => {
                            const images = [...selectedImage];
                            images.splice(i, 1);
                            setSelectedImage(images);
                          }}
                          sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                          aria-label={`info about `}>
                          <Delete />
                        </IconButton>
                      ) : (
                        <Box className="overflow-hidden">
                          <CircularProgress />
                        </Box>
                      )
                    }
                  />
                )}
              </ImageListItem>
            );
          })}
        </ImageList>
      </Stack>
      <LoadingButton
        type="submit"
        loading={loading}
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}>
        Sauvegarder
      </LoadingButton>
    </Box>
  );
};
