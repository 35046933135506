// Import the functions you need from the SDKs you need
import { Capacitor } from "@capacitor/core";
import { FBCollections } from "@common/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { collection, doc, serverTimestamp } from "firebase/firestore";
import {
  StorageError,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { useUserStore } from "../../store/userStore";
import { Logger } from "../error-handling";
import {
  firebaseAppAuth,
  firebaseAppFirestoreDb,
  firebaseStorage,
} from "./config";
import { getAppUser } from "./mapper";
import { initMessaging } from "./messaging";

export function generateFirestoreId(): string {
  return doc(collection(firebaseAppFirestoreDb, "businesses")).id;
}

// let init = false;
onAuthStateChanged(firebaseAppAuth, async (user) => {
  const userStore = useUserStore.getState();
  try {
    if (user) {
      userStore.setUser(await getAppUser(user));

      if (Capacitor.isNativePlatform()) {
        await initMessaging(user.uid);
      }
      // if (!init) await impersonateUser("qWAgozqjO5fJ8SMWtfVKpXRxCnA3");
      // init = true;
    } else {
      userStore.setUser(null);
    }
  } catch (e) {
    Logger.error(e);
  }
});

export const firebaseServerTimestamp = serverTimestamp;

export const fileUpload = (
  file: File,
  collection: FBCollections,
  itemId: string,
  userId: string,
  callbackProgress: (progress: number) => void,
  callbackLoading: (isLoading: boolean) => void,
  callback: ({ url, key }: { url: string; key: string }) => void,
  callbackError?: (e: StorageError) => void,
  type?: string // prefix
) => {
  return new Promise<void>((resolve) => {
    callbackLoading(true);
    const storageRef = ref(
      firebaseStorage,
      collection === FBCollections.Businesses
        ? `images/${collection}/${itemId}/${type}-${generateFirestoreId()}.jpg`
        : `images/${collection}/${type}-${itemId}.jpg`
    );

    const uploadTask = uploadBytesResumable(storageRef, file, {
      customMetadata: {
        itemId,
        userId,
      },
    });
    callbackProgress(0);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        callbackProgress(progress);
        // switch (snapshot.state) {
        //   case "paused":
        //     console.log("Upload is paused");
        //     break;
        //   case "running":
        //     console.log("Upload is running");
        //     break;
        // }
      },
      (error) => {
        if (callbackError) {
          callbackError(error);
          callbackLoading(false);
          resolve();
        }
        // Handle unsuccessful uploads
      },
      async () => {
        const url = await getDownloadURL(uploadTask.snapshot.ref);
        callback({ url, key: uploadTask.snapshot.ref.fullPath });
        callbackLoading(false);
        resolve();
      }
    );
  });
};

export function getImageUrl(key: string) {
  return `https://firebasestorage.googleapis.com/v0/b/${
    import.meta.env.VITE_FIREBASE_STORAGE_BUCKET
  }/o/${encodeURIComponent(key)}?alt=media`;
}
