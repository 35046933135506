import { ComponentErrorBoundary } from "@/common/error-boundary";
import { getImageUrl } from "@/common/firebase/common";
import { getCustomerScansRealTime } from "@/common/firebase/services/user-requests";
import { getUserQrCodeValue } from "@/common/qr-code";
import { Scan } from "@common/model/scan";
import { Image } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Skeleton,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
import { useSetCurrentPageName } from "../store/appSettingsStore";
import { useUserStore } from "../store/userStore";

export default function Loyalty() {
  useSetCurrentPageName("Ma carte");
  const userState = useUserStore();
  const [userScans, setUserScans] = useState<Scan[]>([]);
  const [isLoading, setIsloading] = useState(true);
  const [animateClass, setAnimateClass] = useState("");

  const qrCodeValue = useMemo(
    () => userState.user && getUserQrCodeValue(userState.user.uid),
    [userState.user]
  );

  useEffect(() => {
    const unsubscribe = getCustomerScansRealTime(
      userState.user?.uid as any,
      (scans: any) => {
        if (!isLoading) {
          setAnimateClass("outline-animated");
          setTimeout(() => {
            setAnimateClass("");
          }, 5000);
        }
        setUserScans(scans);
        setIsloading(false);
      }
    );

    return () => {
      unsubscribe && unsubscribe();
    };
  }, []);

  if (!userState.user || !qrCodeValue) {
    return (
      <Container component="main" maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography component="p">
          Pour acceder a nos super promos{" "}
          <Link to="/auth">cre toi un compte ou connecte toi ici</Link>
        </Typography>
      </Container>
    );
  }

  return (
    <ComponentErrorBoundary>
      <Container component="main" maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container>
          <Grid
            item
            xs={12}
            textAlign={"center"}
            sx={{ mb: 4, position: "relative" }}>
            <Box className={animateClass}>
              <QRCode value={qrCodeValue} />
            </Box>
            {animateClass && (
              <Box
                sx={{
                  position: "absolute",
                  width: 1,
                  height: 1,
                  top: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <Chip label="Validé" color="success"></Chip>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sx={{ bgcolor: "background.paper" }}>
            <List subheader={<ListSubheader>Historique</ListSubheader>}>
              {userScans?.map((scan) => (
                <ListItemButton
                  component={Link}
                  to={`/business/${scan.business?.id}`}
                  key={scan.id}>
                  <ListItemAvatar>
                    <Avatar
                      src={
                        scan.business?.cover_picture &&
                        getImageUrl(scan.business?.cover_picture.key)
                      }>
                      <Image />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={scan.business?.name}
                    secondary={dayjs(scan.created_at.toDate())
                      .format("YYYY-MM-DD HH:mm")
                      .toString()}
                  />
                </ListItemButton>
              ))}
              {!isLoading && !userScans.length && (
                <ListItem>
                  <ListItemText secondary="Aucun scan pour le moment" />
                </ListItem>
              )}
              {isLoading && (
                <ListItem>
                  <ListItemAvatar>
                    <Skeleton variant="circular" height={40} width={40} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                    }
                    secondary={
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem", width: "65%" }}
                      />
                    }></ListItemText>
                </ListItem>
              )}
            </List>
          </Grid>
        </Grid>
      </Container>
    </ComponentErrorBoundary>
  );
}
