import { Logger } from "@/common/error-handling";
import { firebaseAppFunctions } from "@/common/firebase/config";
import { getCustomerById } from "@/common/firebase/services/user-requests";
import {
  BarcodeScannedEvent,
  BarcodeScanner,
} from "@capacitor-mlkit/barcode-scanning";
import { Capacitor } from "@capacitor/core";
import { FBCloudFunctions } from "@common/firebase";
import {
  BusinessScanRequest,
  BusinessScanResponse,
  ClaimValue,
  UserCardValue,
} from "@common/index";
import { Business, BusinessProgram } from "@common/model/business";
import { CustomerFirestore2 } from "@common/model/customer";
import { ProgramType } from "@common/model/scan";
import { Close } from "@mui/icons-material";
import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Fab,
  Grid,
  Stack,
} from "@mui/material";
import { httpsCallable } from "firebase/functions";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useClaimRewardDialog } from "./ClaimRewardDialog";

let isScanInProgress = false;
export const QRScannerMobile = ({
  submitScanCallback,
  handleClose,
  business,
  program,
}: {
  submitScanCallback?: (
    customer: CustomerFirestore2,
    scannedValue: string,
    businessId: string
  ) => void;
  handleClose: () => void;
  business: Business;
  program?: BusinessProgram;
}) => {
  const [isScanned, setIsScanned] = useState<{
    customer: CustomerFirestore2;
    programTypes: ProgramType[] | null;
  } | null>(null);
  const [isScanning, setIsScanning] = useState(false);
  const [isScanError, setIsScanError] = useState<string[]>([]);
  const { t } = useTranslation("translation");
  const { setClaimResult, claimDialog } = useClaimRewardDialog(() => {
    setIsScanning(false);
    setIsScanError([]);
    setIsScanned(null);
    isScanInProgress = false;
  });

  const rectConvertedRef = useRef<{
    topLeft: { x: number; y: number };
    bottomRight: { x: number; y: number };
  }>();

  const submitScan = async (
    customer: CustomerFirestore2,
    scannedValue: string,
    businessId: string
  ) => {
    const loyaltyProgram = business.programs?.filteredLoyalty?.active;
    const lotteryProgram = business.programs?.filteredLottery.active;
    if (!loyaltyProgram && !lotteryProgram && !program) return;

    try {
      const businessScanRequest = httpsCallable<
        BusinessScanRequest,
        BusinessScanResponse
      >(firebaseAppFunctions, FBCloudFunctions.BusinessScan);
      const errors: string[] = [];

      const result = await businessScanRequest({
        scannedValue,
        businessId,
        action:
          program?.type === ProgramType.lottery ? "lotteryDraw" : undefined,
        programId: program ? program.id : undefined,
      }).then((result) => {
        return result.data;
      });

      if (result.type === "program" && result.data) {
        const data = result.data;
        const successTypes = Object.values(ProgramType)
          .map((type) => (data[type]?.success ? type : null))
          .filter((v) => !!v) as ProgramType[];
        successTypes.forEach((type) => {
          Logger.success(
            `[${t("programs." + type)}] [${customer.username}] : Validé`
          );
        });

        setIsScanned({
          customer,
          programTypes: successTypes,
        });
        setIsScanError(
          [result.data.lottery?.error, result.data.loyalty?.error].filter(
            (v) => !!v
          ) as string[]
        );
        setIsScanning(false);
      } else if (result.type === "claim") {
        setIsScanning(false);
        setIsScanned({
          customer,
          programTypes: null,
        });
        setClaimResult(result.data, result.data.reward[0], customer);
      } else if ("error" in result) {
        errors.push(result.error);
        setIsScanError(errors);
        setIsScanning(false);
      }

      if (result.type !== "claim") {
        setTimeout(() => {
          setIsScanError([]);
          setIsScanned(null);
          isScanInProgress = false;
        }, 5000);
      }

      if (submitScanCallback) {
        submitScanCallback(customer, scannedValue, businessId);
        return;
      }
    } catch (e) {
      isScanInProgress = false;
      setIsScanning(false);
    }
    return;
  };

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      scan();
    }

    return () => {
      void BarcodeScanner.removeAllListeners();
    };
  }, []);

  useEffect(() => {
    if (isScanning) {
      (
        document.getElementsByClassName("MuiModal-backdrop") as any
      )[0].style.visibility = "visible";
    } else {
      (
        document.getElementsByClassName("MuiModal-backdrop") as any
      )[0].style.visibility = "hidden";
    }
  }, [isScanning]);

  const debouncedScan = useCallback(
    debounce((arg) => handleNewScan(arg), 1500),
    []
  );

  const handleNewScan = async (scannedCode: string) => {
    try {
      if (
        !!scannedCode &&
        !isScanning &&
        !isScanned &&
        !isScanError.length &&
        !isScanInProgress
      ) {
        setIsScanning(true);

        try {
          const userid = (JSON.parse(scannedCode) as UserCardValue | ClaimValue)
            .u;
          isScanInProgress = true;

          const user = await getCustomerById(userid);

          await submitScan(user, scannedCode, business.id);
        } catch (e) {
          Logger.error(e);
          setIsScanning(false);
          isScanInProgress = false;
        }
      }
    } catch (e) {
      // alert(JSON.stringify(e));
      isScanInProgress = false;
      setIsScanning(false);
      Logger.error(e);
    }
    isScanInProgress = false;
  };

  const handleRect = useCallback(() => {
    const rect = document.getElementById("qr-zone")?.getBoundingClientRect();

    if (!rect) {
      throw "Could not determine qrzone";
    }
    rectConvertedRef.current = {
      topLeft: {
        x: rect.x * window.devicePixelRatio,
        y: rect.y * window.devicePixelRatio,
      },
      bottomRight: {
        x: (rect.x + rect.width) * window.devicePixelRatio,
        y: (rect.y + rect.height) * window.devicePixelRatio,
      },
    };
  }, []);

  async function scan(): Promise<void> {
    const granted = await requestPermissions();
    if (!granted) {
      presentAlert();
      return;
    }
    await BarcodeScanner.startScan();
    changeAppVisibility(false);

    await BarcodeScanner.addListener(
      "barcodeScanned",
      (result: BarcodeScannedEvent) => {
        if (
          !result.barcode.cornerPoints ||
          isScanning ||
          isScanInProgress ||
          !rectConvertedRef.current
        ) {
          return;
        }
        // Verify the qrcode is inside the zone
        const isInZone =
          rectConvertedRef.current.topLeft.x <
            result.barcode.cornerPoints[0][0] &&
          rectConvertedRef.current.topLeft.y <
            result.barcode.cornerPoints[0][1] &&
          rectConvertedRef.current.bottomRight.x >
            result.barcode.cornerPoints[2][0] &&
          rectConvertedRef.current.bottomRight.y >
            result.barcode.cornerPoints[2][1];

        if (isInZone) {
          setIsScanning(true);
          debouncedScan(result.barcode.rawValue);
        }
      }
    );
  }

  async function onClose() {
    changeAppVisibility(true);
    await BarcodeScanner.stopScan();
    handleClose();
  }

  async function requestPermissions(): Promise<boolean> {
    const { camera } = await BarcodeScanner.requestPermissions();
    return camera === "granted" || camera === "limited";
  }

  async function presentAlert(): Promise<void> {
    alert("No permission");
  }

  function changeAppVisibility(isVisible: boolean) {
    try {
      (document.getElementById("content-root") as any).style.visibility =
        isVisible ? "visible" : "hidden";
      (document.getElementById("box") as any).style.visibility = isVisible
        ? "visible"
        : "hidden";
      (document.getElementById("root") as any).style.setProperty(
        "background-color",
        isVisible ? "" : "transparent",
        isVisible ? "" : "important"
      );
      (document.getElementsByTagName("html") as any)[0].style.setProperty(
        "background-color",
        isVisible ? "" : "transparent",
        isVisible ? "" : "important"
      );
      (document.getElementsByTagName("body") as any)[0].style.setProperty(
        "background-color",
        isVisible ? "" : "transparent",
        isVisible ? "" : "important"
      );
      (
        document.getElementById("qr-scanner-modal-fade") as any
      ).style.setProperty(
        "background-color",
        isVisible ? "" : "transparent",
        isVisible ? "" : "important"
      );

      (
        document.getElementsByClassName("MuiModal-backdrop") as any
      )[0].style.visibility = isVisible ? "visible" : "hidden";
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Box>
      {claimDialog}
      <Fab
        onClick={onClose}
        size="medium"
        color={"default"}
        sx={{
          position: "fixed",
          top: "25px",
          right: "15px",
          zIndex: 999,
        }}>
        <Close />
      </Fab>
      <Box
        id="qr-zone"
        ref={handleRect}
        sx={{
          border: "3px solid blue",
          borderColor: isScanned
            ? "green"
            : isScanError.length
            ? "red"
            : "gray",
          borderRadius: 10,
          width: 250,
          height: 250,
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        {isScanning && <CircularProgress color="secondary" />}
      </Box>
      <div
        style={{
          width: "250px",
          height: "250px",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}>
        <Stack spacing={1}>
          {isScanned &&
            isScanned.programTypes &&
            isScanned.programTypes.map((type) => (
              <Alert severity="success">
                [{t("programs." + type)}] [{isScanned.customer.username}] :
                Validé
              </Alert>
            ))}
          {isScanError.map((error) => (
            <Alert severity="error">Erreur : {error}</Alert>
          ))}
        </Stack>
      </div>
      <Box id="box">
        <Container
          sx={{
            p: 0,
            pb: 4,
            justifyContent: "center",
            textAlign: "center",
          }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  maxWidth: "400px",
                  maxHeight: "400px",
                  position: "relative",
                }}></Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};
