import { useState } from "react";

export function useCountdown() {
  const [time, setTime] = useState(0);

  function startCountdown(fromValue: number) {
    setTime(fromValue);

    const timer = setInterval(() => {
      setTime((time) => {
        if (time === 0) {
          clearInterval(timer);
          return 0;
        } else return time - 1;
      });
    }, 1000);
  }

  return { startCountdown, remainingValue: time };
}
