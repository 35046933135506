import { AlgoliaSearchResponseHit } from "@/common/algolia";
import NoImage from "@assets/no-image.jpg";
import { Business, BusinessType } from "@common/model/business";
import { LocalBar, Restaurant } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Card,
  CardContent,
  Chip,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { Timestamp } from "firebase/firestore";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PlaceAutocomplete from "../../common/components/PlaceAutocomplete";
import { useSetCurrentPageName } from "../../store/appSettingsStore";
import { useUserStore } from "../../store/userStore";

const BusinessTypesIcons = {
  [BusinessType.Restaurant]: Restaurant,
  [BusinessType.Bar]: LocalBar,
};

export type getPlaceType = (
  place: google.maps.places.PlaceResult | AlgoliaSearchResponseHit
) => void;

export const days = [
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
  "Dimanche",
];

export interface BusinessFormData {
  name: string;
  type: BusinessType[];
  description: string;
  owner_id: string;
  utc_offset_minutes: number;
  location: {
    lat: number;
    lon: number;
  };
  address: {
    street_address: string;
    street_address_addition: string;
    postal_code: string;
    city: string;
    country: string;
  };
  opening_hours: {
    day: number;
    active: boolean;
    open: {
      day: number;
      value: string;
      timestamp: string;
    };
    close?: {
      day: number;
      value: string;
      timestamp: string;
    };
  }[];
  google_place_id: string;
  created: object;
  updated_at: Timestamp;
}

//& google.maps.places.PlaceOpeningHoursPeriod
export default function BusinessForm({
  children,
  loading,
  business,
  submit,
}: {
  children?: ReactNode;
  loading?: boolean;
  business?: Business;
  submit: (data: BusinessFormData, image: File | null) => void;
}) {
  useSetCurrentPageName("Ajouter un établissement");
  const userState = useUserStore();
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation("translation");

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    getValues,
    setValue,
    getFieldState,
    watch,
  } = useForm<BusinessFormData>({
    defaultValues: {
      name: business?.name || "",
      type: business?.type || [],
      description: business?.description || "",
      owner_id: userState.user?.uid,
      location: {
        lat: business?.location.lat,
        lon: business?.location.lon,
      },
      address: {
        street_address: business?.address.street_address || "",
        street_address_addition:
          business?.address.street_address_addition || "",
        postal_code: business?.address.postal_code || "",
        city: business?.address.city || "",
        country: business?.address.country || "",
      },
      opening_hours: business?.opening_hours || [
        {
          day: 0,
          active: false,
        },
        {
          day: 1,
          active: false,
        },
        {
          day: 2,
          active: false,
        },
        {
          day: 3,
          active: false,
        },
        {
          day: 4,
          active: false,
        },
        {
          day: 5,
          active: false,
        },
        {
          day: 6,
          active: false,
        },
      ],
      google_place_id: business?.google_place_id,
      created: business?.created || Timestamp.now(),
      updated_at: business?.updated_at || Timestamp.now(),
    },
  });

  useEffect(() => {
    if (!userState.user) return;
    setValue("owner_id", userState.user.uid);
  }, [userState.user]);

  const getPlace: getPlaceType = (results: google.maps.places.PlaceResult) => {
    if (results?.utc_offset_minutes) {
      setValue("utc_offset_minutes", results.utc_offset_minutes);
    }
    setValue(
      "address",
      {
        street_address:
          `${
            results?.address_components?.find((ac) =>
              ac.types.includes("street_number")
            )?.long_name
          } ${
            results?.address_components?.find((ac) =>
              ac.types.includes("route")
            )?.long_name
          }` || "",
        street_address_addition: "",
        city:
          results?.address_components?.find((ac) =>
            ac.types.includes("locality")
          )?.long_name || "",
        country:
          results?.address_components?.find((ac) =>
            ac.types.includes("country")
          )?.long_name || "",
        postal_code:
          results?.address_components?.find((ac) =>
            ac.types.includes("postal_code")
          )?.long_name || "",
      },
      { shouldDirty: true, shouldValidate: true }
    );
    setValue(
      "opening_hours",
      results?.opening_hours?.periods?.map((hour) => {
        return {
          active: !!hour.open,
          day: hour.open.day,
          open: {
            day: hour.open.day,
            value: `${hour.open.time.substring(
              0,
              2
            )}:${hour.open.time.substring(2, 4)}`,
            timestamp: hour.open.time,
          },
          close: hour.close
            ? {
                day: hour.close?.day,
                value: `${hour.close?.time.substring(
                  0,
                  2
                )}:${hour.close?.time.substring(2, 4)}`,
                timestamp: hour.close?.time,
              }
            : undefined,
        };
      }) || [],
      { shouldDirty: true, shouldValidate: true }
    );
    if (results?.geometry?.location) {
      setValue("location", {
        lat: results.geometry.location.lat(),
        lon: results.geometry.location.lng(),
      });
    }
    if (results?.place_id) {
      setValue("google_place_id", results.place_id);
    }
  };

  return (
    <Container component="main" disableGutters>
      <CssBaseline />
      <Box
        component="form"
        sx={{ mt: 3 }}
        onSubmit={handleSubmit((data) => {
          submit(data, selectedImage);
        })}>
        <Divider textAlign="left" sx={{ mb: 2 }}>
          Informations générales
        </Divider>
        <Grid container spacing={2}>
          {!business && (
            <Grid
              item
              xs={12}
              md={"auto"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}>
              <input
                type="file"
                max={1}
                ref={inputFileRef}
                hidden
                onChange={(e) => {
                  e.target.files &&
                    e.target.files.length &&
                    setSelectedImage(e.target.files[0]);
                }}
              />
              <Avatar
                sx={{
                  height: 150,
                  width: 150,
                  cursor: "pointer",
                  boxShadow: 1,
                }}
                src={
                  (selectedImage && URL.createObjectURL(selectedImage)) ||
                  NoImage
                }
                onClick={() => inputFileRef.current?.click()}
              />
            </Grid>
          )}
          <Grid item xs={12} md>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <PlaceAutocomplete
                      register={register("name")}
                      forwardPlace={getPlace}
                      value={business?.name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Type d'établissement
                      </InputLabel>
                      <Select
                        multiple
                        value={watch("type")}
                        {...register(`type`)}
                        labelId="type"
                        SelectDisplayProps={{
                          style: { display: "flex", alignItems: "center" },
                        }}
                        fullWidth
                        required
                        label="Type d'établissement"
                        renderValue={(selected) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                            }}>
                            {selected.map((type) => {
                              const Icon = BusinessTypesIcons[type];
                              return (
                                <Chip
                                  key={type}
                                  icon={<Icon fontSize="small" />}
                                  label={t("types." + type)}
                                />
                              );
                            })}
                          </Box>
                        )}>
                        {Object.values(BusinessType).map((type) => {
                          const Icon = BusinessTypesIcons[type];
                          return (
                            <MenuItem key={type} value={type}>
                              <ListItemIcon sx={{ minWidth: 36 }}>
                                <Icon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>{t("types." + type)}</ListItemText>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      {...register("description")}
                      fullWidth
                      multiline
                      minRows={4}
                      id="description"
                      label="Description"
                      name="description"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Divider sx={{ mt: 4, mb: 2 }} textAlign="left">
          Adresse
        </Divider>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  {...register("address.street_address")}
                  required
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  id="street_address"
                  label="Numéro et rue"
                  name="street_address"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...register("address.street_address_addition")}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  id="street_address_addition"
                  label="Complément"
                  name="street_address_addition"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  {...register("address.postal_code")}
                  InputLabelProps={{ shrink: true }}
                  required
                  fullWidth
                  id="postal_code"
                  label="Code postal"
                  name="postal_code"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  {...register("address.city")}
                  InputLabelProps={{ shrink: true }}
                  required
                  fullWidth
                  name="city"
                  label="Ville"
                  id="city"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  {...register("address.country")}
                  InputLabelProps={{ shrink: true }}
                  required
                  fullWidth
                  name="country"
                  label="Pays"
                  id="country"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Divider sx={{ mt: 4, mb: 2 }} textAlign="left">
          Heures d'ouverture
        </Divider>
        <Card>
          <CardContent>
            {days.map((day, index) => (
              <Grid container key={day} sx={{ mb: 2 }}>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  sx={{ display: "flex", alignItems: "center" }}>
                  <Typography>{day}</Typography>
                  <Switch
                    {...register(`opening_hours.${index}.active`)}
                    checked={watch(`opening_hours.${index}.active`)}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                </Grid>
                {watch(`opening_hours.${index}.active`) && (
                  <>
                    <Grid item xs>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            ouverture
                          </InputLabel>
                          <Select
                            value={watch(`opening_hours.${index}.open.value`)}
                            {...register(`opening_hours.${index}.open.value`)}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            fullWidth
                            label="ouverture">
                            {dayhours.map((h) => (
                              <MenuItem key={h} value={h}>
                                {h}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            fermeture
                          </InputLabel>
                          <Select
                            value={watch(`opening_hours.${index}.close.value`)}
                            {...register(`opening_hours.${index}.close.value`)}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="fermeture">
                            {dayhours.map((h) => (
                              <MenuItem key={h} value={h}>
                                {h}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Stack>
                    </Grid>
                    {/* <Grid
                      item
                      xs={"auto"}
                      sx={{ display: "flex", alignItems: "center" }}>
                      <IconButton>
                        <Add />
                      </IconButton>
                    </Grid> */}
                  </>
                )}
              </Grid>
            ))}
          </CardContent>
        </Card>
        <LoadingButton
          type="submit"
          loading={loading}
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 2 }}>
          {children}
        </LoadingButton>
        {/* {submitButton(handleSubmit, selectedImage)} */}
      </Box>
    </Container>
  );
}

const dayhours = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
].flatMap((hour) => [
  `${hour < 10 ? "0" : ""}${hour}:00`,
  `${hour < 10 ? "0" : ""}${hour}:30`,
]);
