import { Logger } from "@/common/error-handling";
import { deleteBusinessInvite } from "@/common/firebase/services/owner-requests";
import { Business, BusinessInviteMapped } from "@common/index";
import { Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Chip, TableCell, TableRow, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";

export const BusinessUserInviteRow = ({
  invite,
  businessCollection,
  mutate,
  businessId,
}: {
  invite: BusinessInviteMapped;
  businessCollection?: Business[];
  mutate: () => void;
  businessId?: string;
}) => {
  const [loading, setLoading] = useState(false);
  const removeInvite = async (inviteId: string) => {
    try {
      setLoading(true);
      await deleteBusinessInvite(inviteId, businessId);
      mutate();
      setLoading(false);
      Logger.success("Invitation supprimée");
    } catch (e) {
      Logger.error(e);
      setLoading(false);
    }
  };
  const isExpired = dayjs(invite.expire_at.toDate()).isBefore(dayjs());
  const business = invite.business_ids
    .map((id) => businessCollection?.find((bus) => bus.id == id))
    .filter((v) => !!v) as Business[];
  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell>{invite.email}</TableCell>
      <TableCell align="right">
        <Chip color="warning" label={invite.role}></Chip>
      </TableCell>
      <TableCell align="right">
        {business?.length && (
          <Tooltip title={business.map((b) => b.name).join(", ")}>
            <Chip label={business.length} color="info"></Chip>
          </Tooltip>
        )}
      </TableCell>
      <TableCell align="right">
        <Tooltip
          title={`Invité le ${
            invite.created_at &&
            dayjs(invite.created_at).format("MM/DD/YYYY - HH:mm")
          }`}>
          <Chip
            color={isExpired ? "error" : "info"}
            label={isExpired ? "Expiré" : "En attente"}
          />
        </Tooltip>
      </TableCell>
      <TableCell align="right">
        <LoadingButton
          loading={loading}
          onClick={() => removeInvite(invite.id)}>
          <Delete />
        </LoadingButton>
      </TableCell>
    </TableRow>
  );
};
