import { BusinessForOwner, UserRole } from "@common/index";
import { useUserStore } from "../../store/userStore";
import { useBusinessSubscription } from "./useBusinessSubscription";

export interface BusinessPermissions {
  canAll?: boolean;
  canView?: boolean;
  canScan?: boolean;
  canEditPrograms?: boolean;
}

export const useBusinessPermission = (
  business?: BusinessForOwner,
  targetRole?: UserRole
): [BusinessPermissions, boolean] => {
  const userState = useUserStore();
  const { subscriptionMode, subscriptionActive } =
    useBusinessSubscription(business);

  if (!userState.init || !userState.user || !business) {
    return [{}, false];
  }

  const businessUserRole = business.current_user_permission.role;
  const permissions = getPermissions(
    businessUserRole,
    subscriptionMode ? subscriptionActive : true
  );

  return [permissions, businessUserRole === targetRole];
};

function getPermissions(
  businessUserRole: UserRole,
  hasActivePlan: boolean
): BusinessPermissions {
  if ([UserRole.Owner || UserRole.Admin].includes(businessUserRole)) {
    return {
      canAll: true,
      canView: true,
      canScan: true && hasActivePlan,
      canEditPrograms: true && hasActivePlan,
    };
  }

  if ([UserRole.Scanner].includes(businessUserRole)) {
    return { canView: true, canScan: true };
  }

  if ([UserRole.Viewer].includes(businessUserRole)) {
    return { canView: true };
  }

  return {};
}
