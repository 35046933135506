import { BusinessUser } from "@common/model/business-user";
import { Customer } from "@common/model/customer";
import { User } from "firebase/auth";
import { create } from "zustand";

export interface AppUser extends User {
  customer?: Customer;
  businessUser?: BusinessUser;
  firebaseUser: User;
}

interface UserState {
  user: AppUser | null;
  init: boolean;
  setUser: (user: AppUser | null, init?: boolean) => void;
}

export const useUserStore = create<UserState>((set) => ({
  user: null,
  init: false,
  setUser: (user: AppUser | null, init = true) => set({ user, init }),
}));
