// Import the functions you need from the SDKs you need
import { Capacitor } from "@capacitor/core";
import { Analytics, getAnalytics } from "firebase/analytics";
import { FirebaseOptions, initializeApp } from "firebase/app";
import {
  browserPopupRedirectResolver,
  indexedDBLocalPersistence,
  initializeAuth,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig: FirebaseOptions = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

let analytics: Analytics;

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
if (
  firebaseApp.name &&
  typeof window !== "undefined" &&
  firebaseConfig.measurementId
) {
  analytics = getAnalytics(firebaseApp);
}
export { analytics };

export const firebaseAppAuth = Capacitor.isNativePlatform()
  ? initializeAuth(firebaseApp, {
      persistence: [indexedDBLocalPersistence],
    })
  : initializeAuth(firebaseApp, {
      persistence: [indexedDBLocalPersistence],
      popupRedirectResolver: browserPopupRedirectResolver,
    });
export const firebaseAppFirestoreDb = getFirestore(firebaseApp);
export const firebaseAppFunctions = getFunctions(
  firebaseApp,
  import.meta.env.VITE_REGION
);
export const firebaseStorage = getStorage(firebaseApp);

firebaseAppAuth.languageCode = "fr";

// if dev env
if (!import.meta.env.PROD && true) {
  // connectFunctionsEmulator(firebaseAppFunctions, "127.0.0.1", 5001);
  // connectFirestoreEmulator(firebaseAppFirestoreDb, "127.0.0.1", 8080);
}
