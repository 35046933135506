import {
  BusinessSubCollections,
  FBCloudFunctions,
  FBCollections,
} from "@common/firebase";
import {
  Business,
  BusinessEvent,
  BusinessEventCreate,
  BusinessFirestore,
  BusinessForOwner,
  BusinessInvitedUser,
  BusinessInvitedUserMapped,
  BusinessOffer,
  BusinessOfferCreate,
  BusinessProgram,
  Image,
  ProgramLottery,
  ProgramLoyalty,
  Scan,
  ScanFirestore,
  UserRole,
} from "@common/index";
import { BusinessUser } from "@common/model/business-user";
import {
  Timestamp,
  addDoc,
  arrayRemove,
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  increment,
  limit,
  or,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { deleteObject, ref } from "firebase/storage";
import { mutate as globalMutate } from "swr";
import {
  firebaseAppFirestoreDb,
  firebaseAppFunctions,
  firebaseStorage,
} from "../config";
import { converter } from "../converter";
import { getBusinessForOwner } from "../mapper";

export const getInvitedUsersForAllBusinesses = async (
  user_id: string
): Promise<BusinessInvitedUserMapped[]> => {
  const docCollection = await getDocs(
    query(
      collection(firebaseAppFirestoreDb, FBCollections.Businesses),
      or(where("owner_id", "==", user_id))
    )
  );

  const userIds = (
    await Promise.all(
      docCollection.docs.flatMap(async (ref) => {
        const usersCollection = await getDocs(
          query(
            collection(
              firebaseAppFirestoreDb,
              FBCollections.Businesses,
              ref.id,
              "users_permissions"
            )
          )
        );
        if (!usersCollection.empty) {
          return usersCollection.docs.map((userref) => {
            return {
              ...userref.data(),
              businessIds: [ref.id],
            } as BusinessInvitedUserMapped;
          });
        }
        return null;
      })
    )
  )
    .flatMap((v) => v)
    .filter((v) => !!v)
    .reduce((acc, value) => {
      if (!value) return acc;
      if (acc[value.id]) {
        acc[value.id].businessIds.push(...value.businessIds);
      } else {
        acc[value.id] = { ...value };
      }
      return acc;
    }, {} as { [key: string]: BusinessInvitedUserMapped });

  return Object.values(userIds);
};

export const deleteBusinessUserPermission = (
  businessId: string,
  userId: string
) => {
  const docRef = doc(
    firebaseAppFirestoreDb,
    FBCollections.Businesses,
    businessId,
    "users_permissions",
    userId
  );
  return deleteDoc(docRef);
};

export const getBusinessByIdForOwner = async (
  id: string,
  userId: string
): Promise<BusinessForOwner> => {
  const docRef = doc(firebaseAppFirestoreDb, FBCollections.Businesses, id);
  return getDoc(docRef).then((ref) => getBusinessForOwner(ref, userId));
};

export const deleteBusinessInvite = (inviteId: string, businessId?: string) => {
  const inviteCallable = httpsCallable<
    { inviteId: string; businessId?: string },
    void
  >(firebaseAppFunctions, FBCloudFunctions.RemoveBusinessInvite);
  return inviteCallable({ inviteId, businessId }).then((result) => {
    return result.data;
  });
};

export const deleteBusinessById = (id: string) => {
  const docRef = doc(firebaseAppFirestoreDb, FBCollections.Businesses, id);
  return deleteDoc(docRef);
};

export const createBusinessUser = async (
  userId: string,
  phoneNumber: string
) => {
  const data: BusinessUser = {
    id: userId,
    businessIds: null,
    transactions: null,
    phoneNumber: {
      number: phoneNumber,
      validated_on: null,
    },
    created_at: Timestamp.now(),
    updated_at: Timestamp.now(),
  };

  const ref = doc(firebaseAppFirestoreDb, FBCollections.BusinessUsers, userId);
  return setDoc(ref, data);
};

export const updateBusiness = async (
  id: string,
  data: Partial<BusinessFirestore>
) => {
  const docRef = doc(firebaseAppFirestoreDb, FBCollections.Businesses, id);
  const result = await updateDoc(docRef, data);
  await updateBusinessLt(id);
  return result;
};

export const updateBusinessLoyalty = async (
  business: Business,
  loyaltyId: string,
  data: Partial<ProgramLoyalty>
) => {
  const docRef = doc(
    firebaseAppFirestoreDb,
    FBCollections.Businesses,
    business.id,
    BusinessSubCollections.Loyalty,
    loyaltyId
  );

  const result = await updateDoc(docRef, data);
  await updateBusinessLt(business.id);
  return result;
};

export const updateBusinessLottery = async (
  business: Business,
  lotteryId: string,
  data: Partial<ProgramLottery>
) => {
  const docRef = doc(
    firebaseAppFirestoreDb,
    FBCollections.Businesses,
    business.id,
    BusinessSubCollections.Lottery,
    lotteryId
  );

  const result = await updateDoc(docRef, data);
  await updateBusinessLt(business.id);
  return result;
};

export const archiveBusinessLoyalty = async (
  business: Business,
  loyaltyId: string
) => {
  const docRef = doc(
    firebaseAppFirestoreDb,
    FBCollections.Businesses,
    business.id,
    BusinessSubCollections.Loyalty,
    loyaltyId
  );

  const result = await updateDoc(docRef, {
    archived_at: Timestamp.now(),
  });

  await updateBusinessLt(business.id);
  return result;
};

export const archiveBusinessLottery = async (
  business: Business,
  lotteryId: string
) => {
  const docRef = doc(
    firebaseAppFirestoreDb,
    FBCollections.Businesses,
    business.id,
    BusinessSubCollections.Lottery,
    lotteryId
  );

  const result = await updateDoc(docRef, {
    archived_at: Timestamp.now(),
  });

  await updateBusinessLt(business.id);
  return result;
};

export const createBusinessLoyalty = async (
  businessId: string,
  data: ProgramLoyalty
) => {
  const ref = collection(
    firebaseAppFirestoreDb,
    FBCollections.Businesses,
    businessId,
    BusinessSubCollections.Loyalty
  );
  // data.id = generateFirestoreId();

  const result = await addDoc(ref, data);

  await updateBusinessLt(businessId);

  return { ...data, id: result.id };
};

export const createBusinessLottery = async (
  id: string,
  data: ProgramLottery
) => {
  const ref = collection(
    firebaseAppFirestoreDb,
    FBCollections.Businesses,
    id,
    BusinessSubCollections.Lottery
  );
  // data.id = generateFirestoreId();
  const result = await addDoc(ref, data);

  await updateBusinessLt(id);

  return {
    ...data,
    id: result.id,
  };
};

/**
 * Event section
 */

export const addBusinessEvent = async (
  id: string,
  data: BusinessEventCreate
): Promise<BusinessEvent> => {
  const docRef = doc(
    collection(
      firebaseAppFirestoreDb,
      FBCollections.Businesses,
      id,
      BusinessSubCollections.Events
    )
  );
  (data as BusinessEvent).id = docRef.id;
  const result = await setDoc(docRef, data).then(() => data as BusinessEvent);
  await updateBusinessLt(id);
  return result;
};

const updateBusinessLt = (businessId: string, business?: BusinessFirestore) => {
  if (!business) {
    const docRef = doc(
      firebaseAppFirestoreDb,
      FBCollections.Businesses,
      businessId
    );
    return updateDoc(docRef, { lt: Date.now() });
  } else {
    business.lt = Date.now();
  }
};

export const updateBusinessEvent = async (
  businessId: string,
  id: string,
  data: Partial<BusinessEvent>
): Promise<BusinessEvent> => {
  const docRef = doc(
    firebaseAppFirestoreDb,
    FBCollections.Businesses,
    businessId,
    "events",
    id
  );
  const result = await updateDoc(docRef, data).then(
    () => data as BusinessEvent
  );
  await updateBusinessLt(businessId);
  return result;
};

export const deleteBusinessEvent = async (
  businessId: string,
  id: string
): Promise<void> => {
  const docRef = doc(
    firebaseAppFirestoreDb,
    FBCollections.Businesses,
    businessId,
    "events",
    id
  );
  await deleteDoc(docRef);
  await updateBusinessLt(businessId);
};

/**
 * Offer section
 */

export const addBusinessOffer = async (
  id: string,
  data: BusinessOfferCreate
): Promise<BusinessOffer> => {
  const docRef = doc(
    collection(
      firebaseAppFirestoreDb,
      FBCollections.Businesses,
      id,
      BusinessSubCollections.Offers
    )
  );
  (data as BusinessOffer).id = docRef.id;
  const result = await setDoc(docRef, data).then(() => data as BusinessOffer);
  await updateBusinessLt(id);
  return result;
};

export const updateBusinessOffer = async (
  businessId: string,
  id: string,
  data: Partial<BusinessOffer>
): Promise<BusinessOffer> => {
  const docRef = doc(
    firebaseAppFirestoreDb,
    FBCollections.Businesses,
    businessId,
    BusinessSubCollections.Offers,
    id
  );
  const result = await updateDoc(docRef, data).then(
    () => data as BusinessOffer
  );
  await updateBusinessLt(businessId);
  return result;
};

export const deleteBusinessOffer = async (
  businessId: string,
  id: string
): Promise<void> => {
  const docRef = doc(
    firebaseAppFirestoreDb,
    FBCollections.Businesses,
    businessId,
    BusinessSubCollections.Offers,
    id
  );
  await deleteDoc(docRef);
  await updateBusinessLt(businessId);
};

export const createScan = async (data: ScanFirestore) => {
  const customerSubRef = doc(
    firebaseAppFirestoreDb,
    `${FBCollections.Customers}/${data.customer_id}/scanned_businesses/${data.business_id}`
  );
  const businessRef = doc(
    firebaseAppFirestoreDb,
    `${FBCollections.Businesses}/${data.business_id}`
  );

  const scan = await addDoc(
    collection(firebaseAppFirestoreDb, FBCollections.Scans),
    data
  );
  const batch = await writeBatch(firebaseAppFirestoreDb);
  batch.set(customerSubRef, {
    ref: businessRef,
    scans: increment(1),
  });
  await batch.commit();

  return scan;
};

export const inviteUserToBusiness = (
  emails: string[],
  role: string,
  business_ids: string[]
): Promise<void> => {
  const inviteCallable = httpsCallable<
    {
      emails: string[];
      role: string;
      business_ids: string[];
    },
    void
  >(firebaseAppFunctions, FBCloudFunctions.InviteUserToBusiness);
  return inviteCallable({ emails, role, business_ids }).then((result) => {
    return result.data;
  });
};

export const getCustomerScans = async (
  userId: string,
  limit2: number = 10,
  businessId: string,
  programs: BusinessProgram[]
): Promise<Scan[]> => {
  try {
    const scanPromises = programs.map((program) => {
      const scanQuery = query(
        collection(firebaseAppFirestoreDb, FBCollections.Scans),
        where("customer_id", "==", userId),
        where("business_id", "==", businessId),
        where("program_type", "==", program.type),
        limit(limit2),
        orderBy("created_at", "desc")
      );

      return getDocs(scanQuery);
    });

    return (await Promise.all(scanPromises)).flatMap((scans) => {
      return scans.docs.map((doc) => {
        const data = doc.data() as ScanFirestore;

        return {
          ...data,
          id: doc.id,
        };
      });
    });
  } catch (e) {
    console.error(e, "getCustomerScans");
    return [];
  }
};

export const getBusinessCollectionForOwner = async (
  user_id: string,
  roles?: UserRole[]
): Promise<BusinessForOwner[]> => {
  const userPermissionCollection = await getDocs(
    query(
      collectionGroup(firebaseAppFirestoreDb, "users_permissions"),
      where("id", "==", user_id),
      where("role", "in", roles ?? Object.values(UserRole))
    ).withConverter(converter<BusinessInvitedUser>())
  );

  const docCollection = await Promise.all(
    userPermissionCollection.docs.map((docRef) => {
      return getDoc(
        doc(
          firebaseAppFirestoreDb,
          FBCollections.Businesses,
          docRef?.ref?.parent?.parent?.id as string
        ).withConverter(converter<BusinessFirestore>())
      );
    })
  );

  return await Promise.all(
    docCollection.map((ref) => getBusinessForOwner(ref, user_id))
  );
};

export const makeLotteryDraw = (lotteryId: string, businessId: string) => {
  const lotteryDrawCallable = httpsCallable<
    { lotteryId: string; businessId: string },
    ProgramLottery
  >(firebaseAppFunctions, FBCloudFunctions.LotteryDraw);
  return lotteryDrawCallable({ lotteryId, businessId }).then((result) => {
    return result.data;
  });
};

export const deleteImage = async (
  businessId: string,
  image: Image,
  mutate: typeof globalMutate
): Promise<void> => {
  const docRef = doc(
    firebaseAppFirestoreDb,
    FBCollections.Businesses,
    businessId
  ).withConverter(converter<BusinessFirestore>());
  const url = new URL(decodeURIComponent(image.key));
  const imagePath = `/images/${url.pathname.toString().split("images")[1]}`;
  const imageRef = ref(firebaseStorage, imagePath);

  await deleteObject(imageRef);
  await updateDoc(docRef, {
    menu_pictures: arrayRemove(image),
  });
  await mutate("business-owner-" + businessId);
  await mutate(businessId);
};
