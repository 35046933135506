import { ComponentErrorBoundary } from "@/common/error-boundary";
import { useBusinessDocumentForOwner } from "@/common/firebase/services/owner-hooks";
import { deleteBusinessOffer } from "@/common/firebase/services/owner-requests";
import { useBusinessPermission } from "@/common/hooks/useBusinessPermission";
import { BusinessOffer } from "@common/model/business";
import { Discount, MoreVert } from "@mui/icons-material";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Fade,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Stack,
} from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { AppModal } from "../../common/components/AppModal";
import { useUserStore } from "../../store/userStore";
import UpgradePremiumAd from "../PremiumAd";
import { OfferForm } from "../forms/OfferForm";

const style = {
  position: "absolute",
  top: "0",
  left: "0",
  transform: "translate(0, 0)",
  width: 1,
  height: 1,
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function BusinessOffers() {
  const { id } = useParams();
  const { user } = useUserStore();

  if (!id || !user) return <></>;

  return <View id={id} userId={user.uid} />;
}

function View({ id, userId }: { id: string; userId: string }) {
  const { business, isLoading, mutate } = useBusinessDocumentForOwner(
    id,
    userId
  );
  const [permissions] = useBusinessPermission(business);
  const { t } = useTranslation("translation");
  const [openOffer, setOpenOffer] = useState(false);
  const [editingOffer, setEditingOffer] = useState<BusinessOffer | null>(null);
  const handleOpenOffer = (Offer?: BusinessOffer) => {
    handleClose();
    setEditingOffer(Offer || null);
    setOpenOffer(true);
  };
  const handleCloseOffer = () => {
    setOpenOffer(false);
    setEditingOffer(null);
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const archiveProgram = async (Offer: BusinessOffer) => {
    if (!business) {
      return;
    }
    handleClose();
    await deleteBusinessOffer(business.id, Offer.id);
    mutate();
  };

  if (!business) return <></>;
  return (
    <ComponentErrorBoundary>
      <Grid container>
        <Grid item xs sx={{ mt: 2 }}>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openOffer}
            onClose={handleCloseOffer}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}>
            <Fade in={openOffer}>
              <Box sx={style}>
                <AppModal
                  title="Créer une promotion"
                  handleClose={handleCloseOffer}>
                  <OfferForm
                    business={business}
                    currentOffer={editingOffer || undefined}
                    handleClose={handleCloseOffer}
                    mutate={mutate}
                  />
                </AppModal>
              </Box>
            </Fade>
          </Modal>
          <Stack spacing={2}>
            {!permissions.canEditPrograms && (
              <Box display="flex" justifyContent="center" sx={{ p: 4 }}>
                <UpgradePremiumAd business={business} />
              </Box>
            )}
            {business.offers.length ? (
              <>
                <Box textAlign="right">
                  <Button
                    variant="contained"
                    onClick={() => handleOpenOffer()}
                    disabled={!permissions.canEditPrograms}>
                    Ajouter une promotion
                  </Button>
                </Box>
                {business.offers.map((Offer, i) => (
                  <Card key={i}>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}>
                      <MenuItem
                        onClick={() => handleOpenOffer(Offer)}
                        disabled={!permissions.canEditPrograms}>
                        Éditer
                      </MenuItem>
                      <MenuItem onClick={() => archiveProgram(Offer)}>
                        Supprimer
                      </MenuItem>
                    </Menu>
                    <CardHeader
                      action={
                        !Offer.date || Offer.date.toMillis() > Date.now() ? (
                          <IconButton
                            aria-label="settings"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}>
                            <MoreVert />
                          </IconButton>
                        ) : (
                          <Chip label="Passé" color="warning" />
                        )
                      }
                      title={
                        <Grid container spacing={1}>
                          <Grid item xs="auto">
                            <Chip
                              label={`${Offer.week_days
                                .map((dayIndex) =>
                                  dayjs()
                                    .day(Math.max(0, dayIndex))
                                    .format("dddd")
                                )
                                .join(", ")} ${Offer.start_hour}h - ${
                                Offer.end_hour
                              }h`}></Chip>
                          </Grid>
                          <Grid item xs="auto">
                            <Chip
                              label={t("offers." + Offer.type)}
                              color="info"></Chip>
                          </Grid>
                          <Grid item>{Offer.title}</Grid>
                        </Grid>
                      }
                      subheader={
                        <Alert
                          icon={<Discount />}
                          sx={{
                            mt: 1,
                          }}>
                          {Offer.description}
                        </Alert>
                      }
                    />
                  </Card>
                ))}
              </>
            ) : (
              <>
                <Alert severity="warning">
                  Aucune promotion pour le moment.
                </Alert>
                <Box textAlign={"center"}>
                  <Button
                    variant="contained"
                    onClick={() => handleOpenOffer()}
                    disabled={!permissions.canEditPrograms}>
                    Créer une promotion
                  </Button>
                </Box>
              </>
            )}
          </Stack>
        </Grid>
      </Grid>
    </ComponentErrorBoundary>
  );
}
