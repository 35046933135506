import { FBCollections } from "@common/firebase";
import {
  Business,
  BusinessEvent,
  BusinessInviteMapped,
  UserRole,
} from "@common/index";
import { collection, getDocs, query, where } from "firebase/firestore";
import useSWR from "swr";
import { firebaseAppFirestoreDb } from "../config";
import {
  getBusinessByIdForOwner,
  getBusinessCollectionForOwner,
  getInvitedUsersForAllBusinesses,
} from "./owner-requests";

export const useGetInvitedUsersForAllBusinesses = (id: string) => {
  const { data, error, isLoading, mutate } = useSWR(
    "useGetInvitedUsersForAllBusinesses-" + id,
    () => getInvitedUsersForAllBusinesses(id),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return {
    userIds: data,
    isLoading,
    isError: error,
    mutate,
  };
};

export const useBusinessDocumentForOwner = (id: string, userId: string) => {
  const { data, error, isLoading, mutate } = useSWR(
    "business-owner-" + id,
    () => getBusinessByIdForOwner(id, userId),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return {
    business: data,
    isLoading,
    isError: error,
    mutate,
  };
};

export function useDiscoverBusinessList(owner_id?: string) {
  const { data, error, isLoading } = useSWR(
    owner_id,
    getBusinessCollectionForOwner,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return {
    businessList: data,
    isLoading,
    isError: error,
  };
}

export function useGetPendingInvites(businessCollection: Business[]) {
  return useSWR(
    "pendingInvitesContainer-" + businessCollection?.map((b) => b.id).join(""),
    async () => {
      if (!businessCollection?.length) return;
      return await getDocs(
        query(
          collection(firebaseAppFirestoreDb, FBCollections.BusinessInvites),
          where(
            "business_ids",
            "array-contains-any",
            businessCollection?.map((bu) => bu.id)
          ),
          where("claimed_by", "==", null)
        )
      ).then((collection) => {
        return collection.docs.map(
          (d) => ({ ...d.data(), id: d.id } as BusinessInviteMapped)
        );
      });
    }
  );
}

export const useGetBusinessEvents = (businessId: string | null) => {
  return useSWR(
    businessId ? "useGetBusinessEvents-" + businessId : businessId,
    async () => {
      //conditional fetching if businessId is null the fetcher won't be executed
      return await getDocs(
        query(
          collection(
            firebaseAppFirestoreDb,
            FBCollections.Businesses,
            businessId as string,
            "events"
          )
        )
      ).then((collection) => {
        return collection.docs.map(
          (d) => ({ ...d.data(), id: d.id } as BusinessEvent)
        );
      });
    }
  );
};

export const useBusinessCollection = (user_id: string, roles?: UserRole[]) => {
  const { data, error, isLoading, mutate } = useSWR(
    "useBusinessCollection" + user_id,
    () => getBusinessCollectionForOwner(user_id, roles),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return {
    collection: data,
    isLoading,
    isError: error,
    mutate,
  };
};
