import LocationOnIcon from "@mui/icons-material/LocationOn";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { debounce } from "@mui/material/utils";
import parse from "autosuggest-highlight/parse";
import { Fragment, useEffect, useMemo, useState } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { getPlaceType } from "../../owner/forms/BusinessForm";
import { useGoogleMapApi } from "../google-map";

const autocompleteService: {
  current: google.maps.places.AutocompleteService | null;
} = { current: null };

export interface PlaceType extends google.maps.places.AutocompletePrediction {}

export default function PlaceAutocomplete({
  register,
  forwardPlace,
  sx = {},
  label = "Nom de l'établissement",
  value: originalValue = "",
  placeholder,
  googleMapsTypes = ["cafe", "bar", "restaurant"],
  fullHeight = true,
}: {
  register: UseFormRegisterReturn;
  forwardPlace: getPlaceType;
  sx?: { [key: string]: any };
  label?: string | null;
  value?: any;
  placeholder?: string;
  googleMapsTypes?: string[];
  fullHeight?: boolean;
}) {
  const { placeService } = useGoogleMapApi();
  const [value, setValue] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<readonly PlaceType[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue({ description: originalValue } as any);
  }, [originalValue]);

  const fetch = useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results: readonly PlaceType[] | null) => void
        ) => {
          if (!autocompleteService.current) return;
          autocompleteService.current.getPlacePredictions(
            // https://developers.google.com/maps/documentation/javascript/supported_types?hl=fr
            { ...request, types: [...googleMapsTypes] },
            callback
          );
        },
        400
      ),
    []
  );

  useEffect(() => {
    let active = true;
    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current =
        new google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      setLoading(false);
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      setLoading(false);
      return undefined;
    }
    setLoading(true);

    fetch({ input: inputValue }, (results: readonly PlaceType[] | null) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setLoading(false);

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const getDetailsAndForward = (place: PlaceType | null) => {
    if (!place) return;
    placeService?.getDetails({ placeId: place.place_id }, (results, status) => {
      if (status == google.maps.places.PlacesServiceStatus.OK && results) {
        forwardPlace(results);
      }
    });
  };

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) =>
        typeof option === "string"
          ? option
          : option.structured_formatting?.main_text || option.description
      }
      isOptionEqualToValue={(option, value) =>
        option.place_id === value.place_id
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      autoHighlight
      includeInputInList
      filterSelectedOptions
      disableClearable={loading}
      value={value}
      loading={loading}
      onSubmit={console.log}
      noOptionsText="No locations"
      sx={{ p: 0, ...sx }}
      onChange={(event, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        getDetailsAndForward(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        if (value?.place_id) {
          setOptions([]);
        }
        setInputValue(newInputValue);
        register.onChange({
          target: { name: register.name, value: newInputValue },
        });
        setValue({ description: newInputValue } as any);
      }}
      popupIcon={""}
      renderInput={(params) => (
        <TextField
          {...params}
          {...register}
          label={label}
          placeholder={placeholder}
          fullWidth
          autoComplete={"false"}
          name="business-name"
          InputProps={{
            ...params.InputProps,
            sx: {
              p: !fullHeight ? "0!important" : "",
              // pt: "0!important",
              // pb: "0!important",
              pr: !!value && !loading ? "35px!important" : "",
            },
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} sx={{ mr: 2 }} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => {
        if (!option.structured_formatting) return;
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <li {...props} key={option.place_id}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? "bold" : "regular" }}>
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
