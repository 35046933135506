import ScrollToAnchor from "@/common/components/ScrollToAnchor";
import { Logger } from "@/common/error-handling";
import { firebaseAppFirestoreDb } from "@/common/firebase/config";
import {
  useBusinessCollection,
  useBusinessDocumentForOwner,
} from "@/common/firebase/services/owner-hooks";
import {
  deleteBusinessById,
  updateBusiness as updateBusinessRequest,
} from "@/common/firebase/services/owner-requests";
import { useBusinessDocumentForPublic } from "@/common/firebase/services/user-hooks";
import { FBCollections } from "@common/firebase";
import { BusinessFirestore } from "@common/index";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import { doc, updateDoc } from "firebase/firestore";
import { geohashForLocation } from "geofire-common";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSetCurrentPageName } from "../../store/appSettingsStore";
import { useUserStore } from "../../store/userStore";
import BusinessForm, { BusinessFormData } from "../forms/BusinessForm";
import BusinessMenuForm from "../forms/BusinessMenuForm";

export default function BusinessEdit() {
  const { id } = useParams();
  const user = useUserStore();

  if (!id || !user.user) return <></>;

  return (
    <Container component="main" maxWidth="lg" sx={{ overflow: "unset", mt: 2 }}>
      <View id={id} userId={user.user.uid} />
    </Container>
  );
}

const View = ({ id, userId }: { id: string; userId: string }) => {
  useSetCurrentPageName("Mise à jour");
  const { business, isLoading, mutate } = useBusinessDocumentForOwner(
    id,
    userId
  );
  const { mutate: mutatePublic } = useBusinessDocumentForPublic(id, userId);
  const { mutate: mutateCollection } = useBusinessCollection(userId);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [publicValue, setPublicValue] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  useEffect(() => {
    setPublicValue(business?.status === "active");
  }, [business]);

  const deleteDocument = async () => {
    try {
      await deleteBusinessById(id);
      navigate("/owner/business/list");
      mutateCollection();
      Logger.success("Établissement supprimé");
    } catch (e) {
      Logger.error(e);
    }
  };

  const updateBusiness = async (
    formData: BusinessFormData,
    selectedImage: File | null
  ) => {
    if (!business) return;

    try {
      setLoading(true);
      const hash = geohashForLocation([
        formData.location.lat,
        formData.location.lon,
      ]);
      const data: Partial<BusinessFirestore> = {
        ...formData,
        status: business.status,
        geohash: hash,
        created: business.created,
        lt: Date.now(),
      };

      const docRef = doc(
        firebaseAppFirestoreDb,
        FBCollections.Businesses,
        business.id
      );
      await updateDoc(docRef, { ...data });

      if (mutate) mutate();
      if (mutateCollection) mutateCollection();
      if (mutatePublic) mutatePublic();
      navigate(`/owner/business/${docRef.id}`);
      setLoading(false);
      Logger.success("Établissement mis à jour");
    } catch (e) {
      Logger.error(e);
      setLoading(false);
    }
  };

  const saveVisibility = async () => {
    if (!business) {
      return;
    }
    setLoadingUpdate(true);
    try {
      await updateBusinessRequest(business.id, { status: "active" });
      await mutate();
    } catch (e) {
      Logger.error(e);
    }
    setLoadingUpdate(false);
  };

  if (!business) return;

  return (
    <div>
      <ScrollToAnchor />
      <BusinessForm
        business={business}
        loading={loading}
        submit={updateBusiness}>
        Mettre à jour
      </BusinessForm>
      <Box id="menu">
        <BusinessMenuForm business={business}></BusinessMenuForm>
      </Box>
      <Stack spacing={2} sx={{ pt: 2, pb: 6 }} id="publish">
        <Typography variant="h6">Visibilité</Typography>
        <Grid container>
          <Grid item xs>
            <FormControlLabel
              control={
                <Switch
                  checked={publicValue}
                  onChange={(e) => setPublicValue(e.target.checked)}
                />
              }
              value={publicValue}
              label="Public"
              disabled={publicValue && business?.status === "active"}
            />
          </Grid>
          <Grid item xs={"auto"}>
            <LoadingButton
              fullWidth
              variant="contained"
              color="primary"
              onClick={saveVisibility}
              loading={loadingUpdate}
              disabled={publicValue === (business?.status === "active")}>
              Sauvegarder
            </LoadingButton>
          </Grid>
        </Grid>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h6">Suppression</Typography>
        <Alert severity="error">La suppression est irréversible !</Alert>
        <Button
          fullWidth
          variant="contained"
          color="error"
          onClick={deleteDocument}>
          Supprimer
        </Button>
      </Stack>
    </div>
  );
};
