import { Logger } from "@/common/error-handling";
import {
  addBusinessOffer,
  updateBusinessOffer,
} from "@/common/firebase/services/owner-requests";
import { useResponsive } from "@/common/layout/hooks/use-responsive";
import {
  BusinessOffer,
  BusinessOfferCreate,
  BusinessOfferForm,
} from "@common/model/business";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { Timestamp } from "firebase/firestore";
import { ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { array, date, object, string } from "yup";
import { BusinessFormComponentProps } from "../tabs/BusinessDashboard";

interface OfferFormProps extends BusinessFormComponentProps {
  currentOffer?: BusinessOffer;
}

function getDateFromTimeString(time: string) {
  const parts = time.split(":");
  const date = new Date();
  date.setHours(parseInt(parts[0]), parseInt(parts[1]));
  return date;
}

const week_dayss = [
  {
    index: -1,
    label: "Tous les jours",
  },
  {
    index: 1,
    label: "Lundi",
  },
  {
    index: 2,
    label: "Mardi",
  },
  {
    index: 3,
    label: "Mercredi",
  },
  {
    index: 4,
    label: "Jeudi",
  },
  {
    index: 5,
    label: "Vendredi",
  },
  {
    index: 6,
    label: "Samedi",
  },
  {
    index: 7,
    label: "Dimanche",
  },
];

const schema = object({
  title: string().required(),
  description: string().required(),
  type: string<BusinessOfferForm["type"]>().required(),
  week_days: array().required(),
  date: date()
    .nullable()
    .when("type", {
      is: (v: BusinessOfferForm["type"]) => v === "onetime",
      then: (schema) => schema.required(),
    }),
  start_hour: date().required(),
  end_hour: date().required(),
  created_at: date().required(),
  updated_at: date().required(),
});

export const OfferForm = ({
  business,
  handleClose,
  mutate,
  currentOffer,
}: OfferFormProps) => {
  const [loading, setLoading] = useState(false);
  const [daySelectOpen, setDaySelectOpen] = useState(false);
  const downSm = useResponsive("down", "sm");

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    control,
    setValue,
    watch,
  } = useForm<BusinessOfferForm>({
    resolver: yupResolver<BusinessOfferForm>(schema),
    defaultValues: {
      ...currentOffer,
      start_hour: currentOffer
        ? getDateFromTimeString(currentOffer.start_hour)
        : new Date(2000, 1, 1, 18),
      end_hour: currentOffer
        ? getDateFromTimeString(currentOffer.end_hour)
        : new Date(2000, 1, 1, 20),
      week_days: currentOffer ? currentOffer.week_days : [],
      created_at: currentOffer?.created_at.toDate() || new Date(),
      updated_at: new Date(),
      date: null,
    },
  });
  const formValues = watch();

  useEffect(() => {
    if (formValues.type === "recurring") {
      setValue("date", null);
    } else {
      setValue("week_days", []);
    }
  }, [formValues.type]);

  const createOffer = async (formData: BusinessOfferForm) => {
    if (currentOffer) {
      await updateOffer(formData, currentOffer.id);
      return;
    }

    try {
      setLoading(true);

      const offerValues: BusinessOfferCreate = {
        ...formData,
        date: formData.date ? Timestamp.fromDate(formData.date) : null,
        start_hour: `${formData.start_hour?.getHours()}:${formData.start_hour?.getMinutes()}`,
        end_hour: `${formData.end_hour?.getHours()}:${formData.end_hour?.getMinutes()}`,
        created_at: Timestamp.now(),
        updated_at: Timestamp.now(),
        archived_at: null,
      };

      await addBusinessOffer(business.id, offerValues);
      closeHandler();
    } catch (e) {
      setLoading(false);
      Logger.error(e);
    }
  };

  function closeHandler(isUpdate = false) {
    mutate();
    setLoading(false);
    Logger.success(isUpdate ? "Promotions mise à jour" : "Promotions créé");
    handleClose();
  }

  const updateOffer = async (
    formData: BusinessOfferForm,
    currentOfferId: string
  ) => {
    try {
      setLoading(true);
      const { created_at, ...data } = formData;

      const offerValues: Partial<BusinessOffer> = {
        ...data,
        date: formData.date ? Timestamp.fromDate(formData.date) : null,
        start_hour: `${formData.start_hour?.getHours()}:${formData.start_hour?.getMinutes()}`,
        end_hour: `${formData.end_hour?.getHours()}:${formData.end_hour?.getMinutes()}`,
        updated_at: Timestamp.now(),
      };

      await updateBusinessOffer(business.id, currentOfferId, offerValues);

      closeHandler(true);
    } catch (e) {
      setLoading(false);
      Logger.error(e);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(createOffer)}
      noValidate
      sx={{ mt: 1 }}>
      {/* {JSON.stringify(errors)} */}
      <Typography variant="h5" sx={{ mb: 2, mt: 2 }}>
        Informations
      </Typography>

      <Stack spacing={2}>
        <TextField
          {...register("title")}
          error={!!errors.title}
          fullWidth
          placeholder="Happy hour, 50% sur les steak frites"
          sx={{ minHeight: 45 }}
          required
        />
        <TextField
          {...register("description")}
          multiline
          rows={3}
          error={!!errors.description}
          fullWidth
          placeholder="2 pour 1 sur notre sélection de cocktails !"
          sx={{ minHeight: 45 }}
          InputProps={{
            inputComponent: TextareaAutosize,
            inputProps: {
              maxRows: 4,
              minRows: 2,
            },
          }}
        />
        <FormControl fullWidth>
          <InputLabel>Type</InputLabel>
          <Select {...register("type")} error={!!errors.type} label="Type">
            <MenuItem value="recurring">
              Récurrent (toutes les semaines)
            </MenuItem>
            <MenuItem value="onetime">Ponctuel (une seule fois)</MenuItem>
          </Select>
        </FormControl>

        {control._formValues.type === "onetime" && (
          <LocalizationProvider adapterLocale={"fr"} dateAdapter={AdapterDayjs}>
            <DatePicker
              {...register("date")}
              defaultValue={null}
              onChange={(e: Dayjs | null) => {
                setValue("date", e?.toDate() || null);
              }}
              sx={{ width: 1 }}
              label="Sélectionnez une date"
              slotProps={{ textField: { error: !!errors.date } }}
            />
          </LocalizationProvider>
        )}

        {control._formValues.type === "recurring" && (
          <FormControl fullWidth>
            <Backdrop open={daySelectOpen && downSm}> </Backdrop>
            <InputLabel id="demo-simple-select-label">
              Quel jour(s) ?
            </InputLabel>
            <Select
              {...register(`week_days`)}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              fullWidth
              multiple
              value={watch(`week_days`)}
              error={!!errors.week_days}
              open={daySelectOpen}
              onOpen={() => setDaySelectOpen(true)}
              onClose={() => {
                setDaySelectOpen(false);
                setTimeout(() => {
                  (document.activeElement as HTMLElement).blur();
                });
              }}
              onChange={(e, v) => {
                let value: number[] = e.target.value as number[];
                if (value[value.length - 1] === -1) {
                  value = value = [-1];
                } else {
                  value = value.filter((v) => v !== -1);
                }

                value = value.filter((v) => v !== undefined);
                if (!(v as ReactElement).key?.includes("submit")) {
                  setValue("week_days", value);
                }
                setTimeout(() => {
                  (document.activeElement as HTMLElement).blur();
                });
              }}
              renderValue={(selected) =>
                selected
                  .map((index) => week_dayss[index > 0 ? index : 0].label)
                  .join(", ")
              }
              label="Quel jour(s) ?">
              {week_dayss.map((day) => (
                <MenuItem key={day.index} value={day.index}>
                  <Checkbox
                    checked={control._formValues.week_days.includes(day.index)}
                  />
                  <ListItemText primary={day.label} />
                </MenuItem>
              ))}
              <MenuItem key="submit">
                <Button
                  fullWidth
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    setDaySelectOpen(false);
                  }}>
                  OK
                </Button>
              </MenuItem>
            </Select>
          </FormControl>
        )}
      </Stack>
      <Grid container spacing={2} sx={{ pt: 2 }}>
        <Grid item xs={12} sm={6}>
          <LocalizationProvider adapterLocale={"fr"} dateAdapter={AdapterDayjs}>
            <TimePicker
              {...register("start_hour")}
              defaultValue={dayjs(control._formValues.start_hour)}
              onChange={(e: Dayjs | null) => {
                setValue("start_hour", e?.toDate());
              }}
              sx={{ width: 1 }}
              label="Heure de début"
              slotProps={{ textField: { error: !!errors.start_hour } }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6}>
          <LocalizationProvider adapterLocale={"fr"} dateAdapter={AdapterDayjs}>
            <TimePicker
              {...register("end_hour")}
              defaultValue={dayjs(control._formValues.end_hour)}
              onChange={(e: Dayjs | null) => {
                setValue("end_hour", e?.toDate());
              }}
              minTime={dayjs(control._formValues.start_hour)}
              sx={{ width: 1 }}
              label="Heure de fin"
              slotProps={{ textField: { error: !!errors.end_hour } }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Alert severity="success" sx={{ mt: 2 }}>
        <b>{formValues.title || "TITRE"}</b>:{" "}
        {formValues.type === "recurring" ? (
          <span>
            tous les <b>{week_dayss[formValues.week_days[0]]?.label}</b>
          </span>
        ) : (
          "tel jours"
        )}
        , de <b>{formValues.start_hour?.getHours()}h</b> à{" "}
        <b>{formValues.end_hour?.getHours()}h</b>
      </Alert>
      <LoadingButton
        type="submit"
        loading={loading}
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}>
        Sauvegarder
      </LoadingButton>
    </Box>
  );
};
